import React, { useState } from 'react'
import Button from './Button'
import Block from './Block'
import { Row, Col } from './layout'
import parseScene from '../utils/parseScene'
import '../styles/EditorImport.css'

export default function EditorImport ({ result, setResult }) {
  const [input, setInput] = useState('')

  let blocks = []
  if (result && result.blocks) blocks = result.blocks

  return (
    <div className='editorimport'>
      <Row>

        <Col md={4}>
          <textarea
            value={input}
            onChange={event => {
              setResult({ blocks: parseScene.v3(event.target.value) })
              setInput(event.target.value)
            }}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </Col>

        <Col md={4}>
          <pre>{JSON.stringify(blocks, null, 2)}</pre>
          <Button
            className='my-3'
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(blocks, null, 2))
            }}
          >Copy
          </Button>
        </Col>

        <Col md={4}>
          {blocks.map((block, i) => {
            if (block.dir) {
              return <p key={i + 'd'} className='stagedir'>{block.dir}</p>
            } else {
              return (
                <Block key={i + 'b'} block={block} noteMode='hide' />
              )
            }
          })}
        </Col>

      </Row>
    </div>
  )
}
