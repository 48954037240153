import React from 'react'
import contentIndex from '../content/index'
import templates from '../content/templates'
import auth from '../utils/auth'

export default function Content ({ showID, pageID }) {
  if (contentIndex[showID] && contentIndex[showID][pageID]) {
    const StaticContent = contentIndex[showID][pageID]
    return <StaticContent />
  }

  if (templates[[pageID]]) {
    const Template = templates[pageID]
    return <Template showID={showID} />
  }

  if (auth.hasRights('admin')) {
    return (
      <div className='text-danger' style={{ background: '#ffdddd' }}>
        Content not found: <span className='text-monospace'>{showID || ''}/{pageID}</span>
      </div>
    )
  }

  return null
}
