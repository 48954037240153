import React from 'react'

export default function Content () {
  return (
    <>
      <style>
        {`.cast-list {
            font-size: 1.1em;
          }
          .cast-list li {
            margin-bottom: .5em;
          }
          .bio-link {
            color: inherit !important;
            text-decoration: underline;
          }`}
      </style>
      <div class='text-center'>
        <h2>Romeo & Juliet</h2>
        <h5 className='font-italic'>Presented by the SYT 2021 Summer Ensemble</h5>

        <ul class='list-unstyled cast-list mt-4'>
          <li><b>Cassidy Berg</b> – Friar Laurence/Verona Citizen</li>
          <li><b>August Chaffin</b> – Romeo</li>
          <li><b>Louisa Darr</b> – Lady Capulet</li>
          <li><b>Keena Dietz</b> – Benvolio/Page</li>
          <li><b>Pip Edmund</b> – Tybalt/Third Watchman</li>
          <li><b>Maeve Filkins</b> – Juliet</li>
          <li><b>Ava Blackwell Kinney</b> – Paris/Abraham</li>
          <li><b>Madelyn Kobbermann</b> – Peter/Sampson/Apothecary/Chief Watchman</li>
          <li><b>Anamika Nagargoje</b> – Montague/Friar John/Cousin Capulet/Hugh Rebeck</li>
          <li><b>Lucy Peltier</b> – Chorus/Prince Escalus/Simon Catling </li>
          <li><b>Ilse Prokosch</b> – Nurse</li>
          <li><b>Abraham Riker</b> – Mercutio/Second Watchman</li>
          <li><b>Audrey Thoburn</b> – Gregory/Lady Montague/Balthazar/James Soundpost/Boy</li>
          <li><b>Ashlyn Walters</b> – Capulet</li>
        </ul>

        <h4 className='mt-5'>Creative Team</h4>
        <ul class='list-unstyled cast-list'>
          <li>Sound Designer and Composer: <b><a href='https://danielbonespur.bandcamp.com/' target='_blank' rel='noopener noreferrer' className='text-info bio-link'>Dan Dukich</a></b></li>
          <li>Web Designer and Developer: <b><a href='https://joshdudley.net' target='_blank' rel='noopener noreferrer' className='text-info bio-link'>Josh Dudley</a></b></li>
          <li>Stage Manager: <b>Gabriel Peñaloza-Hernandez</b></li>
          <li>Video Editor: <b>Jeremy Wilker</b></li>
          <li>Director: <b>Lukas Brasherfons</b></li>
          <li>Assistant Director and Dramaturg: <b>Blake Walters</b></li>
          <li>Producers: <b>Maria Signorelli, Logan Verdoorn</b></li>
        </ul>
      </div>
    </>
  )
}
