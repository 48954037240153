import { logging } from './analytics'
import db from './db'
import shows from '../data/shows'

export default {
  save: {
    editors: function (data) { db.ref('/analytics/calc/editors').set(data).then(() => console.log('⚙️ Saved!')) },
    views: function (data) { db.ref('/analytics/calc/views').set(data).then(() => console.log('⚙️ Saved!')) }
  },

  editors: async function (opts = {}) {
    console.log('⚙️ Calculating editors')

    console.log('⚙️ Downloading users...')
    const res = await db.ref('/analytics/users').once('value')
    const users = res.val()

    console.log('⚙️ Scanning users...', Object.keys(users).slice(0, 100))
    const data = {
      admin: [],
      editor: [],
      preview: []
    }
    Object.keys(users).forEach(userID => {
      const user = users[userID]
      if (user.lastRole) {
        if (!data[user.lastRole]) data[user.lastRole] = []
        data[user.lastRole].push(userID)
      }
    })

    console.log('⚙️ Done', data)
    return data
  },

  views: async function (opts = {}) {
    console.log('⚙️ Calculating views')

    console.log('⚙️ Downloading editors...')
    const resEditorLists = await db.ref('/analytics/calc/editors').once('value')
    const editorLists = resEditorLists.val()
    const editors = [
      ...editorLists.admin,
      ...editorLists.editor
    ]

    console.log('⚙️ Downloading log...')
    const resLog = await db.ref('/analytics/log').once('value')
    const log = logging.hydrateLog(resLog.val())

    console.log('⚙️ Scanning log...', log.slice(0, 100))
    const data = {
      midsummer: {
        '2020-09-19': { scenes: 0, show: 0, first: 0 }
      }
    }
    const seen = {
      midsummer: ['an']
    }
    const firstDates = {}
    Object.keys(shows).forEach(showID => {
      data[showID] = {}
      seen[showID] = []
    })
    for (let i = log.length - 1; i >= 0; i--) {
      const entry = log[i]
      if (entry.event !== 'sceneStart') continue

      try {
        if (!entry.date) {
          console.log('undefined date', entry)
          continue
        }

        let showID = entry.data.scene.split('_')[0]
        const userID = entry.data.an
        const dateKey = entry.date.slice(0, 10)

        if (!data[showID]) {
          showID = showID.split('-')[0]
          if (!data[showID]) {
            console.log('unknown showID', showID, entry)
            continue
          }
        }

        if (!firstDates[showID]) {
          firstDates[showID] = dateKey
        }

        if (editors.includes(userID)) continue

        if (!data[showID][dateKey]) data[showID][dateKey] = { scenes: 0, show: 0, first: 0, seen: [] }
        const counts = data[showID][dateKey]

        counts.scenes = (counts.scenes || 0) + 1

        if (!counts.seen.includes(userID)) {
          counts.seen.push(userID)
          counts.show = (counts.show || 0) + 1
        }

        if (!seen[showID].includes(userID)) {
          seen[showID].push(userID)
          counts.first = (counts.first || 0) + 1
        }
      } catch (err) {
        console.log({
          err,
          entry,
          data
        })
      }
    }

    // const now = dayjs()
    // Object.keys(firstDates).forEach(showID => {
    //   let dateCounter = dayjs(firstDates[showID])

    //   while (dateCounter.isBefore(now, 'days')) {
    //     const dateKey = dateCounter.format('YYYY-MM-DD')
    //     if (!data[showID][dateKey]) {
    //       data[showID][dateKey] = { scenes: 0, show: 0, first: 0, seen: [] }
    //     }
    //     dateCounter = dateCounter.add(1, 'days')
    //   }
    // })

    console.log('⚙️ Done', data)
    return data
  }
}
