import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from './layout'

export default function Card ({ title, subtitle, body, link, listGroup, col, className = '', children, ...props }) {
  return (
    <Col width='auto' {...col}>
      <div className={'card mb-3 mb-lg-4 ' + className} {...props}>
        <div className='card-body'>
          {title && <h5 className='card-title m-0'>{title}</h5>}
          {subtitle && <h6 className='card-subtitle mt-1 mb-3'>{subtitle}</h6>}
          {children}
          {link && <Link to={link.to} className='stretched-link'>{link.text}</Link>}
        </div>
        {listGroup &&
          <div className='list-group list-group-flush'>
            {listGroup.map(item => (
              <Link key={item.link} to={item.link} className='list-group-item list-group-item-action'>
                {item.text}
              </Link>))}
          </div>}
      </div>
    </Col>
  )
}
