import React from 'react'
import { Link } from 'react-router-dom'
import '../styles/ButtonDot.css'

export default function Button ({ size, color, outline, dot, className, link, children, ...props }) {
  const classes = ['btn', `btn-${outline ? 'outline-' : ''}${color || 'primary'}`]
  if (size) classes.push('btn-' + size)
  if (dot) classes.push('btn-dot')
  if (className) classes.push(className)
  if (link) {
    return <Link to={link} className={classes.join(' ')} {...props}>{children}</Link>
  }
  return (
    <button className={classes.join(' ')} {...props}>{children}</button>
  )
}

export function ButtonGroup ({ size, vertical, className, children, ...props }) {
  const classes = ['btn-group']
  if (size) classes.push('btn-group-' + size)
  if (vertical) classes.push('btn-group-vertical')
  if (className) classes.push(className)
  return (
    <div className={classes.join(' ')} {...props}>{children}</div>
  )
}
