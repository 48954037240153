import auth from './auth'
import db from './db'

export const logging = {
  events: {
    newUser: { level: 'use', title: 'New user' },
    login: { level: 'info', title: 'Login succeeded' },
    loginFailed: { level: 'warn', title: 'Login failed' },
    buffer: { level: 'warn', title: 'Track buffering' },
    sceneStart: { level: 'use', title: 'Scene started' },
    sceneFinish: { level: 'use', title: 'Scene finished' },
    videoView: { level: 'use', title: 'Video viewed' },
    backstage: { level: 'info', title: 'Backstage accessed' },
    dbOverload: { level: 'error', title: 'Database overloaded' },
    publish: { level: 'info', title: 'Show published' },
    error: { level: 'error', title: 'Error' },
    deploy: { level: 'info', title: 'Site deployed' },
    deployFailed: { level: 'error', title: 'Site deploy failed' },
    donate: { level: 'use', title: 'Donated' },
    survey: { level: 'use', title: 'Survey completed' },
    custom: { level: 'info', title: 'Note' }
  },

  log: function (event, data) {
    const entry = {
      e: event,
      date: new Date().toISOString()
    }
    if (data) entry.data = data
    db.ref('/analytics/log').push(entry)
  },

  hide: function (id) {
    db.ref(`/analytics/log/${id}`).update({ hide: true })
  },

  remove: function (id) {
    db.ref(`/analytics/log/${id}`).remove()
  },

  hydrateLog: function (log) {
    if (!log) return ['none']
    return Object.keys(log).reverse().map(key => {
      const entry = log[key]
      if (entry.hide) return null
      let event = this.events[entry.e]
      if (!event) event = { level: 'warn', title: 'Unknown event ' + entry.e }
      return {
        id: key,
        event: entry.e,
        data: entry.data,
        date: entry.date,
        ...event
      }
    }).filter(e => e)
  },

  subscribe: function (onChange) {
    return db.subscribe('analytics_log', '/analytics/log', data => onChange(this.hydrateLog(data)))
  }
}

const analytics = {
  id: null,
  dbRef: null,
  devMode: window.localStorage.getItem('dev'),
  initialized: false,
  initialize: function () {
    if (this.initialized) return
    this.initialized = true
    if (!this.id) {
      this.id = window.localStorage.getItem('an')
      if (!this.id) {
        this.id = Math.random().toString(36).slice(2)
        window.localStorage.setItem('an', this.id)
        this.event.newUser()
      }
    }
    this.dbRef = db.ref(`/analytics/users/${this.id}`)
    db.get(`/analytics/users/${this.id}`, false, data => {
      const newData = {
        ...data,
        lastRole: auth.currentRights() === 'none' ? null : auth.currentRights(),
        lastVisit: new Date().toISOString(),
        lastUserAgent: navigator.userAgent
      }
      if (!newData.firstVisit) newData.firstVisit = new Date().toISOString()
      this.dbRef.update(newData)
      this.dbRef.child(`screenWidths/${window.innerWidth}`).set(true)
    })
    this.presence = this.dbRef.child('presence')
    this.presence.set('-')
    this.presence.onDisconnect().remove()
  },

  event: {
    newUser: function () {
      analytics.log('newUser', { page: window.location.pathname })
    },
    login: function (role) {
      analytics.log('login', { role })
      analytics.dbRef.update({
        lastRole: role
      })
    },
    loginFailed: function (password) {
      analytics.log('loginFailed', { password })
      analytics.dbRef.child('failedLogins').once('value', data => {
        analytics.dbRef.update({ failedLogins: (data || 0) + 1 })
      })
    },
    buffer: function (scene) {
      analytics.log('buffer', { scene })
      analytics.dbRef.child('buffers').once('value', data => {
        analytics.dbRef.update({ buffers: (data || 0) + 1 })
      })
    },
    sceneStart: function (scene) {
      if (analytics.devMode) return
      analytics.dbRef.child(`scenes/${scene}/starts`).once('value', data => {
        analytics.dbRef.child(`scenes/${scene}/starts`).set((data.val() || 0) + 1)
      })
      analytics.log('sceneStart', { scene })
    },
    sceneFinish: function (scene) {
      if (analytics.devMode) return
      analytics.dbRef.child(`scenes/${scene}/finishes`).once('value', data => {
        analytics.dbRef.child(`scenes/${scene}/finishes`).set((data.val() || 0) + 1)
      })
      analytics.log('sceneFinish', { scene })
    },
    videoView: function (video) {
      if (analytics.devMode) return
      db.ref(`/analytics/videos/${video}/views`).once('value', data => {
        db.ref(`/analytics/videos/${video}/views`).set((data.val() || 0) + 1)
      })
      analytics.log('videoView', { video })
    },
    backstage: function () {
      analytics.dbRef.update({
        lastBackstageAccess: new Date().toISOString()
      })
      if (this.backstageAccessed) return
      this.backstageAccessed = true
      if (analytics.devMode) return
      analytics.log('backstage')
    },
    preview: function () {
      analytics.dbRef.update({
        lastPreviewAccess: new Date().toISOString()
      })
    },
    dbOverload: function (details) {
      analytics.log('dbOverload', details)
    },
    publish: function (show) {
      analytics.log('publish', { show })
    },
    donate: function (show, source) {
      if (analytics.devMode) return
      analytics.log('donate', { show, source })
      analytics.plausible('donate')
      analytics.dbRef.update({
        lastDonation: new Date().toISOString()
      })
    },
    survey: function (show, source) {
      if (analytics.devMode) return
      analytics.log('survey', { show, source })
    }
  },

  error: function (msg, details = {}) {
    this.log('error', { msg, ...details })
  },

  log: function (event, details = {}) {
    logging.log(event, { an: this.id, ...details })
  },

  pageview: function (page) {
    if (this.currentPage !== page) {
      this.currentPage = page
      this.dbRef.child('pageviews').push(page)
      this.presence.set(page)
    }
  },

  plausible: event => {
    // eslint-disable-next-line
    if (window.plausible) window.plausible(event)
  }
}

analytics.initialize()

export default analytics
