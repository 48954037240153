import 'firebase/database'
// import analytics from './analytics'
import firebase from './firebase'

const database = firebase.database()

const db = {
  database,
  ref: function (path) {
    return this.database.ref(path)
  },
  serverValue: firebase.database.ServerValue,

  opCount: 0,
  opCountRecent: 0,
  limitOps: function (ref) {
    this.opCount++
    this.opCountRecent++
    if (this.opCount % 100 === 0) console.log(this.opCount + ' database ops - ' + ref)
    if (this.opCount === 1000 || this.opCountRecent === 100) {
      if (window.localStorage.getItem('backstage')) {
        window.alert(`Database overloaded. Disconnecting to reduce usage fees - further changes will not be saved. Contact Josh for more info.\n\n${this.opCount} ops, ${this.opCountRecent} recent. Last ref: ${ref}.`)
      }
      // analytics.event.dbOverload({
      //   ref,
      //   ops: this.opCount,
      //   recent: this.opCountRecent
      // })
      setTimeout(() => {
        this.database.goOffline()
      }, 2000)
    }
  },

  subs: {},
  subscribe: function (id, path, onChange) {
    this.limitOps(path)
    const dbRef = this.ref(path)
    if (!this.subs[id]) {
      this.subs[id] = dbRef.on('value', snap => {
        onChange(snap.val())
      })
    }
    return () => {
      dbRef.off('value', this.subs[id])
      delete this.subs[id]
    }
  },
  gets: {},
  get: function (path, cache = true, back) {
    if (this.gets[path] && cache) {
      return back(this.gets[path])
    }
    this.limitOps(path)
    this.ref(path).once('value', snap => {
      back(snap.val())
      this.gets[path] = snap.val()
    })
  },

  editorPresence: null,
  registerEditor: function () {
    if (this.editorPresence) return
    this.limitOps('editor registration')
    this.editorPresence = database.ref('/analytics/presence/editor').push({
      joined: new Date().toLocaleString('en-US'),
      userAgent: navigator.userAgent,
      id: window.localStorage.getItem('an')
    })
    this.editorPresence.onDisconnect().remove()
  }
}

db.opInterval = setInterval(() => { db.opCountRecent = 0 }, 10000)

console.log('DATABASE', db)

export default db
