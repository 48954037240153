const scroller = {
  enable: true,
  target: null,
  accel: 1,
  expected: null,
  suppressed: null,
  last: -1,

  scroll: function (pos = 0) {
    if (!this.enable) return
    this.target = Math.min(Math.max(0, pos), document.body.offsetHeight - window.innerHeight * 0.5)
    if (this.suppressed !== null) return
    clearInterval(this.interval)
    this.interval = setInterval(() => this.tick(this), 15)
  },

  scrollTo: function (ref) {
    let total = 0
    let el = ref
    while (el) {
      total += el.offsetTop
      el = el.offsetParent
    }
    this.scroll(total - window.innerHeight * 0.5)
  },

  scrollNow: function (pos) {
    this.expected = 0
    window.scroll(0, pos)
    clearInterval(this.interval)
    this.interval = null
    clearInterval(this.suppressed)
    this.suppressed = null
  },

  cancel: function () {
    if (this.interval === null) {
      this.uncancel = () => this.scroll(this.target)
      this.target = null
    }
  },

  uncancel: () => {},

  tick: function (s) {
    const current = s.last
    if (window.scrollY < s.target + 1 && window.scrollY > s.target - 1) {
      // console.log('scroll arrived')
      clearInterval(s.interval)
      s.interval = null
      return
    }

    let speed = Math.abs(s.target - current) / 20 + 1
    s.accel = Math.min(s.accel + 1, speed)
    speed = Math.min(s.accel, speed)
    if (s.target < current) speed = -speed

    s.expected = Math.floor(current + speed)
    // console.log(`tgt ${s.target}  lst ${s.last}  exp ${s.expected}  cur ${Math.round(window.scrollY * 100) / 100}  spd ${Math.round(speed * 1000) / 1000}`)
    if (s.expected === s.last) {
      // console.log('scroll stalled')
      clearInterval(s.interval)
      s.interval = null
      return
    }
    window.scrollTo(0, s.expected)
    s.last = s.expected
  },

  suppress: function () {
    clearInterval(this.interval)
    this.accel = 1

    clearTimeout(this.suppressed)
    this.suppressed = setTimeout(() => {
      this.suppressed = null
      if (this.target !== null) this.scroll(this.target)
      // console.log('scroll unsuppressed, scrolling to', this.target)
    }, 10000)
  }
}

window.addEventListener('scroll', () => {
  const actual = Math.ceil(window.scrollY)
  scroller.last = actual
  if (actual !== scroller.expected) {
    scroller.suppress()
  }
  scroller.expected = null
})

export default scroller
