const overrides = {
  intro: 'Introduction',
  prologue: 'Prologue',
  credits: 'Credits'
}

const roman = ['0', 'I', 'II', 'III', 'IV', 'V']

export default function sceneTitle (sceneID) {
  const pts = (sceneID.split('_')[1] || sceneID).split('-')
  if (overrides[pts[0]]) return overrides[pts[0]]
  if (pts.length === 1) return pts[0].charAt(0).toUpperCase() + pts[0].slice(1)
  return `Act ${roman[parseInt(pts[0])]}, Scene ${pts[1]}${pts[2] ? ` (Part ${pts[2]})` : ''}`
}
