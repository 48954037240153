import React from 'react'
import shows from '../data/shows'
import urls from '../data/urls'
import analytics from '../utils/analytics'

export default {
  download: function ({ showID }) {
    const show = shows[showID]
    if (!show) return null
    if (!show.download) return null
    return (
      <>
        <h2>Download</h2>
        <p>Looking to take the show on the road? Download the complete production of {show.title}. {show.download.text}</p>
        {show.download.links.map((link, i) => (
          <React.Fragment key={link.file}>
            <a href={urls.download(link.file)} download class={`btn btn-lg btn-${i > 0 ? 'outline-' : ''}info mb-3`}>{link.title}</a>
            <br />
          </React.Fragment>
        ))}
      </>
    )
  },

  directorsnote: function ({ showID }) {
    const show = shows[showID]
    if (!show || !show.directorsNote) return null
    const grafs = [...show.directorsNote]
    const signature = grafs.pop()
    return (
      <div class='row justify-content-center'>
        <div class='col col-md-10 col-xl-8'>
          <h2>Director's Note</h2>
          {grafs.map(graf => <p key={graf.slice(0, 16)}>{graf}</p>)}
          <p>
            {signature.map((line, i) => (
              <>
                {i === 0
                  ? <b>{line}</b>
                  : <i>{line}</i>}
                <br />
              </>
            ))}
          </p>
        </div>
      </div>
    )
  },

  introInline: function ({ showID }) {
    const show = shows[showID]
    return (
      <img
        src={'/assets/' + (show.introImg || `intro-${showID}.jpg`)}
        alt={'SYT Presents: ' + show.title}
        className='img-fluid w-100'
        {...(show.introProps || {})}
      />
    )
  },

  endInline: function ({ showID }) {
    return (
      <div className='text-center mb-5'>
        <h1 className='display1 mb-5 font-serif font-italic'>The End</h1>
        <hr />
        <p>Enjoyed the show? Help support our mission with a donation today.</p>
        <a href={urls.donateCTA} target='_blank' rel='noopener noreferrer' className='btn btn-success' onClick={() => analytics.event.donate(showID)}>Donate Now</a>
      </div>
    )
  },

  static_intro: function ({ showID }) {
    const show = shows[showID]
    const style = {}
    if (show.introBleed) style.marginTop = '-20vh'
    return (
      <img
        src={'/assets/' + (show.introImg || `intro-${showID}.jpg`)}
        alt={'SYT Presents: ' + show.title}
        className='img-fluid w-100'
        style={style}
      />
    )
  },

  static_credits: function ({ showID }) {
    return (
      <>
        <h1 className='display1 mb-5 font-serif font-italic'>The End</h1>
        <hr />
        <p>Enjoyed the show? Help support our mission with a donation today.</p>
        <a href={urls.donateCTA} target='_blank' rel='noopener noreferrer' className='btn btn-success' onClick={() => analytics.event.donate(showID)}>Donate Now</a>
      </>
    )
  },

  static_blank: function () {
    return <></>
  }
}
