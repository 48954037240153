import React from 'react'

export default function Content () {
  return (
    <>
      <style>
        {`.cast-list {
            font-size: 1.1em;
          }
          .cast-list li {
            margin-bottom: .5em;
          }
          .bio-link {
            color: inherit;
            text-decoration: underline;
          }`}
      </style>
      <div class='text-center'>
        <h2>The SYT Ensemble</h2>
        <ul class='list-unstyled cast-list'>
          <li><b>Cassidy Berg</b> – Aeneas</li>
          <li><b>Lucas Bueling</b> – Calcas, Servant, First Myrmidon</li>
          <li><b>Vivian Chaffin</b> – Cressida</li>
          <li><b>Keena Dietz</b> – Diomedes</li>
          <li><b>Pip Edmund</b> – Troilus</li>
          <li><b>Maeve Filkins</b> – Agamemnon</li>
          <li><b>Gray Jensen</b> – Patroclus</li>
          <li><b>Aine Johnson</b> – Alexander, Soldier, Third Myrmidon</li>
          <li><b>Mira Iwaszek</b> – Nestor</li>
          <li><b>Louise Kelly</b> – Hector</li>
          <li><b>Lucy Peltier</b> – Achilles</li>
          <li><b>Ilse Prokosch</b> – Thersites</li>
          <li><b>Abraham Riker</b> – Pandarus</li>
          <li><b>Søren Staffaucher</b> – Helenus, Andromache, Second Myrmidon</li>
          <li><b>Tic Treitler</b> – Paris</li>
          <li><b>Christopher Tupper</b> – Menelaus, Priam</li>
          <li><b>Ashlyn Walters</b> – Ulysses</li>
          <li><b>Blake Walters</b> – Ajax</li>
          <li><b>Caitlin Warshaw</b> – Prologue, Cassandra, Helen</li>
        </ul>
        <h4>Creative Team</h4>
        <ul class='list-unstyled cast-list'>
          <li>Directed/adapted by <b>Lukas Brasherfons</b></li>
          <li>Sound Design and Original Music by <b>Dan Dukich</b></li>
          <li>Graphic Design and Dream Project Web Development by <b>Josh Dudley</b></li>
          <li>Stage Management by <b>Gabriel Peñaloza-Hernandez</b></li>
          <li> Film Editing by <b>Jeremy Wilker</b></li>
          <li>Special Thanks: <b>Alison Ruth, Joanie Schultz, Kevin Springer, Jake Otto</b></li>
        </ul>
      </div>
    </>
  )
}
