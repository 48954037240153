import React, { useState, useEffect } from 'react'
import Button from '../components/Button'
import Navbar from '../components/Navbar'
import db from '../utils/db'

const sourcePath = '/staging/scenes'
const destinationPath = '/staging/scenes'

export default function Database () {
  const [source, setSource] = useState({})
  useEffect(() => {
    return db.subscribe('Database_source', sourcePath, setSource)
  }, [setSource])
  const [working, setWorking] = useState(null)
  const [backup, setBackup] = useState(null)

  async function run (data) {
    return data
  }

  return (
    <>
      <style>{'.buttonRow .btn { margin-right: 1em }'}</style>
      <Navbar back='/backstage' title='Database Management' />
      <div className='container'>
        <div className='buttonRow mb-2'>
          <Button
            color='info' onClick={() => {
              console.log('BEFORE', source)
              setWorking(source)
            }}
          >Load from {sourcePath}
          </Button>
          <Button
            color='primary' onClick={() => {
              const newData = run(working)
              console.log('AFTER', newData)
              setWorking(newData)
            }}
          >Do the Thing
          </Button>
          <Button color='secondary' onClick={() => console.log(working)}>Log</Button>
          <Button
            color='success' outline={backup} disabled={!working} onClick={() => {
              setBackup(source)
              db.ref(destinationPath).set(working)
            }}
          >Upload to {destinationPath}
          </Button>
          <Button color='danger' outline={!backup} disabled={!backup} onClick={() => db.ref(destinationPath).set(backup)}>Revert</Button>
        </div>
        <pre>
          {JSON.stringify(working, null, 2)}
        </pre>
      </div>
    </>
  )
}
