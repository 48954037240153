import React from 'react'
import Block from './Block'
import Button from './Button'
import { Row, Col } from './layout'

function FormRow ({ widths, group, children }) {
  return (
    <div className={'form-row align-items-center' + (group ? ' form-group' : '')}>
      {children.map((child, i) => (
        <Col key={i} width={widths[i] || 12}>
          {child}
        </Col>
      ))}
    </div>
  )
}

function InlineLabel ({ className = '', children }) {
  return <label className={'col-form-label w-100 text-right ' + className}>{children}</label>
}

export default function EditorStylesBlock ({ block, dbRef, i, ...props }) {
  if (block.dir) {
    return (
      <Row>
        <Col width={8}>
          <Block block={block} noteMode='hide' />
        </Col>
      </Row>
    )
  }
  return (
    <Row>
      <Col width={6}>
        <Block block={block} noteMode='hide' />
      </Col>
      <Col width={6} className='mt-5'>
        <FormRow group widths={[1, 1, 2, 3]}>
          <InlineLabel>Font</InlineLabel>
          <Button size='sm' color='info' outline={!block.italic} onClick={() => dbRef.update({ italic: !block.italic })}>Italic</Button>
          <InlineLabel>Indent</InlineLabel>
          <input type='number' className='form-control' value={block.indent || 0} onChange={event => dbRef.update({ indent: Math.max(parseInt(event.target.value), 0) || null })} />
        </FormRow>
      </Col>
    </Row>
  )
}
