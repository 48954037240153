import React from 'react'
import urls from '../data/urls'

export default function LinkOut ({ to, preset, sameTab, children, ...props }) {
  const href = to || urls[preset] || '#'

  if (sameTab) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    )
  }

  return (
    <a href={href} target='_blank' rel='noopener noreferrer' {...props}>
      {children}
    </a>
  )
}
