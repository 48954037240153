import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import Account from './pages/Account'
import Analytics from './pages/Analytics'
import AnalyticsShow from './pages/AnalyticsShow'
import AnalyticsUsers from './pages/AnalyticsUsers'
import AnalyticsProcess from './pages/AnalyticsProcess'
import Backstage from './pages/Backstage'
import BackstageLogin from './pages/BackstageLogin'
import BackstageShow from './pages/BackstageShow'
import Database from './pages/Database'
import Editor from './pages/Editor'
import HomeNew from './pages/HomeNew'
import Log from './pages/Log'
import Login from './pages/Login'
import PagesEditor from './pages/PagesEditor'
import Player from './pages/Player'
import Preflight from './pages/Preflight'
import ShowNew from './pages/ShowNew'
import Shows from './pages/Shows'
import analytics from './utils/analytics'
import auth from './utils/auth'

function Pageviewer ({ location }) {
  useEffect(() => analytics.pageview(location.pathname), [location])
  return <></>
}

function ProtectedArea ({ path, rights, redirect, inject, children, notFound }) {
  return (
    <Route
      path={path} render={() => {
        if (!auth.hasRights(rights)) {
          return <Redirect to={redirect} />
        }
        if (inject) inject()
        return (
          <Switch>
            {children}
            {notFound !== false && <Redirect to={notFound || path} />}
          </Switch>
        )
      }}
    />
  )
}

function App () {
  return (
    <Router>
      <Route component={Pageviewer} />
      <Switch>

        <Redirect from='/player' to='/midsummer' />
        <Redirect from='/editor' to='/backstage' />

        <Route exact path='/backstage/login' component={BackstageLogin} />
        <ProtectedArea
          path='/backstage'
          rights='editor'
          redirect='/backstage/login'
          inject={() => analytics.event.backstage()}
        >
          <ProtectedArea
            path='/backstage/analytics/users'
            rights='admin'
            redirect='/backstage/analytics'
          >
            <Route exact path='/backstage/analytics/users/:user' component={AnalyticsUsers} />
            <Route exact path='/backstage/analytics/users' component={AnalyticsUsers} />
          </ProtectedArea>
          <ProtectedArea
            path='/backstage/analytics/process'
            rights='admin'
            redirect='/backstage/analytics'
          >
            <Route exact path='/backstage/analytics/process/:view' component={AnalyticsProcess} />
            <Route exact path='/backstage/analytics/process' component={AnalyticsProcess} />
          </ProtectedArea>
          <ProtectedArea
            path='/backstage/admin'
            rights='admin'
            redirect='/backstage'
            notFound='/backstage'
          >
            <Route exact path='/backstage/admin/database' component={Database} />
          </ProtectedArea>
          <Route exact path='/backstage/analytics/log' component={Log} />
          <Route exact path='/backstage/analytics' component={Analytics} />
          <Redirect from='/backstage/analytics/:show' to='/backstage/:show/analytics' />
          <Route exact path='/backstage/:show/analytics' component={AnalyticsShow} />
          <Route exact path='/backstage/:show/preflight' component={Preflight} />
          <Route exact path='/backstage/:show/pages/:page' component={PagesEditor} />
          <Redirect from='/backstage/:show/pages' to='/backstage/:show/pages/index' />
          <Route exact path='/backstage/:show/:scene/:editor' component={Editor} />
          <Route exact path='/backstage/:show' component={BackstageShow} />
          <Route exact path='/backstage' component={Backstage} />
        </ProtectedArea>

        <ProtectedArea
          path='/preview'
          rights='preview'
          redirect='/backstage/login'
          inject={() => analytics.event.preview()}
        >
          <Redirect exact from='/preview/:show/play' to='/preview/:show/play/start' />
          <Route exact path='/preview/:show/play/:scene' component={Player} />
          <Redirect exact from='/preview/:show/index' to='/preview/:show' />
          <Route exact path='/preview/:show/:page' component={ShowNew} />
          <Route exact path='/preview/:show' component={ShowNew} />
          <Route exact path='/preview' component={HomeNew} />
        </ProtectedArea>

        <ProtectedArea
          path='/record'
          rights='preview'
          redirect='/'
          notFound='/'
        >
          <Route exact path='/record/:show/play/:scene' component={Player} />
          <Redirect from='/record/:show' to='/record/:show/play/start' />
        </ProtectedArea>

        <Route exact path='/account/:view' component={Account} />
        <Redirect from='/account' to='/account/profile' />

        <Route exact path='/shows' component={Shows} />
        <Route exact path='/login' component={Login} />

        <Redirect exact from='/:show/play' to='/:show/play/start' />
        <Route exact path='/:show/play/:scene' component={Player} />
        <Redirect exact from='/:show/index' to='/:show' />
        <Route exact path='/:show/:page' component={ShowNew} />
        <Route exact path='/:show' component={ShowNew} />

        <Route exact path='/' component={HomeNew} />
        <Redirect to='/' />

      </Switch>
    </Router>
  )
}

export default App
