import React, { useState, useMemo, useEffect } from 'react'
import Button from './Button'
import { Row, Col } from './layout'
import urls from '../data/urls'
import tipsImg from '../assets/timecoding.svg'
import '../styles/EditorAudio.css'

const audios = {}
function getAudio (file) {
  // eslint-disable-next-line
  if (!audios[file]) audios[file] = new Audio(urls.sceneAudio(file))
  return audios[file]
}

function formatTime (time, fuzzy) {
  if (!time) return null
  if (fuzzy) return `${Math.floor(time / 60)}:${Math.floor(time % 60).toString().padStart(2, '0')}`
  return `${Math.floor(time / 60)}:${Math.floor(time % 60).toString().padStart(2, '0')}.${Math.floor(time * 10 % 10)}`
}

export default function EditorAudio ({ dbRef, scene, sceneID, ...props }) {
  const audio = getAudio(sceneID)

  // let existingTimes = []
  // if (scene.audio && scene.audio.times) existingTimes = scene.audio.times || []
  let curProgress = 0
  try {
    curProgress = audio.buffered.end(0)
    // audio.readyState > 1 ? audio.buffered.end(0) : 0
  } catch (e) {
    console.error(e)
  }
  const [cachedScene, setCachedScene] = useState(sceneID)
  const [curLine, setCurLine] = useState(0)
  const [times, setTimes] = useState([])
  const [recording, setRecording] = useState(false)
  const [previewing, setPreviewing] = useState(false)
  const [ready, setReady] = useState(audio.readyState === 4)
  const [progress, setProgress] = useState(curProgress)
  const [playhead, setPlayhead] = useState(audio.currentTime)

  useEffect(() => {
    if (
      scene.audio &&
      scene.audio.times &&
      scene.audio.times.length > 0 &&
      times.length === 0 &&
      scene.id === sceneID
    ) {
      setTimes(scene.audio.times)
    }
  }, [scene.audio, times.length, sceneID, scene.id])

  const previewLine = times.findIndex(t => t > playhead + 0.2) - 1

  function startRecording () {
    setRecording(true)
    setTimes(times.slice(0, curLine))
    audio.play()
  }

  function stopRecording () {
    setRecording(false)
    audio.pause()
  }

  function startPreviewing () {
    setPreviewing(true)
    audio.play()
  }

  function stopPreviewing () {
    setPreviewing(false)
    audio.pause()
  }

  function mark () {
    setTimes([...times, Math.round(audio.currentTime * 100) / 100])
    setCurLine(n => n + 1)
  }

  function unmark () {
    audio.currentTime = times[times.length - 1] - 2
    setTimes(times.slice(0, times.length - 1))
    setCurLine(n => n - 1)
  }

  function replaceTime (i) {
    // eslint-disable-next-line
    const val = parseFloat(prompt('Enter a new time', times[i]))
    if (!val) return
    const newTimes = times.slice()
    newTimes[i] = val
    setTimes(newTimes)
  }

  useEffect(() => {
    return () => {
      setPreviewing(false)
      setRecording(false)
      audio.pause()
    }
  }, [audio, sceneID])

  useEffect(() => {
    const handleCanPlayThrough = () => setReady(true)
    const handleProgress = () => {
      try {
        setProgress(audio.buffered.end(0))
      } catch (e) {
        console.error(e)
        setProgress(0)
      }
    }
    const handleTimeUpdate = () => setPlayhead(audio.currentTime)
    const handleEnded = () => setRecording(false)

    audio.addEventListener('canplaythrough', handleCanPlayThrough)
    audio.addEventListener('progress', handleProgress)
    audio.addEventListener('timeupdate', handleTimeUpdate)
    audio.addEventListener('ended', handleEnded)

    return () => {
      audio.removeEventListener('canplaythrough', handleCanPlayThrough)
      audio.removeEventListener('progress', handleProgress)
      audio.removeEventListener('timeupdate', handleTimeUpdate)
      audio.removeEventListener('ended', handleEnded)
    }
  }, [audio, setReady, setProgress, setPlayhead, setRecording])

  useEffect(() => {
    const handleKeyDown = event => {
      const actions = {}
      if (ready) {
        actions.ArrowRight = () => { audio.currentTime += 2 }
        actions.ArrowLeft = () => { audio.currentTime -= 2 }
      }
      if (recording) {
        actions.Space = () => {
          setTimes(times => [...times, Math.round(audio.currentTime * 100) / 100])
          setCurLine(n => n + 1)
        }
        actions.ShiftLeft = () => {
          audio.currentTime = times[times.length - 1] - 1
          setTimes(times.slice(0, times.length - 1))
          setCurLine(n => n - 1)
        }
      }
      if (actions[event.code]) {
        event.preventDefault()
        actions[event.code]()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [audio, ready, recording, times, setTimes, setCurLine])

  const lines = useMemo(() => {
    const lines = []
    scene.blocks.forEach(block => {
      if (!block.lines) return
      block.lines.forEach((line, i) => {
        if (line.dir) return
        const l = { line, i: lines.length }
        if (i === 0) l.voice = block.voice
        lines.push(l)
      })
    })
    lines.push({ voice: '[end of scene]', line: '', i: lines.length })
    return lines
  }, [scene.blocks])

  if (sceneID !== cachedScene) {
    // console.log(`clearing from ${cachedScene} to ${sceneID}`)
    setCachedScene(sceneID)
    stopRecording()
    stopPreviewing()
    setCurLine(0)
    setTimes([])
    setReady(audio.readyState === 4)
    setProgress(curProgress)
    setPlayhead(audio.currentTime)
    return <></>
  }

  return (
    <>
      <Row className={'editoraudio' + (recording ? ' recording' : '')}>
        <Col width={3} className='control-column'>

          {/* <div className='btn-group'>
            <Button color='secondary' onClick={() => setCurLine(n => n - 1)}>↑</Button>
            <Button color='secondary' onClick={() => setCurLine(n => n + 1)}>↓</Button>
          </div> */}

          <div className='progress mb-3' style={{ height: progress > audio.duration - 1 ? '1em' : '1.5em', transition: 'height .5s ease-out' }}>
            <div className='progress-bar bg-danger' style={{ width: (playhead / audio.duration * 100) + '%' }} />
            <div className='progress-bar' style={{ background: '#bbb', width: ((progress - audio.currentTime) / audio.duration * 100) + '%' }}>
              {audio.networkState === 2 && formatTime(progress - audio.currentTime, true)}
            </div>
          </div>

          {!previewing && (recording
            ? <Button color='danger' onClick={stopRecording}>Stop Recording</Button>
            : ready
              ? <Button color='danger' outline onClick={startRecording}>Record</Button>
              : <Button color='danger' outline disabled><span className='spinner-border spinner-border-sm mr-2' /> Record</Button>)}

          {(recording || previewing) &&
            <div className='btn-group'>
              <Button color='dark' outline onClick={() => { audio.currentTime -= 3 }}>↺</Button>
              <Button color='dark' disabled>{formatTime(playhead, true) || '0:00'}</Button>
              <Button color='dark' outline onClick={() => { audio.currentTime += 3 }}>↻</Button>
            </div>}

          {!recording && (previewing
            ? <Button color='info' onClick={stopPreviewing}>Pause Preview</Button>
            : ready
              ? <Button color='info' outline onClick={startPreviewing}>Preview</Button>
              : <Button color='info' outline disabled><span className='spinner-border spinner-border-sm mr-2' /> Preview</Button>)}

          {recording &&
            <div className='btn-group'>
              <Button outline disabled={times.length === 0} onClick={unmark}>Unmark</Button>
              <Button onClick={mark}>Mark Line</Button>
            </div>}

          {!recording && !previewing &&
            <Button color='success' disabled={times.length === 0} outline={times.length !== lines.length} onClick={() => dbRef.update({ times })}>Save</Button>}

          <div className='small text-muted mt-3'>
            {times.some((time, i) => time < (times[i - 1] || 0.1)) &&
              <p className='text-danger'>Potential incorrect timecodes detected; check that marks line up as expected. You can click the text of lines to seek to that portion of audio. Click - to remove any unexpected marks.</p>}
            <img src={tipsImg} alt='' className='img-fluid' />
            <p>To record timecodes for a scene, click Record, then click Mark Line at the moment the highlighted line begins. Use Unmark to go back and change the previous mark. When finished, click Stop Recording, then Save.</p>
            <p>
              <b>Shortcuts</b><br />
              Space — Mark line<br />
              Shift — Unmark line<br />
              ←/→ — Skip forward/back<br />
              ↑/↓ — Scroll up/down
            </p>
            <p>Click a timecode to manually adjust. Click a line to jump to it; recording or previewing will resume from that line.</p>
            <p className='font-italic'>Laggy? Refresh the page to clear previous scenes from memory.</p>
          </div>

        </Col>
        <Col width={9}>
          {lines.map(line => (
            <div key={line.i} className={'audio-line' + (curLine === line.i ? ' editor-current' : '')}>
              <div className='data line-index'>
                {line.i}
              </div>
              {(recording && curLine === line.i) || (previewing && previewLine === line.i)
                ? <div className='data line-current'>➤</div>
                : <div className='data line-time' role='button' title='Manually enter time' onClick={() => replaceTime(line.i)}>{formatTime(times[line.i]) || '———————'}</div>}
              {times[line.i] < (times[line.i - 1] || 0.1) &&
                <div className='data bit text-danger' title='Potential issue detected'>!!</div>}
              <div
                role='button'
                title='Seek audio to here'
                onClick={() => {
                  audio.currentTime = times[line.i] || times[times.length - 1] || 0
                  setCurLine(line.i)
                }}
              >
                <span className='line-voice'>{line.voice}</span>
                {line.line}
              </div>
              <div
                className='data bit ml-auto'
                role='button'
                title='Remove this mark'
                onClick={() => {
                  const newTimes = times.slice()
                  newTimes.splice(line.i, 1)
                  setTimes(newTimes)
                }}
              >-
              </div>
              <div
                className='data bit'
                role='button'
                title='Insert a new mark below'
                onClick={() => {
                  const newTimes = times.slice()
                  newTimes.splice(line.i, 0, times[line.i] + 0.2)
                  setTimes(newTimes)
                }}
              >+
              </div>
            </div>
          ))}
          {times.slice(lines.length).map((time, i) => (
            <div key={'extra-' + time} className='audio-line'>
              <div className='data line-index'>
                {i + lines.length}
              </div>
              <div className='data line-time' role='button' title='Manually enter time' onClick={() => replaceTime(i + lines.length)}>{formatTime(time) || '———————'}</div>
              <div
                role='button'
                title='Seek audio to here'
                onClick={() => {
                  audio.currentTime = time || times[times.length - 1] || 0
                  setCurLine(i + lines.length)
                }}
              >
                {i === 0 && <span className='line-voice text-danger'>Overflow Timecodes</span>}
                ———————
              </div>
              <div
                className='data bit ml-auto'
                role='button'
                title='Remove this mark'
                onClick={() => {
                  const newTimes = times.slice()
                  newTimes.splice(i + lines.length, 1)
                  setTimes(newTimes)
                }}
              >-
              </div>
              <div
                className='data bit'
                role='button'
                title='Insert a new mark below'
                onClick={() => {
                  const newTimes = times.slice()
                  newTimes.splice(i + lines.length, 0, time + 0.2)
                  setTimes(newTimes)
                }}
              >+
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </>
  )
}
