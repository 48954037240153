import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import Navbar from '../components/Navbar'
import db from '../utils/db'
import { preflightScene, preflightShow, levelRanks, levelColors } from '../utils/preflight'
import sceneTitle from '../utils/sceneTitle'

function IssueList ({ summary, sortLevel, trim }) {
  const out = Object.keys(summary.occur).sort((a, b) => summary.occur[b] - summary.occur[a])
  if (sortLevel) out.sort((a, b) => levelRanks.indexOf(summary.display[a].level) - levelRanks.indexOf(summary.display[b].level))
  let excess = []
  if (trim) {
    excess = out.splice(4)
  }
  return (
    <>
      {summary.count > 0 &&
        out.map(type => (
          <span key={type} className='pl-2' style={{ borderLeft: `3px solid var(--${levelColors[summary.display[type].level]})` }}>
            {summary.display[type].title} ({summary.occur[type]})
          </span>
        ))}
      {excess.length > 4 &&
        <span className='font-italic'>... and {excess.length} more</span>}
    </>
  )
}

export default function Preflight ({ match }) {
  const [filter, setFilter] = useState(window.localStorage.getItem('preflightFilter') || '')
  const filterArr = filter.split(' ')
  const [filterInclude, setFilterInclude] = useState(false)
  const [sortLevel, setSortLevel] = useState(true)
  const [trim, setTrim] = useState(true)

  const showID = match.params.show

  const [show, setShow] = useState({ loading: true })
  useEffect(() => {
    return db.subscribe('Preflight_show', `/staging/shows/${showID}`, setShow)
  }, [showID, setShow])

  const [scenes, setScenes] = useState({ loading: true })
  useEffect(() => {
    return db.subscribe('Preflight_scenes', '/staging/scenes', setScenes)
  }, [showID, setScenes])

  if (show.loading || scenes.loading) {
    return (
      <>
        <Navbar back='/backstage' title='Analytics' />
        <div className='container card-columns'>
          <div className='card'>
            <div className='card-body d-flex flex-column rounded-sm position-relative' style={{ borderLeft: '6px solid var(--secondary)' }}>
              <h6 className='card-title mt-0'>Loading...</h6>
            </div>
          </div>
        </div>
      </>
    )
  }

  const summaries = []
  show.scenes.forEach(sceneID => {
    const results = preflightScene(scenes[sceneID], show.chars).filter(msg => !filterArr.includes(msg.type))

    const occur = {}
    const display = {}
    let worst = 'clear'
    results.forEach(msg => {
      occur[msg.type] = (occur[msg.type] || 0) + 1
      if (!display[msg.type]) {
        display[msg.type] = {
          title: msg.title,
          level: msg.level
        }
      }
      if (levelRanks.indexOf(msg.level) < levelRanks.indexOf(worst)) {
        worst = msg.level
      }
    })

    summaries.push({
      id: sceneID,
      blocks: scenes[sceneID].blocks.length,
      size: JSON.stringify(scenes[sceneID]).length,
      count: results.length,
      worst,
      occur,
      display
    })
  })

  const showResults = preflightShow(show)
  const showOccur = {}
  const showDisplay = {}
  let showWorst = 'clear'
  showResults.forEach(msg => {
    showOccur[msg.type] = (showOccur[msg.type] || 0) + 1
    if (!showDisplay[msg.type]) {
      showDisplay[msg.type] = {
        title: msg.title,
        level: msg.level
      }
    }
    if (levelRanks.indexOf(msg.level) < levelRanks.indexOf(showWorst)) {
      showWorst = msg.level
    }
  })

  const showSummary = {
    count: showResults.length,
    worst: showWorst,
    occur: showOccur,
    display: showDisplay
  }

  return (
    <>
      <Navbar back='/backstage' title='Preflight' />
      <div className='container mb-3'>
        <div className='d-flex align-items-stretch'>
          <div className='btn-group mr-3 flex-shrink-0'>
            <Button color='secondary' outline={sortLevel} onClick={() => setSortLevel(false)}>By Count</Button>
            <Button color='secondary' outline={!sortLevel} onClick={() => setSortLevel(true)}>By Level</Button>
          </div>
          <div className='btn-group mr-3 flex-shrink-0'>
            <Button color='secondary' outline={!trim} onClick={() => setTrim(true)}>Top</Button>
            <Button color='secondary' outline={trim} onClick={() => setTrim(false)}>All</Button>
          </div>
          <div className='border-right mr-3 my-1' />
          <div className='btn-group mr-3 flex-shrink-0'>
            <Button color='secondary' outline={!filterInclude} onClick={() => setFilterInclude(true)}>Include</Button>
            <Button color='secondary' outline={filterInclude} onClick={() => setFilterInclude(false)}>Exclude</Button>
          </div>
          <input
            className='form-control'
            type='text'
            placeholder='Filter...'
            value={filter}
            onChange={e => setFilter(e.target.value)}
            onBlur={() => window.localStorage.setItem('preflightFilter', filter)}
          />
        </div>
      </div>
      <div className='container card-columns'>

        <div className='card'>
          <div className='card-body d-flex flex-column rounded-sm position-relative' style={{ borderLeft: `6px solid var(--${levelColors[showSummary.worst]})` }}>
            <h5 className='card-title my-0 font-weight-bold'>{show.title}</h5>
            <h6 className='card-title my-1 text-muted'>{showSummary.count} issues</h6>
            <IssueList summary={showSummary} sortLevel={sortLevel} trim={trim} />
          </div>
        </div>

        {summaries.map(summary => (
          <div className='card' key={summary.id}>
            <div className='card-body d-flex flex-column rounded-sm position-relative' style={{ borderLeft: `6px solid var(--${levelColors[summary.worst]})` }}>
              <span className='small text-muted' style={{ position: 'absolute', top: '.5em', right: '.5em' }}>
                #{summary.blocks} ({Math.ceil(summary.size / 1000)} KB)
              </span>
              <Link to={summary.id.split('_')[1] + '/preflight'} className='text-decoration-none'>
                <h5 className='card-title my-0 text-dark'>{sceneTitle(summary.id)}</h5>
              </Link>
              <h6 className='card-title my-1 text-muted'>{summary.count} issues</h6>
              <IssueList summary={summary} sortLevel={sortLevel} trim={trim} />
            </div>
          </div>
        ))}

      </div>
    </>
  )
}
