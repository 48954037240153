export const levelRanks = [
  'fatal',
  'error',
  'warn',
  'alert',
  'info',
  'todo',
  'nitpick',
  'clear'
]
export const levelColors = {
  fatal: 'gray-dark',
  error: 'red',
  warn: 'orange',
  alert: 'yellow',
  info: 'gray',
  todo: 'cyan',
  nitpick: 'gray-light',
  clear: 'success'
}

export function preflightScene (scene, chars, dbRef) {
  console.log(scene)
  const msgs = []

  const charList = Object.values(chars || {}).map(char => [char.name]).flat(2)
  let lineCount = 0
  const importances = { high: 0, mid: 0, low: 0, unset: 0 }

  if (!scene.blocks || scene.blocks.length === 0) {
    msgs.push({
      type: 'noBlocks',
      title: 'No block data in scene',
      level: 'info'
    })
  } else {
    if (!scene.blocks[0].dir) {
      msgs.push({
        type: 'startingDir',
        title: 'No dir at start of scene',
        level: 'info',
        block: 0,
        blockData: scene.blocks[0]
      })
    }

    if (!scene.blocks[scene.blocks.length - 1].dir) {
      msgs.push({
        type: 'endingDir',
        title: 'No dir at end of scene',
        level: 'info',
        block: scene.blocks.length - 1,
        blockData: scene.blocks[scene.blocks.length - 1]
      })
    }

    scene.blocks.forEach((block, blockIndex) => {
      try {
        if (block.dir === '') {
          msgs.push({
            type: 'emptyDir',
            title: 'Empty dir block',
            level: 'fatal',
            quoteType: 'dir',
            fixedData: '___',
            fix: () => dbRef.child(`/blocks/${blockIndex}/dir`).set('___'),
            block: blockIndex,
            blockData: { dir: '(empty)' }
          })
          return
        }

        if (block.lines && block.lines.length === 0) {
          msgs.push({
            type: 'emptyVoice',
            title: 'Empty block voice',
            level: 'fatal',
            quoteType: 'voice',
            fixedData: '___',
            fix: () => dbRef.child(`/blocks/${blockIndex}/voice`).set('___'),
            block: blockIndex,
            blockData: { ...block, voice: '(empty)' }
          })
          return
        }

        if (block.lines && block.lines.length === 0) {
          msgs.push({
            type: 'emptyLines',
            title: 'Empty block lines',
            level: 'fatal',
            quoteType: 'line',
            fixedData: '___',
            fix: () => dbRef.child(`/blocks/${blockIndex}/lines/0`).set('___'),
            block: blockIndex,
            blockData: { ...block, lines: ['(empty)'] }
          })
          return
        }

        if (block.dir) {
          const nextBlock = scene.blocks[blockIndex + 1]
          const prevBlock = scene.blocks[blockIndex - 1]
          if (
            prevBlock &&
            prevBlock.lines &&
            nextBlock &&
            nextBlock.voice &&
            (/\w\s\w/).test(nextBlock.voice) &&
            !charList.includes(nextBlock.voice)) {
            msgs.push({
              type: 'maimedBlock',
              title: 'Maimed block detected',
              level: 'warn',
              quote: `${prevBlock.lines[prevBlock.lines.length - 1]}  /  ${block.dir}  /  ${nextBlock.voice}`,
              quoteType: 'line',
              block: blockIndex,
              blockData: block,
              fix: () => {
                const newNotes = (nextBlock.notes || []).map(origNote => ({
                  ...origNote,
                  line: origNote.line + prevBlock.lines.length + 2
                }))
                const newBlock = {
                  ...prevBlock,
                  lines: [
                    ...prevBlock.lines,
                    { dir: block.dir },
                    nextBlock.voice,
                    ...nextBlock.lines
                  ],
                  notes: [...(prevBlock.notes || []), ...newNotes]
                }
                const newBlocks = [...scene.blocks]
                newBlocks.splice(blockIndex - 1, 3, newBlock)
                dbRef.child('/blocks').set(newBlocks)
              }
            })
          }
        } else {
          if (!(/^[A-Z]\w*(?: \w+)?(?: [([].+[)\]])?$/).test(block.voice)) {
            msgs.push({
              type: 'voiceMatch',
              title: 'Voice format failed',
              level: 'alert',
              quote: block.voice,
              quoteType: 'voice',
              edit: edited => dbRef.child(`/blocks/${blockIndex}/voice`).set(edited),
              block: blockIndex,
              blockData: block
            })
          } else if ((/^[\w ]* [([].*[)\]]$/).test(block.voice)) {
            msgs.push({
              type: 'voiceAudience',
              title: 'Voice has audience',
              level: 'info',
              quote: block.voice,
              quoteType: 'voice',
              edit: edited => dbRef.child(`/blocks/${blockIndex}/voice`).set(edited),
              block: blockIndex,
              blockData: block
            })
          } else if ((/^[\w ]* & [\w ]*$/).test(block.voice)) {
            msgs.push({
              type: 'voiceJoint',
              title: 'Joint voice',
              level: 'info',
              quote: block.voice,
              quoteType: 'voice',
              edit: edited => dbRef.child(`/blocks/${blockIndex}/voice`).set(edited),
              block: blockIndex,
              blockData: block
            })
          } else if (chars && !charList.includes(block.voice.replace(/ [([].*/, ''))) {
            msgs.push({
              type: 'voiceList',
              title: 'Unknown voice',
              level: 'info',
              quote: block.voice,
              quoteType: 'voice',
              edit: edited => dbRef.child(`/blocks/${blockIndex}/voice`).set(edited),
              block: blockIndex,
              blockData: block
            })
          }

          block.lines.forEach((line, lineIndex) => {
            if (line.dir) {
              if (line.dir.includes("'") || line.dir.includes('"')) {
                msgs.push({
                  type: 'typoQuote',
                  title: 'Straight quotes',
                  level: 'nitpick',
                  quote: line.dir,
                  quoteType: 'dir',
                  fixedData: line.dir.replaceAll("'", '’'),
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}/dir`).set(edited),
                  fix: function () { this.edit(this.fixedData) },
                  block: blockIndex,
                  blockData: block
                })
              }

              if (line.dir.includes(' - ') || line.dir.includes('--')) {
                msgs.push({
                  type: 'typoDash',
                  title: 'Dashes',
                  level: 'nitpick',
                  quote: line.dir,
                  quoteType: 'dir',
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}/dir`).set(edited),
                  block: blockIndex,
                  blockData: block
                })
              }
            } else {
              lineCount++

              if (charList.includes(line.replace(/[^\w ]/g, ''))) {
                msgs.push({
                  type: 'charMention',
                  title: 'Solo character mention',
                  level: 'info',
                  quote: line,
                  quoteType: 'line',
                  details: `Line ${lineIndex + 1} of ${block.lines.length} in block by ${block.voice}.`,
                  block: blockIndex,
                  blockData: block
                })
              }

              if ((/^\W*(?:Enter|Exit|Exeunt)/).test(line)) {
                msgs.push({
                  type: 'lineEnter',
                  title: 'Line looks like dir',
                  level: 'warn',
                  quote: line,
                  quoteType: 'line',
                  fix: () => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set({ dir: line }),
                  block: blockIndex,
                  blockData: block
                })
              }

              if ((/[>{}[\]]/).test(line)) {
                msgs.push({
                  type: 'artisanDir',
                  title: 'Directive fragment in line',
                  level: 'alert',
                  quote: line,
                  quoteType: 'line',
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set(edited),
                  block: blockIndex,
                  blockData: block
                })
              }

              if ((/[^A-Za-z .?!,;:()'"\-‘’“”–—>{}[\]]/).test(line)) {
                msgs.push({
                  type: 'exoticCharacter',
                  title: 'Exotic codepoint',
                  level: 'alert',
                  quote: line,
                  quoteType: 'line',
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set(edited),
                  block: blockIndex,
                  blockData: block
                })
              }

              if (line.includes("'") || line.includes('"')) {
                msgs.push({
                  type: 'typoQuote',
                  title: 'Straight quotes',
                  level: 'nitpick',
                  quote: line,
                  quoteType: 'line',
                  fixedData: line.replaceAll("'", '’'),
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set(edited),
                  fix: function () { this.edit(this.fixedData) },
                  block: blockIndex,
                  blockData: block
                })
              }

              if (line.includes(' -') || line.includes('--')) {
                msgs.push({
                  type: 'typoDash',
                  title: 'Hyphen dash',
                  level: 'nitpick',
                  quote: line,
                  quoteType: 'line',
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set(edited),
                  block: blockIndex,
                  blockData: block
                })
              }

              if ((/(\S–|–\S| - |‘[^’]+$)/).test(line)) {
                msgs.push({
                  type: 'typoFancySus',
                  title: 'Questionable character',
                  level: 'nitpick',
                  quote: line,
                  quoteType: 'line',
                  fixedData: line.replaceAll(/[‘]/g, "'").replaceAll(/[–—]/g, ' - '),
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set(edited),
                  fix: function () { this.edit(this.fixedData) },
                  block: blockIndex,
                  blockData: block
                })
              } else if ((/[–—‘]/).test(line)) {
                msgs.push({
                  type: 'typoFancy',
                  title: 'Typographic character',
                  level: 'nitpick',
                  quote: line,
                  quoteType: 'line',
                  fixedData: line.replaceAll(/[‘]/g, "'").replaceAll(/[–—]/g, ' - '),
                  edit: edited => dbRef.child(`/blocks/${blockIndex}/lines/${lineIndex}`).set(edited),
                  fix: function () { this.edit(this.fixedData) },
                  block: blockIndex,
                  blockData: block
                })
              }
            }
          })

          if (block.notes) {
            block.notes.forEach((note, noteIndex) => {
              importances[note.importance || 'unset']++

              if (note.img) {
                if (!(/\.\w{3,8}/).test(note.img)) {
                  msgs.push({
                    type: 'noteImgFormat',
                    title: 'Note image filename unrecognized',
                    level: 'warn',
                    quote: note.img,
                    quoteType: 'id',
                    edit: edited => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/img`).set(edited),
                    block: blockIndex,
                    blockData: block
                  })
                }
              } else if (note.vimeo) {
                if (!(/^\d{9}$/).test(note.vimeo)) {
                  msgs.push({
                    type: 'noteVimeoFormat',
                    title: 'Note Vimeo ID incorrect',
                    level: 'warn',
                    quote: note.vimeo,
                    quoteType: 'id',
                    edit: edited => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/vimeo`).set(edited),
                    block: blockIndex,
                    blockData: block
                  })
                }

                if (!note.vimeoThumb) {
                  msgs.push({
                    type: 'noteVimeoThumb',
                    title: 'Note Vimeo thumbnail missing',
                    level: 'alert',
                    quote: note.title,
                    quoteType: 'noteTitle',
                    origData: '',
                    edit: edited => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/vimeoThumb`).set(edited),
                    block: blockIndex,
                    blockData: block
                  })
                }
              } else if (!note.title && !note.body) {
                msgs.push({
                  type: 'noteContentMissing',
                  title: 'Blank note',
                  level: 'warn',
                  quote: note.type,
                  quoteType: 'id',
                  block: blockIndex,
                  blockData: block
                })
              } else if (!note.title) {
                msgs.push({
                  type: 'noteTitleMissing',
                  title: 'Untitled note',
                  level: 'alert',
                  quote: note.body,
                  quoteType: 'noteBody',
                  block: blockIndex,
                  blockData: block
                })
              } else if (!note.body) {
                msgs.push({
                  type: 'noteBodyMissing',
                  title: 'Note without body',
                  level: 'alert',
                  quote: note.title,
                  quoteType: 'noteTitle',
                  block: blockIndex,
                  blockData: block
                })
              }

              if (note.line >= block.lines.length) {
                msgs.push({
                  type: 'noteLineOver',
                  title: 'Note pinned beyond block end',
                  level: 'warn',
                  quote: note.title,
                  quoteType: 'noteTitle',
                  details: `Pinned to line ${note.line}; last line is ${block.lines.length - 1}`,
                  fix: () => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/line`).set(0),
                  block: blockIndex,
                  blockData: block
                })
                return
              }

              if (note.loc !== undefined && note.loc + note.len > block.lines[note.line].length) {
                msgs.push({
                  type: 'noteMarkOver',
                  title: 'Note marked beyond line end',
                  level: 'warn',
                  quote: note.title,
                  quoteType: 'noteTitle',
                  details: `Mark ends at ${note.loc + note.len}; line length is ${block.lines[note.line].length}`,
                  fix: () => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/len`).set(block.lines[note.line].length - note.loc),
                  block: blockIndex,
                  blockData: block
                })
              }

              if (note.overflow && note.loc + note.len < block.lines[note.line].length) {
                msgs.push({
                  type: 'noteMarkDisconnected',
                  title: 'Overflowed note mark is disconnected',
                  level: 'warn',
                  quote: note.title,
                  quoteType: 'noteTitle',
                  details: `Mark ends ${block.lines[note.line].length - note.loc - note.len} chars before line end`,
                  fix: () => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/len`).set(block.lines[note.line].length - note.loc),
                  block: blockIndex,
                  blockData: block
                })
              }

              if (note.vimeo || note.img) {
                if (note.loc !== undefined) {
                  msgs.push({
                    type: 'markedContentNote',
                    title: 'Marked content note',
                    level: 'info',
                    quote: note.title,
                    quoteType: 'noteTitle',
                    fix: () => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}`).update({ loc: null, len: null }),
                    block: blockIndex,
                    blockData: block
                  })
                }
              } else {
                if (note.loc === undefined) {
                  msgs.push({
                    type: 'unmarkedNote',
                    title: 'Unmarked note',
                    level: 'info',
                    quote: note.title,
                    quoteType: 'noteTitle',
                    fix: () => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}`).update({ loc: 0, len: 0 }),
                    block: blockIndex,
                    blockData: block
                  })
                }
              }

              if (note.loc !== undefined) {
                const strFirst = (block.lines[note.line]).slice(note.loc, note.loc + note.len)
                let refStr = strFirst
                const nextLine = block.lines[note.line + 1]
                if (note.overflow && nextLine && !nextLine.dir) {
                  refStr = strFirst + ' ' + nextLine.charAt(0).toLowerCase() + nextLine.slice(1, note.overflow)
                }

                if (refStr.toLowerCase() !== note.title.toLowerCase()) {
                  msgs.push({
                    type: 'noteTitleMismatch',
                    title: 'Note title mismatch',
                    level: 'alert',
                    quote: note.title,
                    quoteType: 'noteTitle',
                    fixedData: refStr.charAt(0).toUpperCase() + refStr.slice(1),
                    edit: edited => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/title`).set(edited),
                    fix: function () { this.edit(this.fixedData) },
                    block: blockIndex,
                    blockData: block
                  })
                }

                if (!(/^[A-Za-z'‘’"“].*[\w'’"”.?!]$/).test(note.title)) {
                  msgs.push({
                    type: 'noteTitleFormat',
                    title: 'Note title format failed',
                    level: 'info',
                    quote: note.title,
                    quoteType: 'noteTitle',
                    fixedData: refStr.charAt(0).toUpperCase() + refStr.slice(1).trimEnd(),
                    edit: edited => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/title`).set(edited),
                    fix: function () { this.edit(this.fixedData) },
                    block: blockIndex,
                    blockData: block
                  })
                }

                // if (!note.overflow && !(new RegExp(`^.{${note.loc}}\b${note.title}\b`, 'i').test(block.lines[note.line]))) {
                //   const thisLine = block.lines[note.line]
                //   msgs.push({
                //     type: 'noteTitle',
                //     title: 'Note title inaccurate',
                //     level: 'info',
                //     quote: note.title,
                //     quoteType: 'noteTitle',
                //     details: `${thisLine.slice(0, note.loc)}[${thisLine.slice(note.loc, note.loc + note.len)}]${thisLine.slice(note.loc + note.len)}`,
                //     edit: edited => dbRef.child(`/blocks/${blockIndex}/notes/${noteIndex}/title`).set(edited),
                //     block: blockIndex,
                //     blockData: block
                //   })
                // }

                if (noteIndex > 0) {
                  const prevNote = block.notes[noteIndex - 1]
                  if (prevNote.line > note.line ||
                    (prevNote.line === note.line &&
                      note.loc !== undefined &&
                      note.loc < (prevNote.loc || 0))) {
                    msgs.push({
                      type: 'notesUnsorted',
                      title: 'Notes not sorted correctly',
                      level: 'error',
                      quote: note.title,
                      quoteType: 'noteTitle',
                      fix: () => {
                        scene.blocks.forEach((fixBlock, i) => {
                          if (!fixBlock.notes) return
                          dbRef.child(`/blocks/${i}/notes`).set(fixBlock.notes.sort((a, b) => (a.line * 100 + (a.loc || -1) - (b.line * 100 + (b.loc || -1)))))
                        })
                      },
                      block: blockIndex,
                      blockData: block
                    })
                  }
                }
              }
            })
          }
        }
      } catch (ex) {
        msgs.push({
          type: 'blockError',
          title: 'Error parsing block',
          level: 'fatal',
          details: String(ex),
          block: blockIndex
        })
      }
    })

    if (!scene.summary && scene.id.includes('-')) {
      msgs.push({
        type: 'summaryMissing',
        title: 'Needs scene synopsis',
        level: 'todo',
        origData: '',
        edit: edited => dbRef.child('/summary').set(edited)
      })
    }

    if (importances.high + importances.mid + importances.low + importances.unset === 0 && scene.id.includes('-')) {
      msgs.push({
        type: 'notesMissing',
        title: 'No notes added',
        level: 'todo'
      })
    }

    if (importances.high === 0 && importances.low === 0 && importances.unset > 0) {
      msgs.push({
        type: 'importanceMissing',
        title: 'Needs note importances',
        level: 'todo'
      })
    }

    if (scene.audio && scene.audio.times) {
      if (scene.audio.times.length < lineCount - 2) {
        msgs.push({
          type: 'timeIncomplete',
          title: 'Timecoding incomplete',
          level: 'todo',
          details: `${lineCount} lines in scene, ${scene.audio.times.length - 1} lines timecoded`
        })
      } else if (scene.audio.times.length !== lineCount + 1) {
        msgs.push({
          type: 'timeCountMismatch',
          title: 'Timecoding inaccurate',
          level: 'warn',
          details: `${lineCount} lines in scene, ${scene.audio.times.length - 1} lines timecoded`
        })
      }

      if (scene.audio.times[0] < 0.01) {
        msgs.push({
          type: 'timeStartZero',
          title: 'Initial timecode is 0',
          level: 'warn'
        })
      }

      scene.audio.times.forEach((time, i) => {
        if (time < (scene.audio.times[i - 1] || 0)) {
          msgs.push({
            type: 'timeOrder',
            title: 'Timecode out of order',
            level: 'warn',
            quote: `${i - 1}: ${scene.audio.times[i - 1]}\n${i}: ${time}`
          })
        }
      })
    } else {
      if (lineCount > 0) {
        msgs.push({
          type: 'timesMissing',
          title: 'Needs timecoding',
          level: 'todo'
        })
      }
    }

    if (chars && !scene.chars && lineCount > 1) {
      msgs.push({
        type: 'charsMissing',
        title: 'Needs char list',
        level: 'todo'
      })
    }

    if (scene.chars) {
      scene.chars.forEach(charID => {
        if (!chars[charID]) {
          msgs.push({
            type: 'charNotFound',
            title: 'Character ID not found',
            level: 'warn',
            quote: charID,
            quoteType: 'id'
          })
        }
      })
    }
  }

  msgs.forEach(msg => {
    // msg.id = Math.random().toString(36)
    msg.id = [msg.block, msg.type, msg.quote].join('')
  })

  let out = msgs
  if (scene.preflightIgnores) {
    const ignores = Object.values(scene.preflightIgnores)
    console.log(ignores)
    console.log(msgs.map(m => JSON.stringify(m)))
    out = msgs.filter(msg => !ignores.includes(JSON.stringify(msg)))
  }

  out.sort((a, b) => {
    if (a.level === 'fatal') return -1
    return (a.block || -1) - (b.block || -1)
  })

  return out
}

export function preflightShow (show) {
  const msgs = []

  if (!show.chars) {
    msgs.push({
      type: 'charsMissing',
      title: 'Needs char list',
      level: 'todo'
    })
  }

  if (!show.summary) {
    msgs.push({
      type: 'summaryMissing',
      title: 'Needs show summary',
      level: 'todo'
    })
  }

  return msgs
}
