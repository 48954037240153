import React from 'react'
import { Link } from 'react-router-dom'
import backIcon from '../assets/icon-back.svg'
import '../styles/ShowNav.css'

export default function ShowNav ({ sceneList, sceneID, setSceneID, showID, preview, recording, alwaysShow, ...props }) {
  return (
    <nav className={'show-nav d-flex align-items-center d-md-none' + (!alwaysShow ? ' seen' : '') + (recording ? ' recording' : '')}>
      <Link to={`${preview ? '/preview' : ''}/${showID}`} className='text-white mr-3 text-decoration-none'>
        <img src={backIcon} alt='back' />
      </Link>
      <select className='custom-select custom-select-sm' value={sceneID} onChange={e => setSceneID(e.target.value)}>
        {sceneList.map(scene => <option key={scene.id} value={scene.id}>{scene.title}</option>)}
      </select>
    </nav>
  )
}
