import React, { useEffect, useState } from 'react'
import { Row, Col } from './layout'
import Button from './Button'
import Input from './Input'
import ViewTrack from './ViewTrack'
import db from '../utils/db'
import shows from '../data/shows'

export default function AccountProfile ({ user }) {
  const [editingProfile, setEditingProfile] = useState(false)
  const [userName, setUserName] = useState(user.displayName)

  useEffect(() => {
    setUserName(user.displayName)
  }, [user.displayName])

  const [userHistory, setUserHistory] = useState({})
  useEffect(() => {
    if (!user) return
    return db.subscribe('AccountProfile_history', `/users/${user.uid}/history`, setUserHistory)
  }, [user])

  if (!user || user.fake) {
    return (
      <div className='container-fluid'>
        <Row>
          <Col md={6}>
            <div className='card position-relative'>
              <div className='card-body d-flex align-items-start'>
                <img alt='avatar' src='/assets/avatar-default.png' className='rounded-circle mr-3' width='72' height='72' />
                <div className='pt-2'>
                  <p className='m-0 font-weight-bold h5'>————</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  if (user && user.displayName && user.displayName.includes('Test!')) {
    return (
      <div className='container-fluid'>
        <Row>
          <Col md={6} />

          <Col md={6}>
            <div
              className='d-flex align-items-center mb-2 p-2 pr-3'
              style={{ background: 'rgb(211, 242, 218)', borderLeft: '.5em solid var(--success)', borderRadius: '.25em' }}
            >
              <div className='d-flex flex-column ml-1'>
                <span className='font-weight-bold'>From Structure to Style</span>
                <span className='small font-italic'>Romeo & Juliet</span>
              </div>
              <div className='d-flex flex-column text-right ml-auto'>
                <span className='text-monospace text-success'>E621AME</span>
              </div>
            </div>
            <div
              className='d-flex align-items-center mb-2 p-2 pr-3'
              style={{ background: 'rgb(211, 242, 218)', borderLeft: '.5em solid var(--success)', borderRadius: '.25em' }}
            >
              <div className='d-flex flex-column ml-1'>
                <span className='font-weight-bold'>Shakespeare Summer Camp</span>
                <span className='small font-italic'>A Midsummer Night’s Dream</span>
              </div>
              <div className='d-flex flex-column text-right ml-auto'>
                <span className='text-monospace text-success'>SRQ24NB</span>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className='container-fluid'>
      <h3 className='font-serif mb-3'>My Account</h3>

      <Row className='mb-5'>
        <Col md={6}>
          <div className='card position-relative'>
            {editingProfile
              ? (
                <div className='card-body d-flex flex-column'>
                  <div className='form-group'>
                    <label for='display-name'>Display Name</label>
                    <Input id='display-name' placeholder='Display Name' value={userName} onChange={setUserName} />
                  </div>
                  <div className='d-flex mt-3'>
                    <Button
                      color='secondary'
                      className='w-50'
                      onClick={() => {
                        setEditingProfile(false)
                        setUserName(user.displayName)
                      }}
                    >Cancel
                    </Button>
                    <Button
                      color='info'
                      className='w-50 ml-3'
                      onClick={() => {
                        user.updateProfile({
                          displayName: userName
                        }).then(() => {
                          setEditingProfile(false)
                          db.database.ref(`/users/${user.uid}/name`).set(userName)
                        }).catch(err => {
                          console.error(err)
                          setEditingProfile(false)
                        })
                      }}
                    >Save
                    </Button>
                  </div>
                </div>
              )
              : (
                <div className='card-body d-flex align-items-start'>
                  <img alt='avatar' src={user.photoURL || '/assets/avatar-default.png'} className='rounded-circle mr-3' width='72' height='72' />
                  <div className='pt-2'>
                    <p className='m-0 font-weight-bold h5' title={user.uid}>{user.displayName || 'Anonymous User'}</p>
                    <p className='m-0'>{user.email}</p>
                    <Button
                      size='sm'
                      color='link'
                      className='text-info p-0'
                      onClick={() => setEditingProfile(true)}
                    >Edit my profile
                    </Button>
                  </div>
                </div>
              )}
          </div>
        </Col>
      </Row>

      {Object.values(shows).filter(show => show.live).map(show => (
        <Row key={show.id} className='mt-4'>
          <ViewTrack show={show} userHistory={userHistory || {}} />
        </Row>
      ))}
    </div>
  )
}
