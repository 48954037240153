import React from 'react'
import Content from './Content'
import { Col } from './layout'
import '../styles/StaticScene.css'

const backCompat = {
  MidsummerIntro: 'intro',
  MidsummerOutro: 'credits',
  HamletIntro: 'intro',
  HamletOutro: 'credits',
  TandcIntro: 'intro',
  TandcOutro: 'credits',
  Blank: 'blank'
}

export default function StaticScene ({ col, showID, scene }) {
  const pageID = 'static_' + (backCompat[scene] || scene)
  return (
    <Col {...col} className='script static-scene mx-auto'>
      <Content showID={showID} pageID={pageID} />
    </Col>
  )
}
