import React from 'react'

export default function Content () {
  return (
    <>
      <style>
        {`.cast-list {
            font-size: 1.1em;
          }
          .cast-list li {
            margin-bottom: .5em;
          }
          .bio-link {
            color: inherit;
            text-decoration: underline;
          }`}
      </style>
      <div class='text-center'>
        <h2>The SYT Season 16 & 17 Core Ensemble</h2>
        <ul class='list-unstyled cast-list'>
          <li><b>Lucas Bueling</b> – Bernardo</li>
          <li><b>Vivian Chaffin</b> – Ophelia</li>
          <li><b>Grace Chamberlain</b> – Sailor/Priest/Player (Queen)</li>
          <li><b>Ulla Collins</b> – Queen Gertrude</li>
          <li><b>Pip Edmund</b> – Laertes</li>
          <li><b>Basie Geary</b> – Guildenstern</li>
          <li><b>Marcus Geary</b> – Marcellus/Player (Lucianus)</li>
          <li><b>Garrett Haynes</b> – Second Gravedigger (Clown)</li>
          <li><b>Tydus Kelcher</b> – Polonius</li>
          <li><b>Ilse Prokosch</b> – Rosencrantz</li>
          <li><b>Abraham Riker</b> – Hamlet</li>
          <li><b>Greyson Shaw</b> – King Claudius</li>
          <li><b>Søren Stauffacher</b> – Osric</li>
          <li><b>Christopher Tupper</b> – Gravedigger</li>
          <li><b>Ashlyn Walters</b> – Horatio</li>
          <li><b>Blake Walters</b> – Ghost/Player (King)</li>
        </ul>
        <h4>Creative Team</h4>
        <ul class='list-unstyled cast-list'>
          <li>Director: <b>Logan Verdoorn</b></li>
          <li>Web Design/Marketing: <b>Josh Dudley</b></li>
          <li>Lighting Design: <b>Søren Olsen</b></li>
          <li>Costume Design: <b>Ash M. Kaun</b></li>
          <li>Dramaturg: <b>Lukas Brasherfons</b></li>
          <li>Set Construction: <b>Alex Olsen</b></li>
          <li>Sound Design: <b>Kevin Springer</b></li>
          <li>Stage Management: <b>Ezra Jackson-Smith,<br />Gabriel Peñaloza-Hernandez, Maria Signorelli</b></li>
          <li>Fight Choreography: <b>Annie Enneking</b></li>
        </ul>
      </div>

      <h3>Meet the Cast</h3>
      <p><b>Lucas Bueling (Bernardo)</b>, 14, is definitely enjoying his second chaotic year in SYT. Previous acting experiences include Pantino/Outlaw in SYT's Summer Ensemble production The Two Gentlemen of Verona, as well as a year in SYT's Tech Track and 5 musicals. Lucas enjoys playing video games, hanging out with friends and family, traveling, and of course doing Shakespearean Youth Theatre. He hopes you enjoy Hamlet.</p>
      <p><b>Vivian Chaffin (Ophelia)</b>, 16, is thrilled to be in her first SYT production. Previous acting experience includes musical theatre and various workshops with SYT. She wants to thank her friends and family for their endless support, and hopes you enjoy the show.</p>
      <p><b>Grace Chamberlain (Player Queen/Priest/Sailor)</b>, 13, is in her first year with SYT. It has been an amazing experience to gain a deeper knowledge of Shakespeare, and identify ways to stretch herself and translate this incredible story into modern day version. She absolutely loves the stage and theater, she finds joy in partnering with her director and cast to bring this story to life. She would like to thank her new friends in this great cast, director and all those that help support the cast and crew. What a journey!</p>
      <p><b>Ulla Collins (Gertrude)</b>, 18, is very much enjoying her second SYT show. When not at a rehearsal, she's probably hanging out with her dog. Upcoming shows include The Tempest with Classical Actors Ensemble, coming to a park near you this summer. She hopes you enjoy this weird and wonderful production of Hamlet.</p>
      <p><b>Pip Edmund (Laertes)</b>, 15, is enjoying their second production at SYT. He has had a wonderful year with the directors and almost the entire cast of last year's The Two Gentlemen of Verona. She is also a proud member of The Garbage Club. They would like to thank the amazing cast, designers, and everyone at SYT for an unforgettable year.</p>
      <p><b>Basie Geary (Guildenstern)</b>, 16, is in her third year at SYT and is a sophomore at Edvisons Off Campus. She is very excited to be playing Guildenstern, who is not Rosencrantz. She would describe her personality as eyeliner, Dr. Martens and a concerning amount of guitar playing.</p>
      <p><b>Marcus Geary (Marcellus)</b>, 16, is a sophomore is in his third year of SYT. He has many strange interests, including Dr. Seuss's The Lorax (1972) television special and Toucan Sam. He spends his time creating art projects or playing a Shakespeare character that has an eerily similar name to his own.</p>
      <p><b>Tydus Kelcher (Polonius)</b>, 16, is positively thrilled to be playing Polonius (the humorous and slightly-long-winded-of-speech advisor to the King, and father to Laertes and Ophelia) in his fourth production with SYT. Previously, he played Pirithous in The Two Noble Kinsmen, and Sir Andrew Aguecheek in Twelfth Night, among others. In his free time, he enjoys embroidering, drawing, and birdwatching.</p>
      <p><b>Ilse Prokosch (Rosencrantz)</b>, 17, is eager to take part in (arguably) Shakespeare's most famous play. This will be her fourth play and third year in SYT, which has been one of the best experiences of her thespian life. Aside from performing stories, Ilse also enjoys writing stories, drawing, playing games, and pestering her cats for cuddles. She hopes you enjoy the show!</p>
      <p><b>Abraham Riker (Hamlet)</b>, 18, is glad to be performing again with SYT. He first acted in Planet Homeschool's production of Witness for the Prosecution, and SYT's productions of The Two Noble Kinsmen and The Two Gentlemen of Verona. He has two cats and thanks you for coming to the show.</p>
      <p><b>Greyson Shaw (King Claudius)</b>, 18, is incredibly excited to welcome all to SYT's Hamlet. A tale of madness, sadness and general badness. It does however mark Greyson's final production with the company, as he moves on to study bicycle design and manufacturing. But that's all one, our play is begun.</p>
      <p><b>Søren Stauffacher (Osric)</b>, 12, was in the Tech Track last year for The Two Noble Kinsmen. This is her first acting production, and she is excited to portray her character! She has always liked theater since she was a little kid. She is shy and hopes to do well!</p>
      <p><b>Christopher Tupper (Graveyard Keeper)</b>, 17, is in his first performance in a while. He is playing the Grave Digger, which he wants to call Graveyard Keeper. He likes peanut butter, video games, books, cats, koalas, drawing, making music, giant anteaters, and foxes. And the show will be amazing, if he just keeps saying so.</p>
      <p><b>Ashlyn Walters (Horatio)</b>, 17, is in her sixth show with SYT, and she has yet to achieve her goal of dying on stage. Ashlyn spends her time outside of SYT doing PSEO at Anoka Ramsey Community College, playing with her 4-year-old cousins, driving her friends around, and teaching herself new skills.</p>
      <p><b>Blake Walters (Ghost/Player King)</b>, 18, is back for his fifth year at SYT and sixth show. Previous roles at SYT include Palamon in The Two Noble Kinsmen and Proteus in The Two Gentlemen of Verona. When not at SYT, Blake spends his time taking PSEO classes at Anoka-Ramsey Community College and volunteering at the Raptor Center. He hopes you enjoy the show!</p>
      <h4>Tech Track Design Assistants</h4>
      <p><b>Leo Buffalo (Sound)</b>, 13, is the loneliest lemon. He's been dragged from his drawing pad and headphones to give you the sound.</p>
      <p><b>Robin James (Costumes)</b>, 18, is the costume apprentice and they're having a wonderful time working with their mentor, Ash, and the rest of SYT. This is their second time being involved in a youth production of Hamlet, their first was in 2017 with the Great River Shakespeare Festival's Shakespeare for Young Actors program in Winona, MN.</p>
      <p><b>Nate Kampling (Sound)</b>, 16, was born in Des Moines, Iowa. His first experience with being in a play was in a Shakespeare reenactment of the second half of the 5th episode of Star Wars. He joined the SYT Core Ensemble Tech Track in 2018--2019. His hobbies include: Making and listening to music, playing video games, playing D&D, and sleeping, lots and lots of sleeping.</p>
      <p><b>Vladyslav Khryashchevskyy (Lighting)</b>, 16, is having a lot of fun designing the lights for the show. He likes to share his ideas with his mentor and follow his directions. He has never had an experience like that and unfortunately, it is Vlad's the only year at SYT. Vlad likes different sports and collecting shoes. He hopes that you will get a lot of positive emotions watching the play.</p>
      <p><b>Michael Oaks (Lighting)</b>, 16, is in his first year at SYT. He enjoys hiking, baking cookies and playing piano. Among his family members, there are two cats: Pepper, and recently acquired, Floof. (Yes, that is her name.) He hopes that everyone will have a great time watching Hamlet, and will now end his bio.</p>
      <p><b>Ashley Odett (Lighting)</b>, 17, hopes you will find Hamlet very illuminating. Previous theater experience includes: Musical Theater camp at Chanhassen Dinner Theatres, Improv Sketch Comedy work at Lyric Arts, in Anoka, MN, and ongoing voice training through Minnesota School of Music. Ashley adores her two pet birds Blu, and Meiko, and also loves corgis. In her free time Ashley can be found singing, gaming, or playing card games.</p>
      <h4>Creative Team</h4>
      <p><b>Lukas Brasherfons (Resident Dramaturg)</b> has directed The Two Gentlemen of Verona, and will direct the upcoming A Midsummer Night's Dream. He has a BFA in Acting with a minor in Stage Combat from Columbia College Chicago and an MFA in Dramaturgy from the Iowa Playwrights Workshop.</p>
      <p><b>Josh Dudley (Graphic Designer, Web Designer)</b> designs, develops and provides nerd services for theatre and more in the Twin Cities and beyond. See more of his work by checking out this website, the Hamlet poster, or <a href='https://joshdudley.net' target='_blank' rel='noopener noreferrer' class='bio-link'>joshdudley.net</a>.</p>
      <p><b>Annie Enneking (Fight Choreographer)</b> is a Minneapolis based singer-songwriter, actor, dancer, fight director, and teaching artist. She is an Associate Instructor with Dueling Arts International, and stages violence for stages around the Twin Cities including the Guthrie/BFA program, Children's Theatre Company, Jungle Theatre, Park Square Theatre, and Theatre Latte Da.</p>
      <p><b>Ezra Jackson-Smith (Stage Manager)</b> is a theater artist and educator new to the Twin Cities after a BA in Theater and Performance Studies from Stanford University. He also works tutoring college prep and writes plays.</p>
      <p><b>Ash Kaun (Costume Designer)</b> is a freelance costume designer and technician based in the Twin Cities. She holds a B.A. in Theatrical Direction and Design from Augsburg University.</p>
      <p><b>Alex Olsen (Technical Director)</b> is the full-time technical director at Park Square Theater. This is his third season with SYT. Alex is a longtime advocate for knowledge and growth in youth theater and he hopes you enjoy the show.</p>
      <p><b>Søren Olsen (Lighting Designer)</b> is a Twin Cities based theatre designer, creator, and performer. You can see more of his work at <a href='https://designsbysoren.com' target='_blank' rel='noopener noreferrer' class='bio-link'>designsbysoren.com</a>.</p>
      <p><b>Gabriel Peñaloza-Hernandez (Assistant Stage Manager)</b> graduated from Stephen F. Austin down in good ol' Texas. After graduation, he decided to move to Minnesota and has been venturing out to different theater companies around the Twin Cities!</p>
      <p><b>Kevin Springer (Sound Designer)</b> is a sound designer and educator whose work has been heard with companies throughout the Twin Cities including Heart of the Beast Puppet and Mask Theatre, Minnesota Opera, Penumbra Theatre, Theatre Latté Da, and Theater Mu.</p>
      <p><b>Logan Verdoorn (Director, Set/Projection Designer)</b> is a Twin Cities based theatre artist and the Artistic Director of SYT. Logan has worked nationally and internationally as a director, actor and educator, with a focus on Shakespeare in translation, cross-cultural collaboration, physical theater, and devised work. Logan is a graduate of NYU's Tisch School of the Arts.</p>
    </>
  )
}
