import React from 'react'

export default function EditorData ({ data, scene, hotCode, setHotCode }) {
  let val = ''
  if (hotCode) {
    if (typeof hotCode === 'object') {
      val = JSON.stringify(hotCode, null, 2)
    } else {
      val = hotCode
    }
  } else {
    val = JSON.stringify(scene, null, 2)
  }
  return (
    <>
      <span className='text-danger font-italic small'>For advanced debugging only; data structure will not be verified before saving.</span>
      <span className='pl-3 text-muted small'>Load DB key:</span>
      {Object.keys(data).map(key => (
        <span
          key={key}
          role='button'
          className='text-primary small pl-2'
          onClick={() => setHotCode('CUSTOM VIEW\n\n' + JSON.stringify(data[key], null, 2))}
        >{key} ({Math.round(JSON.stringify(data[key]).length / 10) / 100} kB)
        </span>
      ))}
      <span
        className='pl-3 text-info small'
        role='button'
        onClick={() => {
          const notes = []
          Object.values(data.scenes).forEach(scene => {
            scene.blocks.forEach(block => {
              if (block.notes) {
                block.notes.forEach(note => {
                  notes.push({
                    ...note,
                    scene: scene.id
                  })
                })
              }
            })
          })
          notes.sort((a, b) => b.body.length - a.body.length)
          setHotCode(notes.map(note => `${note.body.length} ${note.scene}  ${note.title}:    ${note.body.slice(0, 48)}`).join('\n'))
        }}
      >Load note lengths
      </span>
      <textarea
        className='text-monospace small'
        style={{
          width: '100%',
          minHeight: '80vh'
        }}
        value={val}
        onChange={event => setHotCode(event.target.value)}
      />
    </>
  )
}
