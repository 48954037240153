import HamletCast from './hamlet/cast'
import TandcCast from './tandc/cast'
import WinterstaleCast from './winterstale/cast'
import RandjCast from './randj/cast'

export default {
  hamlet: {
    cast: HamletCast
  },
  tandc: {
    cast: TandcCast
  },
  winterstale: {
    cast: WinterstaleCast
  },
  randj: {
    cast: RandjCast
  }
}
