import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import '../styles/Catalog.css'

export default function Catalog ({ links }) {
  const location = useLocation()

  return (
    <ul className='catalog'>
      {links.map((link, i) => {
        if (link.divider) {
          return (
            <li key={'div' + i} className={'catalogDivider' + ((link.active || location.pathname === link.to) ? ' active' : '')}>
              <div className='catalogPill' />
              <div className='catalogDividerLine' />
            </li>
          )
        }
        return (
          <li key={link.to} className={'catalogItem' + ((link.active || location.pathname === link.to) ? ' active' : '')}>
            <div className='catalogPill' />
            <Link to={link.to} className='catalogLink'>
              {link.title}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
