import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from '../components/layout'
import Navbar from '../components/Navbar'
import db from '../utils/db'

export default function AnalyticsUsers ({ match }) {
  const userID = match.params.user || null

  const [users, setUsers] = useState({})
  useEffect(() => {
    return db.subscribe('AnalyticsUsers_users', '/analytics/users', data => {
      Object.keys(data).forEach(key => {
        data[key].lastVisitDate = new Date(data[key].lastVisit).toLocaleString()
      })
      setUsers(data)
    })
  }, [setUsers])

  const userList = Object.keys(users).sort((a, b) => {
    if (!users[a].lastVisit || !users[b].lastVisit) return 0
    return users[b].lastVisit.localeCompare(users[a].lastVisit)
  })

  let userData = {}
  if (userID && users[userID]) {
    const { nickname, pageviews, scenes, ...summary } = users[userID]
    userData = { nickname, pageviews, scenes, summary }
  }

  return (
    <>
      <Navbar back='/backstage/analytics' title='User Analytics' />
      <div className='container'>
        <Row>

          <Col width={4}>
            <ul className='list-group'>
              {userList.map(id => (
                <Link
                  key={id}
                  to={'/backstage/analytics/users/' + id}
                  role='button'
                  className={'list-group-item list-group-item-action' + (id === userID ? ' active' : '')}
                >
                  {id}{users[id].nickname && ` (${users[id].nickname})`}{users[id].lastRole && ` [${users[id].lastRole}]`}
                  {users[id].presence
                    ? <span className='d-block text-primary'>{users[id].presence}</span>
                    : <span className='d-block text-muted'>{users[id].lastVisitDate}</span>}

                </Link>
              ))}
            </ul>
          </Col>

          {userID && users[userID] &&
            <Col width={8}>
              <input
                type='text'
                class='form-control w-50 mb-2'
                placeholder='Nickname'
                value={userData.nickname}
                onChange={event => db.ref(`/analytics/users/${userID}/nickname`).set(event.target.value)}
              />
              <pre>{JSON.stringify(userData.summary, null, 2)}</pre>
              <pre className='small'>{JSON.stringify(userData.scenes, null, 2)}</pre>
              <pre className='small'>{Object.values(userData.pageviews).reverse().join('\n')}</pre>
            </Col>}

        </Row>
      </div>
    </>
  )
}
