import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Button from './Button'
import { Col } from './layout'
import auth from '../utils/auth'
import db from '../utils/db'
import sceneTitle from '../utils/sceneTitle'
import charsIcon from '../assets/icon-chars.svg'
// import helpIcon from '../assets/icon-help.svg'
import settingsIcon from '../assets/icon-settings.svg'
import summaryIcon from '../assets/icon-summary.svg'
import '../styles/ShowBar.css'

const titleHideRegion = 120

const expandOptions = [
  { value: 'never', title: 'Never' },
  { value: 'auto', title: 'Auto' },
  { value: 'always', title: 'Always' }
]

const buttons = [
  // { id: 'help', title: 'Tips', img: helpIcon },
  { when: scene => scene.chars, id: 'chars', title: 'Characters', img: charsIcon },
  { when: scene => scene.summary, id: 'summary', title: 'Scene Synopsis', img: summaryIcon },
  { when: scene => !scene.static, id: 'settings', title: 'Note settings', img: settingsIcon }
]

const Panel = ({ show, children, ...props }) => (
  <div className={'showbar-panel' + (show ? ' shown' : '')} {...props}>
    {children}
  </div>
)

export default function ShowBar ({ col, preview, showID, scene, track, noteSettings, setNoteSettings }) {
  const [showChars, setShowChars] = useState(null)
  useEffect(() => {
    return db.subscribe('ShowBar_showChars', `/${preview ? 'staging' : 'live'}/shows/${showID}/chars`, setShowChars)
  }, [preview, showID, setShowChars])

  const [hideTitle, setHideTitle] = useState(window.scrollY < titleHideRegion)
  useEffect(() => {
    const handleScroll = () => {
      if (hideTitle) {
        if (window.scrollY > titleHideRegion) setHideTitle(false)
      } else {
        if (window.scrollY < titleHideRegion) setHideTitle(true)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [hideTitle])

  const [progress, setProgress] = useState(0)
  useEffect(() => {
    if (!track.audio) return

    const handleTimeUpdate = () => {
      setProgress(track.percent)
    }

    track.audio.addEventListener('timeupdate', handleTimeUpdate)
    return () => {
      track.audio.removeEventListener('timeupdate', handleTimeUpdate)
    }
  }, [track])
  const playbackStyle = { width: progress * 100 + '%' }

  const [showPanel, setShowPanel] = useState(null)

  const routeParams = useParams()

  return (
    <>
      <Col {...col} className='showbar'>
        <div className={'showbar-center' + (hideTitle ? ' title-hide' : '')}>
          <span className='showbar-title'>{sceneTitle(scene.id)}</span>
          <div className='playback-track'>
            <div className='playback-fill' style={playbackStyle} />
          </div>
        </div>
        <div className={'showbar-right' + (noteSettings.recording ? ' recording' : '')}>
          {noteSettings.recording
            ? <span className='mode-indicator'>RECORDING</span>
            : (preview && <span className='mode-indicator'>PREVIEW</span>)}
          {buttons.filter(btn => !btn.when || btn.when(scene)).map(btn => (
            <div
              key={btn.id}
              role='button'
              className={'showbar-button' + (showPanel === btn.id ? ' active' : '')}
              onClick={() => setShowPanel(showPanel === btn.id ? null : btn.id)}
            >
              <img src={btn.img} alt={btn.title} height='30' width='30' />
            </div>
          ))}
        </div>
      </Col>

      <Panel show={showPanel === 'settings'} style={{ width: '17em' }}>
        <div className='d-flex flex-column'>
          {auth.hasRights('preview') &&
            <>
              <div className='custom-control custom-switch'>
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='check-recording'
                  checked={noteSettings.recording}
                  onChange={e => setNoteSettings({ ...noteSettings, recording: e.target.checked })}
                />
                <label className='custom-control-label' htmlFor='check-recording'>Recording mode</label>
              </div>
              <Link to={`/backstage/${routeParams.show}/${routeParams.scene}/notes`} className='text-center'>Open backstage</Link>
              <hr className='mx-3 my-2' style={{ borderTop: '1px solid #ddd' }} />
            </>}
          <div className='custom-control custom-switch'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='check-autoScroll'
              checked={noteSettings.autoScroll}
              onChange={e => setNoteSettings({ ...noteSettings, autoScroll: e.target.checked })}
            />
            <label className='custom-control-label' htmlFor='check-autoScroll'>Autoscroll with audio</label>
          </div>
          <div className='custom-control custom-switch mb-2 mb-xl-3'>
            <input
              type='checkbox'
              className='custom-control-input'
              id='check-autoNextScene'
              checked={noteSettings.autoNextScene}
              onChange={e => setNoteSettings({ ...noteSettings, autoNextScene: e.target.checked })}
            />
            <label className='custom-control-label' htmlFor='check-autoNextScene'>Autoplay next scene</label>
          </div>
          <div style={{ whiteSpace: 'nowrap' }}>Auto-expand notes</div>
          <div className='mt-1 btn-group btn-group-sm'>
            {expandOptions.map(option => (
              <Button
                key={option.value}
                color='secondary'
                outline={option.value !== noteSettings.expand}
                onClick={() => {
                  setNoteSettings({ ...noteSettings, expand: option.value })
                }}
              >
                {option.title}
              </Button>
            ))}
          </div>
        </div>
        {noteSettings.expand !== 'never' &&
          <div className='mt-2 d-flex flex-wrap align-items-baseline'>
            <div>Fewer</div>
            <div className='ml-auto'>More</div>
            <input
              type='range'
              className='custom-range w-100'
              id='importance'
              min='0'
              max='2'
              value={noteSettings.importance}
              onChange={event => setNoteSettings({ ...noteSettings, importance: event.target.value })}
            />
          </div>}
        <p className='mt-3 mb-0'>
          {noteSettings.expand === 'always' && noteSettings.importance === '2'
            ? 'All notes will be shown in the sidebar below.'
            : <>Click <span className='demo-note-extra'>underlined phrases</span> to see {noteSettings.expand === 'always' && 'even more'} <span className='demo-color define'>definitions</span> and <span className='demo-color dramaturg'>insights</span>.</>}
        </p>
        {noteSettings.expand === 'auto' &&
          <p className='mt-1 mb-0'>
            <span className='demo-note-auto'>Solid underlined</span> notes will appear automatically as you listen.
          </p>}
      </Panel>

      <Panel show={showPanel === 'chars'} style={{ maxWidth: '20em' }}>
        <h6>In this scene:</h6>
        <dl>
          {showChars && scene.chars && scene.chars.map(charID => {
            const char = showChars[charID]
            if (!char) return null
            return (
              <React.Fragment key={charID}>
                <dt className={'char-' + char.color}>{char.name}</dt>
                <dd>{char.desc}</dd>
              </React.Fragment>
            )
          })}
        </dl>
      </Panel>

      <Panel show={showPanel === 'summary'} style={{ maxWidth: '30em' }}>
        <h6>Scene Synopsis</h6>
        <p>{scene.summary}</p>
      </Panel>
    </>
  )
}
