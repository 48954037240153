export default {
  freeform: function (str) {
    const blocks = []
    let block = null
    str.split('\n').map(s => s.trim()).forEach(ln => {
      if (block) {
        if (ln === '') {
          blocks.push(block)
          block = null
        } else if (ln[0] === '>') {
          block.lines.push({ dir: ln.slice(1).trim() || '—' })
        } else {
          block.lines.push(ln)
        }
      } else {
        if (ln === '') {
          // skip
        } else if (ln[0] === '>') {
          blocks.push({ dir: ln.slice(1).trim() || '—' })
        } else {
          block = { voice: ln, lines: [] }
        }
      }
    })
    return blocks
  },

  freeformStringify: function (blocks) {
    return blocks.map(block => {
      if (block.dir) {
        return '>' + block.dir
      } else {
        return [block.voice, ...block.lines.map(ln => {
          if (ln.dir) return '>' + ln.dir
          return ln
        })].join('\n')
      }
    }).join('\n\n')
  },

  v1: function (str) {
    const lns = str.split('\n').map(s => s.trim()).reverse()

    const blocks = []
    const voiceRegex = /^(@.*|\w*\.)$/
    const dirRegex = /^(>|Enter|Exit|Exeunt).*/
    while (lns.length > 0) {
      const ln = lns.pop()
      if (ln === '') {
        continue
      }
      if (dirRegex.test(ln)) {
        blocks.push({ dir: ln.replace('>', '').replace(/\.$/, '') || '—' })
        continue
      }
      const block = { voice: ln.replace(/\.$/, '').replace('@', ''), lines: [] }
      while (lns.length > 0) {
        const next = lns.pop()
        if (next === '') {
          break
        }
        if (dirRegex.test(next)) {
          if (lns.length === 0 || voiceRegex.test(lns[lns.length - 1])) {
            lns.push(next)
            break
          }
          block.lines.push({ dir: next.replace('>', '').replace(/\.$/, '') || '—' })
          continue
        }
        if (voiceRegex.test(next)) {
          lns.push(next)
          break
        }
        block.lines.push(next.replace('#', ''))
      }
      blocks.push(block)
    }

    return blocks
  },

  v2: function (str) {
    const lns = str.split('\n').map(s => s.trim()).reverse()

    const blocks = []
    const voiceRegex = /^(@.*|\w*)$/
    const dirRegex = /^(>|Enter|Exit|Exeunt).*/
    while (lns.length > 0) {
      const ln = lns.pop()
      if (ln === '') {
        continue
      }
      if (dirRegex.test(ln)) {
        blocks.push({ dir: ln.replace('>', '').replace(/\.$/, '') || '—' })
        continue
      }
      const block = { voice: ln.replace(/\.$/, '').replace('@', ''), lines: [] }
      while (lns.length > 0) {
        const next = lns.pop()
        if (next === '') {
          break
        }
        if (dirRegex.test(next)) {
          if (lns.length === 0 || voiceRegex.test(lns[lns.length - 1])) {
            lns.push(next)
            break
          }
          block.lines.push({ dir: next.replace('>', '').replace(/\.$/, '') || '—' })
          continue
        }
        if (voiceRegex.test(next)) {
          lns.push(next)
          break
        }
        block.lines.push(next.replace('#', ''))
      }
      blocks.push(block)
    }

    return blocks
  },

  v3: function (str) {
    const lns = str.split('\n').map(s => s.trim()).reverse()

    const blocks = []
    const voiceRegex = /^(@.*|\w*\.?( \[.*\])?)$/
    const dirRegex = /^(>|<|Enter|Exit|Exeunt).*/
    while (lns.length > 0) {
      const ln = lns.pop()
      if (ln === '') {
        continue
      }
      if (dirRegex.test(ln)) {
        blocks.push({ dir: ln.replaceAll(/[<>]/g, '').replace(/\.$/, '') || '—' })
        continue
      }
      const block = { voice: ln.replace(/\.$/, '').replace('@', ''), lines: [] }
      while (lns.length > 0) {
        const next = lns.pop()
        if (next === '') {
          break
        }
        if (dirRegex.test(next)) {
          if (lns.length === 0 || voiceRegex.test(lns[lns.length - 1])) {
            lns.push(next)
            break
          }
          block.lines.push({ dir: next.replaceAll(/[<>]/g, '').replace(/\.$/, '') || '—' })
          continue
        }
        if (voiceRegex.test(next)) {
          lns.push(next)
          break
        }
        block.lines.push(next.replace('#', ''))
      }
      blocks.push(block)
    }

    return blocks
  }
}
