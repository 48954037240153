import React from 'react'
import { Link, Redirect } from 'react-router-dom'
import Catalog from '../components/Catalog'
import Content from '../components/Content'
import { Row, Col } from '../components/layout'
import NavbarPublic from '../components/NavbarPublic'
import PageFooter from '../components/PageFooter'
import PageShowOverview from '../components/PageShowOverview'
import shows from '../data/shows'
import auth from '../utils/auth'
import '../styles/Show.css'
import urls from '../data/urls'

export default function Page ({ match, location }) {
  const showID = match.params.show
  const pageID = match.params.page || 'index'
  const preview = match.path.includes('preview')
  const localShow = shows[showID]

  if (!localShow) {
    if (!auth.hasRights('editor')) return <Redirect to='/' />
    return (
      <>
        <NavbarPublic />
        <div className='container my-3 my-lg-5' style={{ minHeight: '50vh' }}>
          <div className='alert alert-danger pt-4'>
            <h2>Unknown Show</h2>
            <p>No show exists with id <span className='text-monospace font-weight-bold'>{showID}</span>. Double check your link, or return to the <Link to='/'>Dream Project homepage</Link>.</p>
          </div>
          <p className='small text-muted mx-3'>This page is only visible to signed-in editors. Visitors will be redirected to the homepage.</p>
        </div>
        <PageFooter location={location} />
      </>
    )
  }

  if (!localShow.live && !localShow.staging) {
    if (!auth.hasRights('editor')) return <Redirect to='/' />
    return (
      <>
        <NavbarPublic />
        <div className='container my-3 my-lg-5' style={{ minHeight: '50vh' }}>
          <div className='alert alert-danger pt-4'>
            <h2>No Show Data</h2>
            <p><i>{localShow.title}</i> has not been fully initialized in the database, and cannot be viewed or edited yet.</p>
          </div>
          <p className='small text-muted mx-3'>This page is only visible to signed-in editors. Visitors will be redirected to the homepage.</p>
        </div>
        <PageFooter location={location} />
      </>
    )
  }

  if (!localShow.live && !preview) {
    if (!auth.hasRights('preview')) return <Redirect to='/' />
    return (
      <>
        <NavbarPublic />
        <div className='container my-3 my-lg-5' style={{ minHeight: '50vh' }}>
          <div className='alert alert-warning pt-4'>
            <h2>Unpublished Show</h2>
            <p><i>{localShow.title}</i> has not been published yet. To view this show's content, <Link to={'/preview' + location.pathname}>switch to preview mode</Link>.</p>
          </div>
          <p className='small text-muted mx-3'>This page is only visible to signed-in users with preview rights. Visitors will be redirected to the homepage.</p>
        </div>
        <PageFooter location={location} />
      </>
    )
  }

  return (
    <>
      <NavbarPublic background={`navbar-${showID}.jpg`} />
      <div className='container my-3 my-lg-5' style={{ minHeight: '50vh' }}>
        <Row>
          <Col md={3}>
            <img src={urls.asset(`logo-${showID}-nav-dark.svg`)} alt={localShow.title} className='img-fluid mb-3 mr-5 d-none d-md-block' />
            <Catalog
              links={Object.values(localShow.pages).map(page => ({
                to: `${preview ? '/preview' : ''}/${showID}/${page.id}`.replace('/index', ''),
                title: page.title,
                active: page.id === pageID
              }))}
            />
          </Col>

          <Col md={9}>
            {pageID.includes('index')
              ? <PageShowOverview localShow={localShow} preview={preview} />
              : <Content showID={showID} pageID={pageID} preview={preview} />}
          </Col>

        </Row>
      </div>
      <PageFooter />
    </>
  )
}
