const base = 'https://syt-dream-project-content.netlify.app'

function filename (name = '', exts = []) {
  if (exts.some(ext => name.endsWith(ext))) {
    return name
  }
  return name + exts[0]
}

export default {
  sceneAudio: id => base + '/scenes/' + filename(id, ['.mp3']),
  imageThumb: id => base + '/images/thumb/' + filename(id, ['.jpg', '.png']),
  imageOrig: id => base + '/images/orig/' + filename(id, ['.jpg', '.png']),
  videoThumb: id => base + '/videos/' + filename(id, ['.jpg', '.svg']),
  download: id => base + '/complete/' + filename(id, ['.mp3', '.wav']),

  asset: id => '/assets/' + filename(id, ['.jpg', '.png', '.svg']),

  donateHome: 'https://www.givemn.org/donate/Syt',
  donateCTA: 'https://www.givemn.org/donate/Syt',
  twitter: 'https://twitter.com/YouthShakes',
  facebook: 'https://www.facebook.com/ShakespeareanYouthTheatre',
  instagram: 'https://www.instagram.com/shakespeareanyouththeatre'
}
