import * as firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyC6vyyg_L8mfjpj0MUrfdd4AKdNIts4WMs',
  authDomain: 'syt-dream-project.firebaseapp.com',
  databaseURL: 'https://syt-dream-project.firebaseio.com',
  projectId: 'syt-dream-project',
  storageBucket: 'syt-dream-project.appspot.com',
  messagingSenderId: '633369356488',
  appId: '1:633369356488:web:c826d74a51dbb921c3ddfd'
}
firebase.initializeApp(firebaseConfig)

export default firebase

export const auth = firebase
