import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col } from '../components/layout'
import PlayControls from '../components/PlayControls'
import Script from '../components/Script'
import Shatter from '../components/Shatter'
import ShowBar from '../components/ShowBar'
import ShowNav from '../components/ShowNav'
import Stage from '../components/Stage'
import StaticScene from '../components/StaticScene'
import shows from '../data/shows'
import analytics from '../utils/analytics'
import db from '../utils/db'
import sceneTitle from '../utils/sceneTitle'
import scroller from '../utils/scroller'
import getTrack from '../utils/Track'
import stageEdgeImg from '../assets/stage-edge.png'
import { Redirect } from 'react-router'
import { useUser } from '../utils/auth'

export default function Player ({ match, history, location }) {
  let preview = false
  if (location.pathname.slice(0, 8) === '/preview') {
    preview = true
  }

  const [redirect, setRedirect] = useState(null)

  const showID = match.params.show
  const sceneID = `${showID}_${match.params.scene}`
  const setSceneID = useCallback(id => {
    history.push(location.pathname.replace(match.params.scene, id.split('_')[1]))
  }, [history, location, match.params.scene])

  const genericMode = false

  const user = useUser()
  const [userNotes, setUserNotes] = useState([])
  const dbUserNotesRef = user ? db.ref(`/users/${user.uid}/notes/${sceneID}`) : null
  useEffect(() => {
    if (!user) return
    const dbBaseRef = db.ref(`/users/${user.uid}/notes/${sceneID}`)
    return db.subscribe('Player_userNotes', `/users/${user.uid}/notes/${sceneID}`, data => {
      if (!data) {
        setUserNotes([])
        return
      }
      setUserNotes(Object.keys(data).map(key => ({
        ...data[key],
        dbRef: dbBaseRef.child(key)
      })))
    })
  }, [user, sceneID])

  const [noteSettings, setNoteSettings] = useState(
    JSON.parse(window.localStorage.getItem('noteSettings')) ||
    {
      importance: 1,
      expand: 'auto',
      autoNextScene: true,
      autoScroll: true
    }
  )

  useEffect(() => {
    scroller.enable = noteSettings.autoScroll
  }, [noteSettings.autoScroll])

  const [autoplay, setAutoplay] = useState(false)

  useEffect(() => {
    analytics.event.sceneStart(sceneID)
  }, [showID, sceneID])

  const [scene, setScene] = useState({
    id: sceneID,
    blocks: [],
    fake: true
  })
  useEffect(() => {
    return db.subscribe('Player_scene', `/${preview ? 'staging' : 'live'}/scenes/${sceneID}`, data => {
      if (data) {
        setScene(data)
      } else {
        const showData = shows[showID]
        if (showData && (showData.live || (showData.staging && preview))) {
          setRedirect(`${preview ? '/preview' : ''}/${showID}/play/${showData.scenes[0].split('_')[1]}`)
        } else {
          setRedirect(`${preview ? '/preview' : ''}/${showID}`)
        }
      }
    })
  }, [preview, sceneID, setScene, history, showID])

  const [sceneList, setSceneList] = useState([])
  useEffect(() => {
    const options = shows[showID].scenes.map(id => ({ id, title: sceneTitle(id) }))
    setSceneList(options)
  }, [showID])

  let track = {
    fake: true,
    onLineChange: () => { },
    play: () => { },
    pause: () => { },
    cancelLoading: () => { }
  }
  if (scene.id === sceneID) {
    track = getTrack(scene.id, scene.audio ? scene.audio.times : null)
    track.restartLoading()
    if (autoplay) {
      track.play()
      setAutoplay(false)
    }
  }

  useEffect(() => {
    if (!user) return
    return track.onLineChange(line => {
      if (line === track.lines.length - 5) {
        db.ref(`/users/${user.uid}/history`).update({
          [sceneID]: db.serverValue.increment(1)
        })
      }
    })
  }, [track, user, sceneID])

  useEffect(() => {
    if (track.fake) return
    const handleEndTrack = event => {
      if (user && scene.static) {
        db.ref(`/users/${user.uid}/history`).update({
          [sceneID]: db.serverValue.increment(1)
        })
      }
      if (!track.next || !noteSettings.autoNextScene) {
        setPlaying(false)
        return
      }
      getTrack(track.next).play()
      track.cancelLoading()
      setSceneID(track.next)
      setAutoplay(true)
      scroller.scrollNow(0)
    }
    track.audio.addEventListener('ended', handleEndTrack)
    return () => {
      track.audio.removeEventListener('ended', handleEndTrack)
    }
  }, [track, scene, sceneID, setSceneID, user, noteSettings.autoNextScene])

  const [busy, setBusy] = useState(0)
  useEffect(() => {
    console.log('BUSY:', busy)
  }, [busy])

  const [playing, setPlaying] = useState(false)
  const [ready, setReady] = useState(true)
  useEffect(() => {
    if (!track.audio) return
    const handleReady = () => setReady(true)
    track.audio.addEventListener('canplaythrough', handleReady)
    return () => {
      track.audio.removeEventListener('canplaythrough', handleReady)
    }
  }, [track, setReady])
  const pause = useCallback(() => {
    track.pause()
    setPlaying(false)
    scroller.cancel()
  }, [track])
  const play = useCallback(() => {
    track.play()
    setPlaying(track.playing)
    setReady(track.ready)
  }, [track])

  const changeScene = useCallback(id => {
    pause()
    track.cancelLoading()
    setSceneID(id)
    scroller.scrollNow(0)
  }, [pause, setSceneID, track])

  const [shatterSlide, setShatterSlide] = useState(0)
  useEffect(() => {
    if (sceneID === 'hamlet_3-4-1' && track.audio) {
      const shatterTimes = [78.3, 78.8, 86.5, 87, 88, 93.7, 99, 115.5, 300]
      const handlePlay = event => {
        if (event.target.currentTime > shatterTimes[shatterSlide]) {
          setShatterSlide(shatterSlide + 1)
        }
      }
      track.audio.addEventListener('timeupdate', handlePlay)

      const handleSeek = () => setShatterSlide(0)
      track.audio.addEventListener('seeked', handleSeek)

      return () => {
        track.audio.removeEventListener('timeupdate', handlePlay)
        track.audio.removeEventListener('seeked', handleSeek)
      }
    } else {
      setShatterSlide(0)
    }
  }, [track, sceneID, shatterSlide])

  return (
    <>
      {redirect && <Redirect to={redirect} />}
      <style>
        {`:root { --stage-image: url(/assets/stage-${genericMode ? 'tandc-generic' : sceneID}.jpg) } .stage-edge { background: url(${stageEdgeImg}) no-repeat right/cover, transparent }`}
      </style>

      <div className={'container-fluid' + (noteSettings.recording ? ' recording' : '')}>
        <Row className='align-items-stretch' style={{ minHeight: '100vh' }}>
          <Stage col={{ md: 2, xl: 3 }} setSceneID={changeScene} recording={noteSettings.recording} {...{ preview, sceneList, showID, sceneID, genericMode }} />
          {scene.static
            ? <StaticScene col={{ md: 8, xl: 6 }} showID={showID} scene={scene.static} />
            : <Script col={{ md: 10, xl: 9 }} {...{ scene, track, play, noteSettings, userNotes, dbUserNotesRef, showID, setBusy }} />}
        </Row>
      </div>
      <Col md={2} xl={3} className='d-none d-md-block stage-edge' />
      <ShowBar
        {...{ preview, showID, scene, track, noteSettings }}
        col={{ md: 10, xl: 9 }}
        setNoteSettings={val => {
          setNoteSettings(val)
          window.localStorage.setItem('noteSettings', JSON.stringify(val || false))
        }}
      />

      {/* <div className={'player' + (recording ? ' recording' : '')}>
        <Stage {...{ preview, sceneList, showID, sceneID, setSceneID: changeScene, genericMode }} />
        {scene.static
          ? <StaticScene scene={scene.static} />
          : <Script {...{ scene, track, play }} />}
      </div> */}

      <PlayControls
        {...{ playing, ready, play, pause, sceneID, track }}
        recording={noteSettings.recording}
        setSceneID={changeScene}
        big={scene.static}
        enableKeyboard={busy === 0}
      />
      <ShowNav
        {...{ preview, sceneList, showID, sceneID }}
        recording={noteSettings.recording}
        alwaysShow={scene.static}
        stageImg={`/assets/stage-${sceneID}.jpg`}
        setSceneID={changeScene}
      />
      {sceneID === 'hamlet_3-4-1' && <Shatter slide={shatterSlide} />}
    </>
  )
}
