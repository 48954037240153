import React from 'react'
import EditorTextBlock from './EditorTextBlock'

export default function EditorText ({ scene, dbRef }) {
  return (
    <>
      {scene.blocks.some(block => block.notes) &&
        <div className='alert alert-info w-50 mx-auto'>
          <p>Notes in this scene will not be modified as you edit text here, and will remain attached to their block. You may need to adjust mark locations after editing is complete.</p>
          <p className='mb-0'>Deleting a block, or converting it to a stage direction, will delete the notes attached to it.</p>
        </div>}
      {scene.audio && scene.audio.times &&
        <div className='alert alert-warning w-50 mx-auto'>
          This scene has been timecoded. Adding or removing lines here may cause audio timecodes to no longer align with their proper line.
        </div>}
      {scene.blocks.map((block, i) => (
        <EditorTextBlock
          key={i} index={i} block={block}
          dbRef={dbRef.child(i.toString())}
          onAdd={type => {
            const block = type === 'dir' ? { dir: 'Enter ___' } : { voice: '___', lines: ['___'] }
            const newBlocks = scene.blocks.slice()
            newBlocks.splice(i + 1, 0, block)
            dbRef.set(newBlocks)
          }}
          onRemove={() => {
            const newBlocks = scene.blocks.slice()
            newBlocks.splice(i, 1)
            dbRef.set(newBlocks)
          }}
        />
      ))}
    </>
  )
}
