import React, { useState } from 'react'
import Button from './Button'
import { Row, Col } from './layout'
import shows from '../data/shows'
import '../styles/EditorChars.css'

const defaultAddChar = {
  id: '',
  name: ''
}

const colors = {}
Object.keys(shows).forEach(showID => {
  colors[showID] = shows[showID].charColors || []
})

const CharCell = ({ char, onRemove, onEdit, show }) => {
  const [newChar, setNewChar] = useState(null)

  return (
    <li className='list-group-item position-relative py-2'>
      <h6 className={'mt-1 mb-0 char-' + char.color}>{char.name || 'Unknown character: ' + char.id}</h6>
      {!newChar &&
        <p
          className='mb-1'
          onClick={onEdit ? () => setNewChar(char) : null}
        >
          {char.desc || (onEdit && <span className='text-muted font-italic small' role='button'>Click to add bio.</span>)}
        </p>}
      {newChar &&
        <textarea
          className='form-control mb-2'
          rows='2'
          placeholder='Description'
          value={newChar.desc || ''}
          onChange={e => setNewChar({ ...newChar, desc: e.target.value })}
          onBlur={() => {
            onEdit(newChar)
            setNewChar(null)
          }}
        />}
      {onRemove &&
        <div role='button' className='char-remove-btn' onClick={onRemove}>×</div>}
    </li>
  )
}

export default function EditorChars ({ show, scene, dbShowRef, dbSceneRef }) {
  const [addChar, setAddChar] = useState(defaultAddChar)
  const [search, setSearch] = useState('')

  const suggestions = []
  const suggestedIDs = new Set(scene.chars || [])
  const otherVoices = []
  if (show.chars && scene.blocks) {
    scene.blocks.forEach((block, i) => {
      const suggest = { block: i, chars: [] }

      if (block.dir) {
        suggest.dir = true
        suggest.quote = block.dir
        block.dir.split(' ').map(word => word.toLowerCase().replace(/[^a-z]/g, '')).forEach(word => {
          const char = Object.values(show.chars).find(c => c.id === word)
          if (char && !suggestedIDs.has(char.id)) {
            suggest.chars.push(char.id)
          }
        })
      }

      if (block.voice) {
        suggest.quote = `${block.voice}: ${block.lines[0]}`
        const char = Object.values(show.chars).find(c => c.name === block.voice)
        if (char) {
          if (!suggestedIDs.has(char.id)) {
            suggest.chars.push(char.id)
            suggestedIDs.add(char.id)
          }
        } else if (!otherVoices.find(item => item.voice === block.voice)) {
          otherVoices.push({
            block: i,
            voice: block.voice,
            quote: block.lines[0]
          })
        }
        block.lines.forEach(line => {
          if (line.dir) {
            line.dir.split(' ').forEach(word => {
              const char = Object.values(show.chars).find(c => c.name === word)
              if (char && !suggestedIDs.has(char.id)) {
                suggest.chars.push(char.id)
              }
            })
          }
        })
      }

      if (suggest.chars.length > 0) {
        suggestions.push(suggest)
      }
    })
  }

  return (
    <Row>
      <Col md={4}>
        <h4 className='ml-3'>Dramatis Personae</h4>
        <ul className='list-group mb-3'>
          {Object.values(show.chars || {}).map(char => (
            <CharCell
              key={char.id}
              char={char}
              onRemove={() => {
                dbShowRef.child(char.id).remove()
              }}
              onEdit={newChar => {
                dbShowRef.child(char.id).set(newChar)
              }}
            />
          ))}
        </ul>
        <div className='card'>
          <div className='card-body'>
            <small className={'form-text text-muted mb-1 mt-n3 text-monospace' + (show.chars && show.chars[addChar.id] ? ' text-danger' : '')}>{addChar.id || '——'}</small>
            <input
              type='text'
              className='form-control mb-2'
              placeholder='Name'
              value={addChar.name}
              onChange={e => setAddChar({
                ...addChar,
                name: e.target.value,
                id: e.target.value.toLowerCase().replace(/[^a-z]+/g, '')
              })}
            />
            <textarea
              className='form-control mb-2'
              rows='2'
              placeholder='Description'
              value={addChar.desc || ''}
              onChange={e => setAddChar({ ...addChar, desc: e.target.value })}
            />
            <select className={'form-control custom-select mb-2 color-select char-' + addChar.color} value={addChar.color || 'initial'} onChange={e => setAddChar({ ...addChar, color: e.target.value })}>
              <option disabled value='initial'>Select affiliation...</option>
              <option value='none'>(none)</option>
              {show.id && colors[show.id] && colors[show.id].map(color => (
                <option key={color.id} value={color.id}>{color.title}</option>
              ))}
            </select>
            <Button
              color='success'
              disabled={!(addChar.name && addChar.color)}
              className='btn-block'
              onClick={() => {
                let newID = addChar.id
                let i = 1
                while (show.chars && show.chars[newID]) {
                  newID = addChar.id + i
                  i++
                }
                dbShowRef.child(newID).set({ ...addChar, id: newID })
                setAddChar({ ...defaultAddChar, color: addChar.color })
              }}
            >
              Add Character
            </Button>
          </div>
        </div>
      </Col>

      <Col md={4}>
        <h4 className='ml-3'>Add to Scene</h4>
        {suggestions.map(suggest => (
          <div key={suggest.block} className='list-group mb-3'>
            <div className={'suggest-quote list-group-item list-group-item-' + (suggest.dir ? 'warning' : 'info')}>
              <span className={suggest.dir ? 'font-italic' : 'font-serif'}>{suggest.quote}</span>
              <div className='suggest-block'>#{suggest.block}</div>
            </div>
            {suggest.chars.map(charID => (
              <button
                key={charID}
                className='list-group-item list-group-item-action'
                onClick={() => dbSceneRef.set([...(scene.chars || []), charID])}
              >
                {show.chars[charID].name}
              </button>
            ))}
          </div>
        ))}
        {otherVoices.length > 0 &&
          <div className='list-group mb-3'>
            <div className='list-group-item list-group-item-secondary'>(unknown voices)</div>
            {otherVoices.map(v => (
              <button
                key={v.voice}
                className='list-group-item list-group-item-action'
                onClick={() => setAddChar({ ...addChar, name: v.voice })}
                title={String(v.quote)}
              >
                #{v.block}: {v.voice}
              </button>
            ))}
          </div>}
        <div className='list-group'>
          <div className='list-group-item py-3'>
            <input type='text' className='form-control' placeholder='Search' value={search} onChange={e => setSearch(e.target.value)} />
          </div>
          {search && Object.values(show.chars || {}).filter(char => char.id.includes(search) || char.name.includes(search)).map(char => (
            <button
              key={char.id}
              className='list-group-item list-group-item-action'
              onClick={() => dbSceneRef.set([...scene.chars, char.id])}
            >
              {char.name}
            </button>
          ))}
        </div>
      </Col>

      <Col md={4}>
        <h4 className='ml-3'>In This Scene</h4>
        <ul className='list-group'>
          {scene.chars
            ? scene.chars.map(charID => (
              <CharCell
                key={charID}
                char={show.chars ? show.chars[charID] || { id: charID } : null}
                onRemove={() => {
                  dbSceneRef.set(scene.chars.filter(id => id !== charID))
                }}
              />
            ))
            : <li className='list-group-item text-muted font-italic'>No characters listed.</li>}
        </ul>
      </Col>

    </Row>
  )
}
