import React, { useEffect, useRef, useState } from 'react'
import RealNote, { UserNote, FakeNote } from './Note'
import iconEdit from '../assets/icon-edit.svg'
import '../styles/Block.css'

const importances = ['high', 'mid', 'low']

export default function Block ({ block, blockIndex, lineIndex, noteMode = 'show', test, currentLine, currentBlock, track, scrollpointRef, noteIDPrefix, play, noteSettings = { expand: 'auto', importance: 2 }, userNotes, dbUserNotesRef, setBusy, ...props }) {
  const anyNotesShown = noteSettings.expand !== 'never'
  const notes = {}
  if (block.notes && noteMode !== 'hide') {
    block.notes.forEach((note, i) => {
      if (!note.body && noteMode !== 'editor') return
      if (!notes[note.line]) notes[note.line] = []
      const id = `${noteIDPrefix}-${i}`
      const extra = !anyNotesShown || importances.indexOf(note.importance || 'mid') > noteSettings.importance
      notes[note.line].push({
        ...note,
        id,
        extra
      })
      if (note.overflow) {
        notes[note.line + 1] = [{
          ...note,
          id,
          extra,
          overflowed: true,
          loc: 0,
          len: note.overflow
        }]
      }
    })
  }
  const userNotesSorted = {}
  if (userNotes) {
    userNotes.forEach((note, i) => {
      if (!userNotesSorted[note.line]) userNotesSorted[note.line] = []
      userNotesSorted[note.line].push({
        ...note,
        id: `${noteIDPrefix}-u${i}`
      })
    })
  }

  const [pinnedNotes, setPinnedNotes] = useState([]) // via click mark, force note reveal
  const [focusedNote, setFocusedNote] = useState(null) // via hover mark or note, show background on mark and note
  const [stickyNote, setStickyNote] = useState(null) // via hover note, force note reveal
  const [quickNotes, setQuickNotes] = useState([]) // via click mark, override animation speed
  const quickTimeout = useRef(null)
  const addQuickNote = id => {
    setQuickNotes(arr => [...arr, id])
    if (quickTimeout.current) {
      clearTimeout(quickTimeout.current)
    }
    quickTimeout.current = setTimeout(() => setQuickNotes([]), 5000)
  }
  const [quickOverride, setQuickOverride] = useState(false)
  useEffect(() => {
    setQuickOverride(true)
    const timeout = setTimeout(() => setQuickOverride(false), 2000)
    return () => clearTimeout(timeout)
  }, [noteSettings.expand])

  if (block.dir) {
    return <div className='stagedir'>{block.dir}</div>
  }

  if (!block.lines) {
    return <div className='stagedir text-danger' title={JSON.stringify(block)} onClick={() => console.log(block)}>Error rendering block: no lines found.</div>
  }

  const Note = (noteMode === 'editor') ? FakeNote : RealNote
  const showNotes = noteMode === 'show'

  const inBlock = currentBlock || (currentLine > lineIndex && currentLine < lineIndex + 1 + block.lines.filter(l => !l.dir).length)
  let index = lineIndex
  const isMobile = window.innerWidth < 768

  return (
    <div className={'block' + (inBlock ? ' in-block' : '') + (block.italic ? ' script-italic' : '')}>
      <h6>{block.voice}</h6>
      {block.lines.map((line, i) => {
        if (line.dir) {
          return <span className='stagedir' key={i}>{line.dir}</span>
        }
        index++
        const indexCopy = index

        const parts = []
        let start = 0
        if (notes[i]) {
          notes[i].forEach(note => {
            if (typeof note.loc === 'number') {
              parts.push(line.slice(start, note.loc))
              parts.push((
                <span
                  key={note.loc}
                  className={'note-inline ' + note.type + (note.id === focusedNote ? ' note-focused' : '') + (note.extra ? ' note-extra' : '')}
                  onMouseOver={() => setFocusedNote(note.id)}
                  onMouseLeave={() => setFocusedNote(null)}
                  onClick={() => {
                    addQuickNote(note.id)
                    if (pinnedNotes.includes(note.id)) {
                      setPinnedNotes(arr => arr.filter(id => id !== note.id))
                    } else {
                      setPinnedNotes(arr => [...arr, note.id])
                    }
                  }}
                >
                  {line.slice(note.loc, note.loc + note.len)}
                </span>
              ))
              start = note.loc + note.len
            }
          })
        }
        parts.push(line.slice(start))

        const lineProps = {}
        if (i === 0 && block.indent && !isMobile) lineProps.style = { marginLeft: block.indent + 'em' }
        const lineEl = <span className={'line' + (showNotes ? ' limit-length' : '') + (userNotesSorted[i] ? ' user-noted' : '')} {...lineProps}>{parts}</span>
        let lineStart = ''
        if (userNotesSorted[i]) {
          const lineWords = line.split(' ')
          lineStart = lineWords.slice(0, 3).join(' ') + (lineWords.length > 3 ? '…' : '')
        }

        return (
          <div key={i + 'notes'} className={'line-container' + (index === currentLine ? ' current' : '')}>
            {track &&
              <div
                className='line-indicator-track'
                onClick={() => {
                  track.line = indexCopy
                  if (play) play()
                }}
              />}
            {track && <div className='line-indicator' />}
            {index === currentLine && <span className='scrollpoint' ref={scrollpointRef} />}
            {isMobile && lineEl}
            {userNotesSorted[i] &&
              userNotesSorted[i].map((note, j) => (
                <UserNote
                  key={'u' + j}
                  note={note}
                  title={lineStart}
                  reveal
                  setBusy={setBusy}
                />
              )
              )}
            {notes[i] &&
              notes[i].filter(n => !n.overflowed).map((note, j) => (
                <Note
                  key={j}
                  note={note}
                  track={track}
                  delay={j}
                  reveal={
                    (
                      !note.extra &&
                      (
                        noteSettings.expand === 'always' ||
                        (
                          noteSettings.expand === 'auto' &&
                          !isMobile &&
                          (
                            note.vimeo ||
                            note.img ||
                            currentBlock ||
                            (currentLine >= index && (currentLine < index + 2 + note.body.length / 30))
                          )
                        )
                      )
                    ) ||
                    pinnedNotes.includes(note.id) ||
                    stickyNote === note.id
                  }
                  quick={quickOverride || quickNotes.includes(note.id)}
                  focused={focusedNote === note.id}
                  onMouseOver={() => {
                    setFocusedNote(note.id)
                    setStickyNote(note.id)
                  }}
                  onMouseLeave={() => {
                    setFocusedNote(null)
                    setStickyNote(null)
                  }}
                  pinned={pinnedNotes.includes(note.id)}
                  onPin={() => {
                    addQuickNote(note.id)
                    if (pinnedNotes.includes(note.id)) {
                      setPinnedNotes(arr => arr.filter(id => id !== note.id))
                      setStickyNote(null)
                    } else {
                      setPinnedNotes(arr => [...arr, note.id])
                    }
                  }}
                />)
              )}
            {!isMobile && lineEl}
            {noteMode === 'show' && dbUserNotesRef &&
              <img
                src={iconEdit}
                alt='Add note'
                className='line-noteadd'
                role='button'
                width='16'
                height='16'
                onClick={() => {
                  dbUserNotesRef.push({
                    block: blockIndex,
                    line: i,
                    body: ''
                  })
                  setBusy(n => n + 1)
                }}
              />}
          </div>
        )
      })}
    </div>
  )
}
