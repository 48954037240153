import analytics from './analytics'
import db from './db'
import shows from '../data/shows'
import urls from '../data/urls'

const tracks = {}
export default function getTrack (id, lines) {
  if (!tracks[id]) tracks[id] = new Track(id, lines)
  const track = tracks[id]
  return track
}

function pauseAllExcept (exceptTrack) {
  Object.values(tracks).forEach(track => {
    if (track !== exceptTrack) track.pause()
  })
}

class Track {
  constructor (id, lines) {
    this.id = id
    this.show = id.split('_')[0]
    this.url = urls.sceneAudio(this.id)
    const trackList = shows[this.show].scenes || [this.id]
    this.next = trackList[trackList.indexOf(this.id) + 1] || null
    this.prev = trackList[trackList.indexOf(this.id) - 1] || null

    // eslint-disable-next-line
    this.audio = new Audio(this.url)
    console.log(this.url, this.audio)

    if (lines) {
      this.setLines(lines)
    } else {
      const staging = window.location.pathname.slice(0, 8) === '/preview'
      db.get(`/${staging ? 'staging' : 'live'}/scenes/${this.id}/audio/times`, true, data => this.setLines(data))
    }

    this.lineChangeHandlers = []

    this.progress = 0
    this.audio.addEventListener('progress', () => {
      try {
        this.progress = this.audio.buffered.end(0)
      } catch (e) {
        console.error(e)
        this.progress = 0
      }
      if (this.progress > this.duration - 1 && this.next) {
        console.log('preloading', this.next)
        getTrack(this.next).restartLoading()
      }
    })
    this.audio.addEventListener('canplaythrough', () => console.log(this.id, 'can play through'))
    this.audio.addEventListener('waiting', () => {
      console.log(this.id, 'buffering')
      if (this.currentTime > 1) {
        analytics.plausible('buffering')
        analytics.event.buffer(this.id)
      }
    })
    this.audio.addEventListener('ended', () => {
      analytics.event.sceneFinish(this.id)
    })
  }

  setLines (lines) {
    this.lines = lines
    if (!lines) return
    this.currentLine = -1
    this.audio.addEventListener('timeupdate', event => {
      const newLine = this.lines.findIndex(t => t > this.time + 0.2) - 1
      if (this.currentLine !== newLine) {
        this.currentLine = newLine
        this.lineChangeHandlers.forEach(f => f(newLine))
      }
    })
  }

  onLineChange (back) {
    this.lineChangeHandlers.push(back)
    return () => {
      this.lineChangeHandlers.splice(this.lineChangeHandlers.indexOf(back), 1)
    }
  }

  cancelLoading () {
    if (!this.loading) return
    console.log(this.id, 'cancelled')
    this.audio.src = '#'
    this.audio.load()
    this.cancelled = true
  }

  restartLoading () {
    if (!this.loading || !this.cancelled) return
    console.log(this.id, 'restarted')
    this.audio.src = this.url
    this.audio.load()
    this.cancelled = false
  }

  play () {
    this.restartLoading()
    this.audio.play()
    pauseAllExcept(this)
  }

  pause () {
    this.audio.pause()
  }

  get playing () { return !this.audio.paused }
  get loading () { return this.audio.networkState !== 1 } // 1: idle, 2: loading
  get ready () { return this.audio.readyState === 4 } // enough data loaded to probably play through
  get duration () { return this.audio.duration || 1 }

  get percent () { return this.time / this.duration }

  get time () { return this.audio.currentTime || 0 }
  set time (val) { this.audio.currentTime = val }

  get line () { return this.currentLine || -1 }
  set line (val) {
    if (!this.lines) console.error('Tried to set line on a track with no lines')
    else if (val < 0) this.time = 0
    else if (val >= this.lines.length) this.time = this.duration
    else this.time = this.lines[val]
  }
}
