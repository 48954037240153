import React, { useState, useRef } from 'react'
import Button from './Button'
import EmbedVimeo from './EmbedVimeo'
import Input from './Input'
import Lightbox from './Lightbox'
import urls from '../data/urls'
import analytics from '../utils/analytics'
import '../styles/Note.css'

export default function Note ({ note, reveal, focused, quick, delay, track, pinned, onPin, ...props }) {
  const [showVimeo, setShowVimeo] = useState(false)
  const [showImg, setShowImg] = useState(false)
  const wasPlaying = useRef(false)
  const [collapsed, setCollapsed] = useState(false)

  return (
    <>
      <div
        className={[
          'note',
          note.type,
          reveal && 'note-reveal',
          collapsed && 'note-collapsed',
          focused && 'note-focused',
          quick && 'note-quick',
          delay && ('delay-' + Math.min(7, delay)),
          (note.body.length > 250 || note.img || note.vimeo) && 'long'
        ].filter(x => x).join(' ')}
        style={{ marginTop: note.offset + 'em' }}
        {...props}
      >
        <div className='d-flex align-items-end'>
          <div className='note-header' onClick={() => setCollapsed(false)}>{note.title}</div>
          {note.loc === undefined && <div role='button' className='note-disclosure' onClick={() => setCollapsed(!collapsed)} />}
          {note.loc !== undefined && <div role='button' className={'note-pin' + (pinned ? ' pinned' : '')} onClick={onPin} />}
        </div>
        <div className='note-body'>
          {note.body}
          {note.img &&
            <img
              src={urls.imageThumb(note.img)}
              alt={note.imgCaption}
              className='img-fluid rounded mt-2'
              role='button'
              onClick={() => setShowImg(true)}
            />}
          {note.img && note.imgCaption &&
            <div className='text-muted font-italic mt-1'>{note.imgCaption}</div>}
          {note.vimeo &&
            <img
              src={urls.videoThumb(note.vimeoThumb || 'default.svg')}
              alt=''
              className='img-fluid rounded mt-2'
              role='button'
              onClick={() => {
                setShowVimeo(true)
                wasPlaying.current = track.playing
                track.pause()
                analytics.event.videoView(note.vimeo)
              }}
            />}
        </div>
      </div>
      {note.img &&
        <Lightbox
          show={showImg}
          onDismiss={() => {
            setShowImg(false)
          }}
        >
          <img src={urls.imageOrig(note.img)} alt='' className='img-fluid rounded' style={{ maxHeight: '75vh' }} />
          <div className='mt-2' style={{ fontSize: '1.3em' }}>{note.imgCaption}</div>
        </Lightbox>}
      {note.vimeo &&
        <Lightbox
          show={showVimeo}
          onDismiss={() => {
            setShowVimeo(false)
            if (wasPlaying.current) track.play()
          }}
        >
          <EmbedVimeo vimeoID={note.vimeo} />
        </Lightbox>}
    </>
  )
}

export function FakeNote ({ type, title, ...props }) {
  return (
    <div className={'fake-note ' + type}>
      {title}
      <div className='fake-note-line' />
    </div>
  )
}

export function UserNote ({ note, title, reveal, focused, setBusy, ...props }) {
  const [collapsed, setCollapsed] = useState(false)
  const [editing, setEditing] = useState(!note.body)

  return (
    <>
      <div
        className={[
          'note note-user',
          reveal && 'note-reveal',
          collapsed && 'note-collapsed',
          focused && 'note-focused',
          (note.body.length > 250) && 'long'
        ].filter(x => x).join(' ')}
        style={{ marginTop: note.offset + 'em' }}
        {...props}
      >
        <div className='d-flex align-items-end'>
          <div className='note-header' onClick={() => setCollapsed(false)}>{title}</div>
          {note.loc === undefined && <div role='button' className='note-disclosure' onClick={() => setCollapsed(!collapsed)} />}
        </div>
        <div className='note-body'>
          {editing
            ? (
              <>
                <Input
                  area
                  rows='4'
                  value={note.body}
                  dbRef={note.dbRef.child('body')}
                  nullable
                  onChange={val => {
                    if (!val) {
                      note.dbRef.remove()
                    }
                  }}
                  onBlur={() => {
                    setEditing(false)
                    setBusy(n => n - 1)
                  }}
                />
                <div className='my-1 w-100 d-flex justify-content-end'>
                  <Button
                    size='sm'
                    color='link'
                    className='text-danger'
                    onClick={() => {
                      setBusy(n => n - 1)
                      note.dbRef.remove()
                    }}
                  >Delete
                  </Button>
                  <Button
                    size='sm'
                    color='info'
                  >Save
                  </Button>
                </div>
              </>)
            : (
              <div onClick={() => {
                setEditing(true)
                setBusy(n => n + 1)
              }}
              >
                {note.body}
                <div className='small text-muted'>Click to edit</div>
              </div>)}
        </div>
      </div>
    </>
  )
}
