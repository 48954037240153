import React from 'react'

export function Row ({ className = '', children, ...props }) {
  return (
    <div className={'row ' + className} {...props}>
      {children}
    </div>
  )
}

export function Col ({ width, sm, md, lg, xl, className, children, ...props }) {
  const classes = []
  if (width === 'auto') {
    classes.push('col')
  } else {
    classes.push('col-' + (width || 12))
  }
  if (sm) classes.push('col-sm-' + sm)
  if (md) classes.push('col-md-' + md)
  if (lg) classes.push('col-lg-' + lg)
  if (xl) classes.push('col-xl-' + xl)
  if (className) classes.push(className)
  return (
    <div className={classes.join(' ')} {...props}>
      {children}
    </div>
  )
}
