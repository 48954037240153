import React from 'react'
import NavbarPublic from '../components/NavbarPublic'
import LoginForm from '../components/LoginForm'
import { useHistory } from 'react-router-dom'

export default function Login () {
  const history = useHistory()

  return (
    <>
      <NavbarPublic />
      <div className='d-flex justify-content-center align-items-center' style={{ width: '100%', height: '80vh' }}>
        <LoginForm
          displayCreatePromo
          onSignIn={() => history.push('/account/profile')}
          onCreateAccount={() => history.push('/account/profile')}
        />
      </div>
    </>
  )
}
