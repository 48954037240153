import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/logo-syt.svg'
import wordmark from '../assets/wordmark.svg'

export default function Wordmark ({ logoOnly, small, preview, location, link, ...props }) {
  const isPreview = preview || (location && location.pathname.slice(0, 8) === '/preview')
  return (
    <Link to={link || (isPreview ? '/preview' : '/')} {...props}>
      <img src={logoOnly ? logo : wordmark} alt='SYT Dream Project' height={small ? '40' : '60'} className='d-block' />
      {isPreview && !small &&
        <span className='d-block small text-warning font-weight-bold' style={{ letterSpacing: '0.15em', marginLeft: '59px', mixBlendMode: 'color-dodge' }}>PREVIEW</span>}
    </Link>
  )
}
