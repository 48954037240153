import React from 'react'

export default function Content () {
  return (
    <>
      <style>
        {`.cast-list {
            font-size: 1.1em;
          }
          .cast-list li {
            margin-bottom: .5em;
          }
          .bio-link {
            color: inherit !important;
            text-decoration: underline;
          }`}
      </style>
      <div class='text-center'>
        <h2>The Winter’s Tale</h2>
        <h5 className='font-italic'>Presented by the SYT 2021 Core Ensemble</h5>

        <ul class='list-unstyled cast-list mt-4'>
          <li><b>Lucas Bueling</b> – Jailer, Bohemian Servant and Officer</li>
          <li><b>Viviann Chaffin</b> – Autolycus</li>
          <li><b>Louisa Darr</b> – Perdita</li>
          <li><b>Keena Dietz</b> – Hermione</li>
          <li><b>Pip Edmund</b> – Clown</li>
          <li><b>Maeve Filkins</b> – Shepherd</li>
          <li><b>Gray Jensen</b> –  Florizel</li>
          <li><b>Aine Johnson</b> – Dion and Mariner</li>
          <li><b>Mira Iwaszek</b> – Emilia, Dorcas and Time</li>
          <li><b>Louise Kelly</b> – Antigonus</li>
          <li><b>Ilse Prokosch</b> – Lady, Mopsa and Time</li>
          <li><b>Abraham Riker</b> – Leontes</li>
          <li><b>Søren Staffaucher</b> – Mamillius and Cleomenes</li>
          <li><b>Tic Treitler</b> – Time and Sicilian Servant</li>
          <li><b>Christopher Tupper</b> – Rogero</li>
          <li><b>Ashlyn Walters</b> –  Camillo</li>
          <li><b>Blake Walters</b> – Polixenes</li>
          <li><b>Caitlin Warshaw</b> – Paulina</li>
        </ul>

        <h4 className='mt-5'>Creative Team</h4>
        <ul class='list-unstyled cast-list'>
          <li>Sound Designer and Composer: <b><a href='https://danielbonespur.bandcamp.com/' target='_blank' rel='noopener noreferrer' className='text-info bio-link'>Dan Dukich</a></b></li>
          <li>Web Designer and Developer: <b><a href='https://joshdudley.net' target='_blank' rel='noopener noreferrer' className='text-info bio-link'>Josh Dudley</a></b></li>
          <li>Stage Manager: <b>Gabriel Peñaloza-Hernandez</b></li>
          <li>Dramaturg: <b>Lukas Brasherfons</b></li>
          <li>Video Editor: <b>Jeremy Wilker</b></li>
          <li>Director: <b>Logan Verdoorn</b></li>
          <li>Producers: <b>Maria Signorelli, Logan Verdoorn</b></li>
        </ul>
      </div>
    </>
  )
}
