import React from 'react'

export default {
  muchado: {
    id: 'muchado',
    title: 'Much Ado About Nothing',
    titleShort: 'Much Ado',
    summary: <><i>Much Ado About Nothing</i> is a story of old and new love. As a group of soldiers returns home from the field, the old "merry war" of wits between iconic duo Beatrice and Benedick is contrasted with the new and troubled love of Hero and Claudio. As each couple navigates their new and changing identities, the play asks us to beware of superficial feeling, interrogate societal expectations of love and marriage, and ultimately gives us a tale chock-full of verbal banter, comedic hijinks, and some of the most endearing and beloved Shakespeare characters ever written.</>,
    callout: 'JUST PREMIERED',
    promoImg: 'show-promo-muchado.jpg',
    posterImg: 'show-promo-muchado.jpg',
    live: true,
    staging: true,

    pages: [
      { id: 'index', title: 'Overview' }
    ],

    scenes: [
      '1-1', '1-2', '1-3', '2-1', '2-2', '2-3', '3-1', '3-2', '3-3', '3-4', '3-5', '4-1', '4-2', '5-1', '5-2', '5-3', '5-4', 'credits'
    ].map(id => 'muchado_' + id),
    charColors: [
      { id: 'citizen', title: 'Citizen' },
      { id: 'outsider', title: 'Out-of-towner' }
    ]
  },

  randj: {
    id: 'randj',
    title: 'Romeo & Juliet',
    titleShort: 'Romeo & Juliet',
    summary: <>The greatest love story ever told, Shakespeare’s classic drama of two feuding families and the children who find connection across a border of hatred is Shakespeare at his most ecstatic, delightful, and heartbreaking. <i>Romeo & Juliet</i> is a tale of two young people who learn about growing up by falling in love before it ultimately kills them. Rediscover this devastating story of age versus youth on the Dream Project.</>,
    callout: 'EXPLORE THE CLASSIC',
    promoImg: 'show-promo-randj.jpg',
    posterImg: 'show-promo-randj.jpg',
    live: true,
    staging: true,

    pages: [
      { id: 'index', title: 'Overview' },
      { id: 'cast', title: 'Cast & Crew' },
      { id: 'download', title: 'Download' }
    ],
    download: {
      links: [
        { title: 'Download MP3 (226 MB)', file: 'SYT_RomeoAndJuliet.mp3' }
      ]
    },

    scenes: [
      'prologue', '1-1', '1-2', '1-3', '1-4', '1-5', '2-1', '2-2', '2-3', '2-4', '2-5', '3-1', '3-2', '3-3', '3-4', '3-5', '4-1', '4-2', '4-3', '4-4', '5-1', '5-2', '5-3', 'credits'
    ].map(id => 'randj_' + id),
    charColors: [
      { id: 'montague', title: 'Montague' },
      { id: 'capulet', title: 'Capulet' },
      { id: 'escalus', title: 'Escalus' }
    ]
  },

  winterstale: {
    id: 'winterstale',
    title: 'The Winter’s Tale',
    titleShort: 'Winter’s Tale',
    summary: <>Experience a breathtaking tale of jealousy, love, revenge, fate and astonishing grace. <i>The Winter’s Tale</i> is a story that crosses seas and decades, both a classic folktale and an astonishingly modern love story that imagines the possibility of grace and redemption at the furthest extremes of human folly.</>,
    promoImg: 'show-promo-winterstale.jpg',
    posterImg: 'show-promo-winterstale.jpg',
    live: true,
    launchDate: 'Sat, 26 Jun 2021 12:00:00 CST',

    introProps: {
      width: 1800,
      height: 1200,
      style: { margin: '-20vh 0' }
    },
    pages: [
      { id: 'index', title: 'Overview' },
      { id: 'cast', title: 'Cast & Crew' },
      { id: 'download', title: 'Download' }
    ],
    startButton: 'Enter the Winter’s Tale',
    download: {
      links: [
        { title: 'Download MP3 (172 MB)', file: 'SYT_TheWintersTale.mp3' }
      ]
    },

    scenes: [
      'prologue', '1-1', '2-1', '2-2', '2-3', '3-1', '3-2', '3-3', '4-1', '4-2', '4-3', '4-4', '5-1', '5-2', '5-3', '5-4', 'epilogue'
    ].map(id => 'winterstale_' + id),
    charColors: [
      { id: 'sicily', title: 'Sicilian' },
      { id: 'bohemia', title: 'Bohemian' }
    ]
  },

  tandc: {
    id: 'tandc',
    title: 'Troilus & Cressida',
    titleShort: 'Troilus & Cressida',
    summary: 'The Trojan war has raged for seven years. Troyan hero Hector strives to defend his city against an unrelenting siege from the Grecian forces. Achilles, the mighty Greek champion, has led his army to the brink of victory, but is growing weary of perpetual war. Inside the city walls, young prince Troilus falls in love with Cressida, the daughter of a defector to the Greeks. As Hector and Achilles clash, Troilus and Cressida are thrust together and ripped apart by the changing tides of war.',
    summaryStyle: { fontSize: '0.96em' },
    promoImg: 'show-promo-tandc.png',
    posterImg: 'show-promo-tandc.png',
    live: true,
    launchDate: 'Sat, 3 Apr 2021 10:00:00 CST',

    pages: [
      { id: 'index', title: 'Overview' },
      { id: 'directorsnote', title: 'Director’s Note' },
      { id: 'cast', title: 'Cast & Crew' }
    ],
    directorsNote: [
      'Troilus and Cressida is its own kind of love story. Not only do we have the titular love between Troilus and Cressida, as well as its complementary will-they-or-won’t-they battlefield romance of Hector and Achilles, but basically everyone around loves someone within throwing distance: Troilus loves Cressida, Cressida loves Troilus, Diomedes loves Cressida, Achilles loves Hector, Pandarus loves Troilus, Aeneas loves Hector, Paris loves Helen (or at least he did), Ajax loves Thersites, Patroclus loves Achilles, Helen loves Paris (or at least she did) Ulysses loves Troilus, and I would argue Agamemnon and Nestor both in their own way love Hector too (people find Hector very loveable), and I won’t even pretend to diagnose the tortured feelings Thersites has for Patroclus.',
      'This play has long been mired in a judgmental Victorian morality. Peter Brook said that the Victorian image of Shakespeare was useful because whatever the Victorians thought, we should always do the opposite. The general critical consensus about Troilus and Cressida – even among people who like it – has usually been that it is a bitter, cynical play, somehow concerned with how sexual depravity poisons military endeavors. Such a mindset renders the ultimate death of Pandarus at play’s end through his contracted venereal diseases as somehow the fine for the wages of sin, whose punishment is death, rather than what it is: an unfortunate extension of a man’s inability to express his love directly for another man, and when the one avenue by which he has contact with that man disintegrates, his health disintegrates in tandem.',
      'As for the cynicism, sure, the play has a non-reverential treatment of its Homeric characters. The flaws and foibles of these warriors are more on display than ancient legend would prepare us for. And yes, the play’s ending, with the death of Hector and the imminent destruction of Troy, is certainly not a positive ending for those involved. However, I object to the notion that somehow this means the play is “cynical” or “pessimistic.” This play asks us, very reasonably, to see ourselves as part of a society that can only move forward if it is completely destroyed. The action shows us people striving to do their best under untenable circumstances, and how even well-intentioned action in such a situation inherently corrupts the people living within that corrupt society. Every character in this play, to an individual, is striving for transcendence from the putrid civilization in which they live, and that transcendence usually comes in the form of interpersonal love. Thus, I feel no need to judge that love more harshly simply because it often veers from heteronormativity, as previous generations did.',
      'Yes, Troy falls at the end of this play. Almost everyone is consumed in the flames. If Shakespeare’s universe continued, there would be no Trojan horse, no Odyssey, no Aeneid, because his fall of Troy is so complete it incinerates both Greeks and Troyans alike. However, maybe that is what needed to happen. Maybe Troy needed to burn down to allow for a new epoch. Whatever tragedy this play may have is tempered by the prime function of that genre: the play incites a purgation of heightened emotion by which experience we exit the story more productive citizens.',
      'In the waning light of Troilus and Cressida, we hear the message that, as the poet Tennyson said, “’Tis not too late to seek a newer world.”',
      'We invite you to “rage against the dying of the light” with the SYT ensemble, as you enjoy our rendition of this verbose, invigorating, and unclassifiable gem.',
      ['Lukas Brasherfons', 'Director, Troilus and Cressida', 'Resident Dramaturg, Shakespearean Youth Theatre']
    ],

    scenes: [
      'intro', 'prologue', '1-1', '1-2', '1-3', '2-1', '2-2', '2-3', '3-1', '3-2', '3-3', '4-1', '4-2', '4-3', '4-4', '4-5', '5-1', '5-2', '5-3', '5-4', '5-5', '5-6', '5-7', '5-8', '5-9', '5-10', 'credits'
    ].map(id => 'tandc_' + id),
    charColors: [
      { id: 'greek', title: 'Greek' },
      { id: 'trojan', title: 'Trojan' }
    ]
  },

  hamlet: {
    id: 'hamlet',
    title: 'Hamlet',
    titleShort: 'Hamlet',
    summary: 'A king has died. His widow has hastily remarried. The king’s son has returned home to find rot and corruption beneath the pristine facade of the state. As the nation is thrown into a whirlpool of chaos, lives are uprooted, and revenge, ambition, and madness reign unchecked. Welcome to Elsinore.',
    promoImg: 'show-promo-hamlet.jpg',
    posterImg: 'show-poster-hamlet.jpg',
    live: true,
    launchDate: 'Sat, 19 Dec 2020 10:00:00 CST',

    pages: [
      { id: 'index', title: 'Overview' },
      { id: 'directorsnote', title: 'Director’s Note' },
      { id: 'cast', title: 'Cast & Crew' },
      { id: 'download', title: 'Download' }
    ],
    directorsNote: [
      'Welcome to The Dream Project by Shakespearean Youth Theatre! This season, we are thrilled to be sharing some of Shakespeare’s most iconic works, beginning with Hamlet and A Midsummer Night’s Dream. Each play centers young people confronting and transforming power structures they inherited from their elders - a theme that is brilliantly supported by the natural revolutionary tendencies of this generation of young artists, and this year’s Season 16 Core Ensemble.',
      'I am always delighted by the perspectives, insights, passion, and grit this ensemble of young artists brings to the process. The Tragedie of Hamlet famously challenges directors and actors to answer one question: is Hamlet actually mad?',
      'Throughout the course of rehearsals, this Ensemble has been less interested in answering that question than exploring another, more nuanced question: what does Hamlet’s mental state reveal about the reality of the world around him?',
      'Hamlet is a play about truth, and the fevered dreams that come knocking at our doors when truth breaks down and reality is left to each individual’s subjective experience. Prince Hamlet is an unreliable narrator, to be sure. He may also know far more about the state of the world than any other character in the play. At a time when the idea of truth is challenged daily, I can think of no better story to engage this experience than one that has been held up for centuries as a paragon of “Universal Truth”—which, upon closer examination, requires audiences and artists alike to ask endless questions, and provides few answers.',
      ['Logan Verdoorn', 'Artistic Director']
    ],
    download: {
      text: 'Available as an MP3 or a CD-quality WAV file.',
      links: [
        { title: 'Download MP3 (217 MB)', file: 'SYT_Hamlet.mp3' },
        { title: 'Download WAV (1.6 GB)', file: 'SYT_Hamlet.wav' }
      ]
    },

    scenes: [
      'intro', '1-1', '1-2', '1-3', '1-4', '1-5', '2-1', '2-2', '3-1', '3-2', '3-3', '3-4-1', '3-4-2', '4-1', '4-2', '4-3', '4-4', '4-5', '4-6', '5-1', '5-2', 'credits'
    ].map(id => 'hamlet_' + id),
    charColors: []
  },

  midsummer: {
    id: 'midsummer',
    title: 'A Midsummer Night’s Dream',
    titleShort: 'Midsummer',
    summary: 'Join us for an experience that navigates the story of youthful lovers getting their first glimpse of the indescribable, a crew of workers cobbling together a show out of sheer passion, and the magical forces that make it all possible. Safe to say, there will be ne’er a set of fairy wings in sight.',
    promoImg: 'show-promo-midsummer.jpg',
    posterImg: 'show-promo-midsummer.png',
    live: true,
    launchDate: 'Sat, 19 Sep 2020 10:00:00 CDT',

    pages: [
      { id: 'index', title: 'Overview' },
      { id: 'download', title: 'Download' }
    ],
    download: {
      links: [
        { title: 'Download MP3 (112 MB)', file: 'SYT_Midsummer.mp3' }
      ]
    },

    introImg: 'intro-midsummer.svg',

    scenes: [
      'intro', '1-1', '1-2', '2-1', '2-2', '3-1', '3-2', '4-1', '4-2', '4-3', '5-1', 'credits'
    ].map(id => 'midsummer_' + id),
    charColors: []
  }
}
