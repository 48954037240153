// eslint-disable-next-line
/* eslint-disable jsx-a11y/accessible-emoji */

import React, { useState, useEffect } from 'react'
import db from '../utils/db'
import Button from '../components/Button'
import { Row } from '../components/layout'
import Navbar, { BackArrow } from '../components/Navbar'
import Script from '../components/Script'
import EditorAudio from '../components/EditorAudio'
import EditorChars from '../components/EditorChars'
import EditorData from '../components/EditorData'
import EditorImport from '../components/EditorImport'
import EditorFreeform from '../components/EditorFreeform'
import EditorNotes from '../components/EditorNotes'
import EditorPreflight from '../components/EditorPreflight'
import EditorStage from '../components/EditorStage'
import EditorStylesBlock from '../components/EditorStylesBlock'
import EditorText from '../components/EditorText'
import auth from '../utils/auth'
import getTrack from '../utils/Track'

const viewModes = [
  { id: 'import', label: '📖 Import' },
  { id: 'data', label: '🛠 Data', color: 'info' },
  { id: 'freeform', label: '📋 Freeform' },
  { id: 'text', label: '📝 Text' },
  { id: 'notes', label: '📑 Notes' },
  { id: 'styles', label: '🖌 Styles' },
  { id: 'audio', label: '🎙 Audio' },
  { id: 'chars', label: '👥 Chars' },
  { id: 'stage', label: '💡 Stage' },
  { id: 'preflight', label: '🔎 Preflight' },
  { id: 'preview', label: '👀 Preview', color: 'primary' }
]

export default function Editor ({ match, history, location }) {
  const view = match.params.editor
  const showID = match.params.show
  const sceneID = `${showID}_${match.params.scene}`

  let status = { text: 'Connected' }

  db.registerEditor()

  const [show, setShow] = useState({ scenes: ['hamlet_1-1'] })
  useEffect(() => {
    return db.subscribe('Editor_show', `/staging/shows/${showID}`, setShow)
  }, [setShow, showID])
  const dbShowRef = db.ref(`/staging/shows/${showID}`)

  const [scene, setScene] = useState({ fake: true, blocks: [], id: 'hamlet_1-1' })
  useEffect(() => {
    return db.subscribe('Editor_scene', `/staging/scenes/${sceneID}`, setScene)
  }, [setScene, sceneID])
  const dbSceneRef = db.ref(`/staging/scenes/${sceneID}`)

  const [presence, setPresence] = useState({})
  useEffect(() => {
    return db.subscribe('Editor_presence', '/analytics/presence/editor', setPresence)
  }, [setPresence])
  if (presence) {
    const editorCount = Object.keys(presence).length
    if (editorCount === 2) {
      status = { text: '1 other person editing', color: 'light' }
    } else if (editorCount > 2) {
      status = { text: editorCount - 1 + ' other people editing', color: 'warning' }
    }
  }

  const [hotCode, setHotCode] = useState(null)
  let hotCodeValid = true
  if (hotCode) {
    if (hotCode === JSON.stringify(scene, null, 2)) {
      setHotCode(null)
    }
    if (typeof hotCode === 'object') {
      status = { text: 'Parsed data ready', color: 'info' }
    } else {
      try {
        JSON.parse(hotCode)
        status = { text: 'Unsaved data changes', color: 'info' }
      } catch (err) {
        hotCodeValid = false
        status = { text: 'Data syntax error', color: 'warning' }
      }
    }
  }

  const [connected, setConnected] = useState(true)
  useEffect(() => {
    return db.subscribe('Editor_connected', '/.info/connected', setConnected)
  }, [setConnected])
  if (!connected) {
    status = { text: 'Connection to database lost', color: 'warning' }
  }

  const [showAllEditors, setShowAllEditors] = useState(false)
  let enabledViews = viewModes
  if (!show.editorViews) {
    enabledViews = []
  } else if (!showAllEditors) {
    enabledViews = viewModes.filter(mode => show.editorViews[mode.id])
  } else {
    enabledViews = viewModes.filter(mode => !show.editorViews[mode.id])
  }
  if (!showAllEditors && !enabledViews.some(mode => mode.id === view)) {
    enabledViews.push(viewModes.find(mode => mode.id === view))
  }

  let navButton = null
  if (view === 'import') {
    navButton = (
      <Button
        size='sm'
        color='success'
        disabled={typeof hotCode !== 'object'}
        onClick={() => {
          // eslint-disable-next-line
          const id = prompt('Enter a scene ID')
          if (!id) return
          db.ref(`/staging/scenes/${showID}_${id}`).set({
            ...hotCode,
            id: `${showID}_${id}`
          })
          dbShowRef.child('scenes').set([
            ...show.scenes,
            `${showID}_${id}`
          ])
        }}
      >Add Scene
      </Button>
    )
  } else if (hotCode && (view === 'data' || view === 'freeform')) {
    navButton = (
      <Button
        size='sm'
        outline={!hotCode}
        color={!hotCode ? 'secondary' : hotCodeValid ? 'info' : 'warning'}
        disabled={!hotCode || !hotCodeValid}
        onClick={() => {
          try {
            const parsed = typeof hotCode === 'string' ? JSON.parse(hotCode) : hotCode
            dbSceneRef.set(parsed)
            setHotCode(null)
          } catch (err) {
            console.log(err, hotCode)
          }
        }}
      >Save Data
      </Button>
    )
  } else if (view === 'notes') {
    navButton = (
      <Button
        size='sm'
        color='secondary'
        onClick={() => {
          scene.blocks.forEach((block, i) => {
            if (!block.notes) return
            dbSceneRef.child(`/blocks/${i}/notes`).set(block.notes.sort((a, b) => (a.line * 100 + (a.loc || -1) - (b.line * 100 + (b.loc || -1)))))
          })
        }}
      >Sort Notes
      </Button>
    )
  }

  const [freeformText, setFreeformText] = useState('')

  if (!scene || !scene.blocks) {
    // return <Navbar back='/backstage' title='Editor'>Failed to load scene data.</Navbar>
    return (
      <>
        <Navbar>
          <div className='flex-grow-1 d-flex align-items-bottom' style={{ width: '10%' }}>
            <BackArrow link={`/backstage/${showID}`} className='mr-3' />
            <select
              className='custom-select custom-select-sm bg-dark text-light w-auto'
              value={match.params.scene}
              onChange={event => history.push(location.pathname.replace(match.params.scene, event.target.value))}
            >
              {(show.scenes || []).map(id => {
                const key = id.split('_')[1]
                return <option key={key} value={key}>Scene {key}</option>
              })}
            </select>
          </div>
        </Navbar>

        <div className='container mb-5 editor'>
          <p className='display-4 text-muted text-center mt-5'>Failed to load scene.</p>
          {auth.hasRights('admin') &&
            <Button
              color='success'
              onClick={() => {
                db.ref(`/staging/scenes/${sceneID}`).set({
                  blocks: [{ dir: 'Static scene' }],
                  id: sceneID,
                  static: 'Blank'
                })
              }}
            >Add Scene Data ({sceneID})
            </Button>}
        </div>
      </>
    )
  }

  let editorContent = null
  if (view === 'import') editorContent = <EditorImport result={hotCode} setResult={setHotCode} />
  else if (view === 'data') editorContent = <EditorData data={show} scene={scene} hotCode={hotCode} setHotCode={setHotCode} />
  else if (view === 'freeform') editorContent = <EditorFreeform scene={scene} text={freeformText} setText={setFreeformText} result={hotCode} setResult={setHotCode} />
  else if (view === 'text') editorContent = <EditorText scene={scene} dbRef={dbSceneRef.child('/blocks')} />
  else if (view === 'notes') editorContent = <EditorNotes scene={scene} dbRef={dbSceneRef} />
  else if (view === 'styles') editorContent = scene.blocks.map((block, i) => <EditorStylesBlock key={i} block={block} dbRef={dbSceneRef.child(`/blocks/${i}`)} />)
  else if (view === 'audio') editorContent = <EditorAudio scene={scene} sceneID={sceneID} dbRef={dbSceneRef.child('/audio')} />
  else if (view === 'chars') editorContent = <EditorChars show={show} scene={scene} dbShowRef={dbShowRef.child('/chars')} dbSceneRef={dbSceneRef.child('/chars')} />
  else if (view === 'stage') editorContent = <EditorStage scene={scene} dbRef={dbSceneRef} />
  else if (view === 'preflight') editorContent = <EditorPreflight show={show} scene={scene} dbRef={dbSceneRef} />
  else if (view === 'preview') {
    editorContent = (
      <Row>
        <Script scene={scene} track={getTrack(sceneID)} />
      </Row>
    )
  } else editorContent = <p className='display-4 text-muted text-center mt-5'>Editor not found.</p>

  return (
    <>
      <Navbar>
        <div className='flex-grow-1 d-flex align-items-bottom' style={{ width: '10%' }}>
          <BackArrow link={`/backstage/${showID}`} className='mr-3' />
          <select
            className='custom-select custom-select-sm bg-dark text-light w-auto'
            value={match.params.scene}
            onChange={event => history.push(location.pathname.replace(match.params.scene, event.target.value))}
          >
            {(show.scenes || []).map(id => {
              const key = id.split('_')[1]
              return <option key={key} value={key}>Scene {key}</option>
            })}
          </select>
        </div>

        <div className='flex-grow-0 d-flex justify-content-center'>
          <div className='btn-group btn-group-sm'>
            {enabledViews.map(mode => (
              <Button
                key={mode.id}
                color={mode.id === view ? (mode.color || 'secondary') : 'secondary'}
                outline={mode.id !== view}
                link={mode.id}
                className='text-light'
                onClick={() => setShowAllEditors(false)}
              >
                {mode.label}
              </Button>
            ))}
            {auth.hasRights('admin') &&
              <Button color='secondary' outline={!showAllEditors} className='text-light' onClick={() => setShowAllEditors(!showAllEditors)}>•••</Button>}
          </div>
          {showAllEditors && auth.hasRights('admin') &&
            <Button
              size='sm' color='dark' className='ml-2'
              onClick={() => {
                dbShowRef.child('/editorViews/' + view).set(!show.editorViews[view] || null)
                setShowAllEditors(false)
              }}
            >
              {show.editorViews[view] ? 'Deny' : 'Allow'}
            </Button>}
        </div>

        <div className='flex-grow-1 d-flex justify-content-end align-items-center' style={{ width: '10%' }}>
          <span className={`text-${status.color || 'muted'} small mr-3`} onClick={() => setHotCode(null)}>{status.text}</span>
          {navButton}
        </div>
      </Navbar>

      <div className='container mb-5 editor'>
        {editorContent}
      </div>
    </>
  )
}
