import React, { useState, useEffect, useCallback } from 'react'
import Button from './Button'
import pauseIcon from '../assets/icon-pause.svg'
import playIcon from '../assets/icon-play.svg'
import '../styles/PlayControls.css'

export default function PlayControls ({ sceneID, setSceneID, track, big, playing, play, pause, ready, recording, enableKeyboard, ...props }) {
  const playPauseClicked = useCallback(() => {
    if (track.playing) {
      console.log('pausing', track)
      pause()
    } else {
      console.log('playing', track)
      play()
    }
  }, [pause, play, track])

  useEffect(() => {
    if (!enableKeyboard) return

    const handleKeyDown = event => {
      const actions = {
        Space: playPauseClicked
      }
      if (actions[event.code]) {
        event.preventDefault()
        actions[event.code]()
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [enableKeyboard, track, playPauseClicked])

  const [time, setTime] = useState(0)
  useEffect(() => {
    if (big && track && track.audio) {
      const handleTimeUpdate = () => setTime(track.percent)
      track.audio.addEventListener('timeupdate', handleTimeUpdate)
      return () => {
        track.audio.removeEventListener('timeupdate', handleTimeUpdate)
      }
    }
  }, [big, setTime, track])

  if (playing && recording) {
    return null
  }

  let mainIcon = null
  if (!playing) {
    mainIcon = <img src={playIcon} alt='play' />
  } else if (!ready) {
    mainIcon = <span className='spinner-border' />
  } else {
    mainIcon = <img src={pauseIcon} alt='pause' />
  }

  return (
    <>
      <div className={'play-controls d-flex justify-content-center align-items-center' + (big ? ' big' : '')}>
        <Button dot color='dark' size='lg' className='mx-2' onClick={playPauseClicked}>
          {mainIcon}
        </Button>
      </div>
      {big &&
        <div className={'scene-progress progress' + (time > 0 ? ' playing' : '')}>
          <div className='progress-bar bg-secondary' style={{ width: (track.percent * 100) + '%' }} />
        </div>}
    </>
  )
}
