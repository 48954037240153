import React, { useState } from 'react'
import Button from '../components/Button'
import auth from '../utils/auth'

export default function BackstageLogin ({ history }) {
  const [password, setPassword] = useState('')
  const [incorrect, setIncorrect] = useState(false)

  function login (pass) {
    if (auth.login(pass)) {
      history.replace('/backstage')
    } else {
      setIncorrect(true)
      setPassword('')
    }
  }

  return (
    <div className='d-flex flex-column align-items-center justify-content-center mx-auto' style={{ height: '75vh', maxWidth: '36em' }}>
      <h3>Backstage Access</h3>
      <p>Enter your backstage access code to manage show data.</p>
      <form
        onSubmit={event => {
          event.preventDefault()
          login(password)
        }}
      >
        <div className='input-group'>
          <input type='password' className='form-control' autoFocus value={password} onChange={e => setPassword(e.target.value)} />
          <div className='input-group-append'>
            <Button onClick={() => login(password)}>Log In</Button>
          </div>
        </div>
      </form>
      {incorrect && <p className='small text-danger mt-2'>Incorrect password.</p>}
    </div>
  )
}
