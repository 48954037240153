import React from 'react'
import { Row, Col } from './layout'

export default function PageContent ({ img, alt, flip, children, className, ...props }) {
  return (
    <Row className={'mb-3 mb-xl-5 ' + className} {...props}>
      <Col md={6} className={flip ? 'order-md-2' : ''}>
        <img src={img} alt={alt} className='w-100 rounded-lg' />
      </Col>
      <Col md={6} className='mt-3 mt-md-0 d-flex flex-column justify-content-center align-items-start'>
        {children}
      </Col>
    </Row>
  )
}
