import React from 'react'
import Block from './Block'
import EditorNote from './EditorNoteObject'
import { Row, Col } from './layout'

export default function EditorNoteBlock ({ block, dbRef, i, ...props }) {
  if (block.dir) {
    return (
      <Row>
        <Col width={8}>
          <Block block={block} noteMode='editor' />
        </Col>
      </Row>
    )
  }
  return (
    <Row>
      <Col width={6}>
        <Block block={block} noteMode='editor' />
      </Col>
      <Col width={6} className={block.notes ? 'mt-3' : 'mt-5'}>
        <span className='small text-muted monospace d-block mx-5'>BLOCK {i}</span>
        {block.notes && block.notes.map((note, i) => <EditorNote note={note} block={block} dbRef={dbRef.child(i)} key={i} />)}
        <span
          role='button'
          className='text-success small font-weight-bolder px-5 py-3'
          onClick={() => {
            dbRef.child(block.notes ? block.notes.length : 0).set({ type: 'define', line: 0, title: '', body: '' })
          }}
        >＋ ADD NOTE
        </span>
      </Col>
    </Row>
  )
}
