import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import Navbar from '../components/Navbar'
import ShowPageContent from '../components/ShowPageContent'
import db from '../utils/db'

const viewModes = [
  { id: 'text', label: '📝 Text' },
  { id: 'html', label: '🛠 Code' },
  { id: 'preview', label: '👀 Preview', color: 'primary' }
]

export default function PagesEditor ({ match }) {
  const showID = match.params.show
  const pageID = `${showID}_${match.params.page}`

  const [pageList, setPageList] = useState([])
  useEffect(() => {
    return db.subscribe('PagesEditor_pageList', `/staging/shows/${showID}/pages`, setPageList)
  }, [showID, setPageList])

  const [page, setPage] = useState({ text: '' })
  useEffect(() => {
    return db.subscribe('PagesEditor_page', `/staging/pages/${pageID}`, setPage)
  }, [pageID, setPage])
  const dbPageRef = db.ref(`/staging/pages/${pageID}`)

  const [view, setView] = useState('text')

  const [html, setHTML] = useState('')
  useEffect(() => setHTML(page.html || ''), [page.html])

  return (
    <>
      <Navbar back={'/backstage/' + showID} title='Pages'>
        <div className='btn-group btn-group-sm ml-3 mr-auto'>
          {viewModes.map(viewMode => (
            <Button outline={view !== viewMode.id} color='secondary' key={viewMode.id} onClick={() => setView(viewMode.id)}>{viewMode.label}</Button>
          ))}
        </div>
        {pageList && pageList.map(page => {
          const pageKeys = page.id.split('_')
          return (
            <Link key={page.id} to={`/backstage/${pageKeys[0]}/pages/${pageKeys[1]}`} className={'mr-3 ' + (page.id === pageID ? 'badge badge-light' : 'text-light')}>{page.title}</Link>
          )
        })}
        <Button
          size='sm' color='success' outline
          onClick={() => {
            // eslint-disable-next-line
            const title = prompt('Enter a page title')
            if (!title) return
            const id = `${showID}_${title.toLowerCase().replace(/[^a-z]/g, '')}`
            db.ref(`/staging/shows/${showID}/pages`).set([
              ...pageList,
              { id, title }
            ])
            db.ref(`/staging/pages/${id}`).set({
              id,
              title,
              text: ''
            })
          }}
        >+ New Page
        </Button>
      </Navbar>

      <div className='container'>
        {view === 'text' &&
          <>
            <div className='d-flex align-items-center'>
              <h3 className='mr-auto'>Text</h3>
              {page.html && <span className='text-danger'>This text is being overridden by the HTML set for this page.</span>}
            </div>
            <textarea
              value={page.text}
              onChange={event => dbPageRef.update({ text: event.target.value })}
              className={page.html ? 'border-danger' : ''}
              style={{
                width: '100%',
                height: '80vh'
              }}
            />
          </>}

        {view === 'html' &&
          <>
            <div className='d-flex align-items-center'>
              <h3 className='mr-auto'>Code</h3>
              <Button size='sm' onClick={() => setHTML(page.html)}>Load</Button>
              <Button
                size='sm' onClick={() => {
                  if (!html.trim()) return
                  dbPageRef.update({ html })
                }}
              >Save
              </Button>
            </div>
            <textarea
              value={html}
              onChange={event => setHTML(event.target.value)}
              className='text-monospace small'
              style={{
                width: '100%',
                height: '80vh'
              }}
            />
          </>}

        {view === 'preview' &&
          <ShowPageContent page={page} />}
      </div>
    </>
  )
}
