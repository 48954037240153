import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import Card from '../components/Card'
import { Row } from '../components/layout'
import Navbar from '../components/Navbar'
import auth from '../utils/auth'
import db from '../utils/db'

export default function Backstage (props) {
  const [shows, setShows] = useState({})
  useEffect(() => {
    return db.subscribe('Backstage_shows', '/staging/shows', setShows)
  }, [])

  return (
    <>
      <Navbar back='/' title='Dream Project' logout className='' />
      <div className='container'>
        <Row className='row-cols-3'>

          <Card
            title='Shows'
            listGroup={Object.values(shows).filter(show => show.status !== 'hidden').map(show => ({
              link: '/backstage/' + show.id,
              text: show.title
            }))}
          >
            {auth.hasRights('admin') &&
              <Button
                color='success'
                outline
                size='sm'
                className='mt-3'
                onClick={() => {
                  const showID = window.prompt('Enter show ID')
                  if (!showID) return
                  db.ref(`/staging/shows/${showID}`).update({
                    id: showID,
                    status: 'hidden',
                    title: showID,
                    titleShort: showID,
                    summary: '',
                    posterImg: `show-postcard-${showID}.png`,
                    v: 0,
                    scenes: [
                      `${showID}_1-1`
                    ],
                    editorViews: {
                      import: true,
                      freeform: true,
                      text: true,
                      preflight: true,
                      preview: true
                    }
                  })
                }}
              >
                + Create Show
              </Button>}
          </Card>

          <Card title='Analytics' subtitle='Coming soon.' link={{ to: '/backstage/analytics', text: 'View analytics' }} />

          <Card title='Preview'>
            <p className='mt-3'>Browse the upcoming version of the site. Changes you make in the editor appear in realtime.</p>
            <a href='/preview' className='btn btn-info' target='_blank'>
              Open preview site
            </a>
          </Card>

          <Card title='Database' subtitle='Run manual database operations.' link={{ to: '/backstage/admin/database', text: 'Open database manager' }} />

        </Row>
      </div>
    </>
  )
}
