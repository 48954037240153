import React, { useEffect, useState } from 'react'
import Button from '../components/Button'
import Card from '../components/Card'
import { Row } from '../components/layout'
import Navbar from '../components/Navbar'
import analytics from '../utils/analytics'
import auth from '../utils/auth'
import db from '../utils/db'

const publishMessages = {
  staging: "This show is currently in staging and can't be published yet.",
  ready: 'This show is ready to publish for the first time.',
  live: 'This show is currently live.',
  admin: "This show is in staging - make sure you're ready before publishing."
}

export default function BackstageShow ({ match }) {
  const showID = match.params.show

  const [show, setShow] = useState({ loading: true })
  useEffect(() => {
    return db.subscribe('BackstageShow_show', `/staging/shows/${showID}`, setShow)
  }, [showID])

  const [liveShow, setLiveShow] = useState(null)
  useEffect(() => {
    return db.subscribe('BackstageShow_liveShow', `/live/shows/${showID}`, setLiveShow)
  }, [showID])

  const [publishConfirm, setPublishConfirm] = useState(false)
  const publish = () => {
    setPublishConfirm(false)
    analytics.event.publish(showID)
    const lastPublished = new Date().toISOString()
    db.ref(`/live/shows/${showID}`).set({ ...show, lastPublished })
    db.ref(`/staging/shows/${showID}`).update({ lastPublished })
    db.ref('/staging/scenes').once('value', snap => {
      const data = snap.val()
      const out = {}
      console.log(show.scenes, data)
      show.scenes.forEach(sceneID => {
        console.log('scene', sceneID, data[sceneID])
        out[sceneID] = data[sceneID]
      })
      db.ref('/live/scenes').update(out)
    })
    // db.ref('/staging/pages').once('value', snap => {
    //   const data = snap.val()
    //   const out = {}
    //   console.log(show.pages, data)
    //   show.pages.forEach(page => {
    //     console.log('page', page, data[page.id])
    //     out[page.id] = data[page.id]
    //   })
    //   db.ref('/live/pages').update(out)
    // })
  }

  let publishStatus = 'staging'
  if (liveShow) publishStatus = 'live'
  else if (show.status === 'ready') publishStatus = 'ready'
  else if (auth.hasRights('admin')) publishStatus = 'admin'

  if (!show || show.loading) {
    return (
      <>
        <Navbar back='/backstage' />
        <div className='container'>
          <Row className='row-cols-3'>
            <Card title='Loading...' subtitle={showID} />
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <Navbar back='/backstage' title={show.title} logout />
      <div className='container'>
        <Row className='row-cols-3'>

          <Card
            title='Show'
            subtitle='Lines, notes, and more by scene'
            link={{ to: showID + '/1-1/notes', text: 'Edit show' }}
          />

          <Card title='Pages'>
            <span className='font-italic text-muted'>
              The pages editor has been deprecated. Please submit content directly.
              {auth.hasRights('admin') && ' To view anyway, visit /pages.'}
            </span>
          </Card>

          {auth.hasRights('admin') &&
            <Card
              title='Preflight'
              subtitle='Overview of detected issues'
              link={{ to: showID + '/preflight', text: 'View report' }}
            />}

          <Card
            title='Analytics'
            subtitle='View stats and more'
            link={{ to: showID + '/analytics', text: 'View analytics' }}
          />

          <Card title='Preview'>
            <p className='mt-3'>Browse the upcoming version of the site. Changes you make in the editor appear in realtime.</p>
            <a href={'/preview/' + showID} className='btn btn-info' rel='noopener noreferrer' target='_blank'>
              Open preview site
            </a>
          </Card>

          <Card title='Publish' subtitle='Deploy staged content to the live site'>
            <p>
              {publishConfirm
                ? "Publish this show? All changes will be applied immediately to the live site and can't be automatically undone."
                : publishMessages[publishStatus]}
              <br />
              <small>Last published: {
                !liveShow
                  ? '——'
                  : new Date(liveShow.lastPublished).toLocaleString()
              }
              </small>
            </p>
            {publishConfirm && <Button color='secondary' className='mr-2' onClick={() => setPublishConfirm(false)}>Cancel</Button>}
            <Button
              disabled={publishStatus === 'staging'} color='dark' onClick={() => {
                if (publishConfirm) {
                  publish()
                } else {
                  setPublishConfirm(true)
                }
              }}
            >Publish Now
            </Button>
          </Card>

          {auth.hasRights('admin') &&
            <Card>
              <a className='stretched-link' href={`https://console.firebase.google.com/u/0/project/syt-dream-project/database/syt-dream-project/data/~2Fstaging~2Fshows~2F${showID}?consoleUI=FIREBASE`}>
              View in Firebase
              </a>
            </Card>}

        </Row>
      </div>
    </>
  )
}
