import React, { useState } from 'react'
import Button from './Button'
import '../styles/EditorTextBlock.css'

export default function EditorTextBlock ({ block, index, dbRef, onAdd, onRemove, ...props }) {
  let textField = null

  const [confirm, setConfirm] = useState(null)

  if (block.dir) {
    textField = (
      <input
        {...props}
        className='form-control font-italic my-2 w-75'
        value={block.dir}
        onChange={event => {
          dbRef.update({
            dir: event.target.value
          })
        }}
      />
    )
  } else {
    const lines = [block.voice, ...(block.lines || ['ERROR: Block has no lines']).map(line => {
      if (line.dir) return '>' + line.dir
      return line
    })]
    textField = (
      <textarea
        {...props}
        className='form-control my-2 w-75'
        rows={lines.length}
        value={lines.join('\n')}
        onChange={event => {
          const lines = event.target.value.split('\n')
          const voice = lines.shift()
          if (lines.length === 0) lines.push('')
          dbRef.update({
            voice: voice,
            lines: lines.map(line => {
              if (line[0] === '>') return { dir: line.slice(1) || '—' }
              return line
            })
          })
        }}
      />
    )
  }

  let controls = <></>

  if (!confirm) {
    controls = (
      <div className='btn-group btn-group-sm mr-3 mt-2 block-controls'>
        <Button color='danger' outline onClick={() => setConfirm('remove')}>-</Button>
        <Button color='info' outline onClick={() => setConfirm('convert')}>{block.dir ? 'D' : 'T'}</Button>
        <Button color='success' outline onClick={() => setConfirm('add')}>+</Button>
      </div>
    )
  } else if (confirm === 'remove') {
    controls = (
      <div className='btn-group btn-group-sm mr-3 mt-2' onClick={() => setConfirm(null)}>
        <Button color='secondary' outline>✕</Button>
        <Button color='danger' onClick={onRemove}>Delete block{block.notes ? ` & ${block.notes.length} notes` : ''}</Button>
      </div>
    )
  } else if (confirm === 'convert') {
    controls = (
      <div className='btn-group btn-group-sm mr-3 mt-2' onClick={() => setConfirm(null)}>
        <Button color='secondary' outline>✕</Button>
        <Button
          color='warning' onClick={() => {
            if (block.dir) {
              dbRef.set({ voice: 'Voice', lines: [block.dir] })
            } else {
              dbRef.set({ dir: block.lines.join('  ') || '—' })
            }
          }}
        >{block.notes ? `Delete ${block.notes.length} notes & convert ` : 'Convert '}
        to {block.dir ? 'text block' : 'stage dir'}
        </Button>
      </div>
    )
  } else if (confirm === 'add') {
    controls = (
      <div className='btn-group btn-group-sm mr-3 mt-2' onClick={() => setConfirm(null)}>
        <Button color='secondary' outline>✕</Button>
        <Button color='primary' onClick={() => onAdd('voice')}>Lines</Button>
        <Button color='info' onClick={() => onAdd('dir')}>Stage dir</Button>
      </div>
    )
  }

  return (
    <div className='editortextblock'>
      {controls}
      {textField}
      {!confirm &&
        <span className='mt-3 ml-3 text-muted'>#{index}{block.notes && ` (${block.notes.length} notes)`}</span>}
    </div>
  )
}
