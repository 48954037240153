import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Block from './Block'
import Button from './Button'
import { Row, Col } from './layout'
import parseScene from '../utils/parseScene'
import '../styles/EditorFreeform.css'

export default function EditorFreeform ({ scene, text, setText, result, setResult }) {
  const original = parseScene.freeformStringify(scene.blocks)

  const [loadedScene, setLoadedScene] = useState(scene)
  if (scene !== loadedScene) {
    setLoadedScene(scene)
    setText(original)
    setResult({ ...scene })
  }

  if (text === '' && original !== '') {
    setText(original)
    setResult({ ...scene, blocks: parseScene.freeform(original) })
  }

  let blocks = []
  if (result && result.blocks) blocks = result.blocks

  const hasNotes = scene.blocks.some(block => block.notes)
  let noteCount = 0
  if (hasNotes) noteCount = scene.blocks.reduce((acc, block) => acc + (block.notes ? block.notes.length : 0), 0)

  const [warned, setWarned] = useState(false)

  return (
    <div className='editorfreeform'>
      <Row>

        <Col md={6}>
          <textarea
            disabled={hasNotes && !warned}
            value={text}
            onChange={event => {
              setText(event.target.value)
              setResult({ ...scene, blocks: parseScene.freeform(event.target.value) })
            }}
            style={{
              width: '100%',
              height: '100%'
            }}
          />
        </Col>

        <Col md={6}>
          {hasNotes && !warned &&
            <div className='alert alert-danger'>
              <p>
                This freeform editor will remove all <strong>{noteCount}</strong> notes in this scene if you save.
                Use the <Link to='text' onClick={() => setResult(null)}>text editor</Link> to preserve notes when editing the script.
              </p>
              <Link to='text' onClick={() => setResult(null)}><Button color='secondary'>Take me away</Button></Link>
              <Button color='danger' outline className='ml-3' onClick={() => setWarned(true)}>I don't need those {noteCount} notes</Button>
            </div>}
          {blocks.map((block, i) => {
            if (block.dir) {
              return <p key={i + 'd'} className='stagedir'>{block.dir}</p>
            } else {
              return (
                <Block key={i + 'b'} block={block} noteMode='hide' />
              )
            }
          })}
        </Col>

      </Row>
    </div>
  )
}
