import React from 'react'
import { Link } from 'react-router-dom'
import NavbarPublic from '../components/NavbarPublic'
import PageContent from '../components/PageContent'
import PageFooter from '../components/PageFooter'
import { Row, Col } from '../components/layout'
import shows from '../data/shows'
import urls from '../data/urls'
import iconGo from '../assets/icon-go.svg'
import '../styles/Home.css'
import auth from '../utils/auth'

const Divider = ({ children }) => (
  <div className='d-flex align-items-center mb-3 mb-xl-4 px-2 px-lg-5'>
    <hr className='flex-grow-1' style={{ borderColor: '#ccc' }} />
    <h4 className='font-italic text-muted my-0 mx-2 mx-lg-3 font-serif'>{children}</h4>
    <hr className='flex-grow-1' style={{ borderColor: '#ccc' }} />
  </div>
)

const ShowRow = ({ show, isPreview, flip, link }) => (
  <PageContent
    img={urls.asset(show.promoImg || `show-promo-${show.id}.jpg`)}
    alt={show.title}
    className='position-relative'
    flip={flip}
  >
    {!show.live && link &&
      <span className='small text-warning font-weight-bold' style={{ letterSpacing: '0.1em' }}>EDITOR PREVIEW</span>}
    {show.callout &&
      <span className='small text-info font-weight-bold' style={{ letterSpacing: '0.1em' }}>{show.callout.toUpperCase()}</span>}
    <h2 className='font-serif'>{show.title}</h2>
    <p style={show.summaryStyle}>{show.summary}</p>
    {link &&
      <div className='golink'>
        <Link
          to={(isPreview ? '/preview/' : '/') + show.id}
          className='stretched-link'
          style={{ fontSize: '1.2em' }}
        >
          Explore
        </Link>
        <img src={iconGo} alt='' />
      </div>}
  </PageContent>
)

export default function Home ({ match, location }) {
  const isPreview = auth.hasRights('preview')

  const flip = false

  return (
    <>
      <NavbarPublic />

      <div className='container my-3 my-lg-5'>

        <h2 className='font-serif mb-5'>All Shows</h2>

        {Object.values(shows).filter(show => show.live || (isPreview && show.staging)).map(show => {
          // flip = !flip
          return <ShowRow key={show.id} link {...{ show, isPreview, flip }} />
        })}

        <Row className='mb-3 mb-xl-5'>
          <Col lg={8} className='mx-auto'>
            <div
              className='p-3 rounded-lg d-flex flex-column align-items-start flex-md-row align-items-md-center'
              style={{ background: 'rgb(212, 244, 220)', border: '1px solid rgb(211, 233, 216)', fontSize: '1.1em' }}
            >
              <div className='flex-shrink-1 mr-md-2 mb-2 mb-md-0'><b>Join Shakespearean Youth Theatre!</b> Registration is now open for spring workshops and summer camps in Saint Paul, MN.</div>
              <a href='https://www.sytmn.org/classes' target='_blank' rel='noopener noreferrer' className='btn btn-success flex-shrink-0'>Learn More</a>
            </div>
          </Col>
        </Row>

        <Divider>And coming soon...</Divider>

        {Object.values(shows).filter(show => !(show.live || (isPreview && show.staging))).reverse().map(show => {
          // flip = !flip
          return <ShowRow key={show.id} {...{ show, isPreview, flip }} />
        })}
      </div>

      <PageFooter location={location} />
    </>
  )
}
