import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import { Row, Col } from '../components/layout'
import Navbar from '../components/Navbar'
import analyticsCalc from '../utils/analyticsCalc'

const views = [
  { id: 'views', title: 'Show Views', calcKey: 'views' },
  { id: 'editors', title: 'Editors', calcKey: 'editors' }
]

export default function AnalyticsProcess ({ match }) {
  const currentView = views.find(v => v.id === (match.params.view)) || views[0]

  const [results, setResults] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Navbar back='/backstage/analytics' title={'Analytics - ' + currentView.title} />
      <div className='container'>
        <Row>

          <Col width={4}>
            <ul className='list-group'>
              {views.map(view => (
                <Link
                  key={view.id}
                  to={'/backstage/analytics/process/' + view.id}
                  role='button'
                  className={'list-group-item list-group-item-action' + (view === currentView ? ' active' : '')}
                >
                  {view.title}
                </Link>
              ))}
            </ul>
          </Col>

          <Col width={8}>
            {results &&
              <Button color='success' onClick={() => analyticsCalc.save[currentView.calcKey](results)}>Save</Button>}
            {error
              ? <pre className='text-danger'>{error.toString()}</pre>
              : results
                ? <pre className='small'>{JSON.stringify(results, null, 2)}</pre>
                : loading
                  ? <Button className='mt-5 mx-auto' outline disabled>Loading...</Button>
                  : (
                    <Button
                      className='mt-5 mx-auto' onClick={() => {
                        setLoading(true)
                        analyticsCalc[currentView.calcKey]().then(res => {
                          setResults(res)
                          setLoading(false)
                        }).catch(err => {
                          setError(err)
                          setLoading(false)
                        })
                      }}
                    >Activate
                    </Button>)}
          </Col>

        </Row>
      </div>
    </>
  )
}
