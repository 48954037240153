import React from 'react'
// import Content from './Content'
// import db from '../utils/db'

export default function ShowPageContent ({ pageID, preview }) {
  return <></>

  // return <Content id={pageID} />

  /*
  const [page, setPage] = useState({ loading: true })
  useEffect(() => {
    return db.subscribe('page', `/${preview ? 'staging' : 'live'}/pages/${pageID}`, setPage)
  }, [preview, pageID, setPage])

  if (!page) {
    return (
      <h2>Page not found.</h2>
    )
  }

  if (page.loading) {
    return (
      <></>
    )
  }

  if (page.html) {
    return <div dangerouslySetInnerHTML={{ __html: page.html }} />
  }

  return (
    <>
      <h2>{page.title}</h2>
      {page.text.split('\n').map(s => s.trim()).filter(s => s !== '').map((s, i) => <p key={i}>{s}</p>)}
    </>
  )
  */
}
