import React, { useState, useEffect, useRef } from 'react'
import Block from './Block'
import ErrorBoundary from './ErrorBoundary'
import { Col } from './layout'
import sceneTitle from '../utils/sceneTitle'
import scroller from '../utils/scroller'
import '../styles/Script.css'
import Content from './Content'

export default function Script ({ col, showID, scene, track, noteMode = 'show', play, noteSettings, userNotes, dbUserNotesRef, setBusy, ...props }) {
  const [currentLine, setCurrentLine] = useState(-1)
  const [sceneForCurrentLine, setSceneForCurrentLine] = useState(scene.id)
  const [currentBlock, setCurrentBlock] = useState(-1)
  if (track && scene.id !== sceneForCurrentLine) {
    setCurrentLine(track.currentLine)
    setCurrentBlock(-1)
    setSceneForCurrentLine(scene.id)
  }

  const scrollpointRef = useRef(null)

  useEffect(() => {
    if (!track) return
    return track.onLineChange(val => {
      setCurrentLine(val)
      if (scrollpointRef.current && track.playing) scroller.scrollTo(scrollpointRef.current)
    })
  }, [track, setCurrentLine])

  useEffect(() => {
    const handleKeyDown = event => {
      const actions = {
        ArrowRight: () => {
          track.line = Math.max(track.line + 1, 1)
        },
        ArrowLeft: () => {
          track.line = track.line - 1
        }
      }
      if (actions[event.code]) {
        event.preventDefault()
        actions[event.code]()
      }
    }
    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [currentBlock, track])

  let blockLineTally = -1
  const blockLines = scene.blocks.map(block => {
    if (block.lines) blockLineTally += block.lines.filter(l => !l.dir).length
    return blockLineTally
  })
  blockLines.pop()
  blockLines.unshift(0)

  const userNoteBlocks = {}
  if (userNotes) {
    userNotes.forEach(note => {
      const b = note.block
      if (!userNoteBlocks[b]) {
        userNoteBlocks[b] = [note]
      } else {
        userNoteBlocks[b].push(note)
      }
    })
  }

  return (
    <>
      <Col {...col} className='script ml-auto'>
        {scene.headerContent &&
          <div className='d-flex align-items-center justify-content-center' style={{ minHeight: '40vh', marginBottom: '10vh' }}>
            <Content showID={showID} pageID={scene.headerContent} />
          </div>}
        {!scene.fake &&
          <h2>{sceneTitle(scene.id)}</h2>}
        {scene.blocks.map((block, i) => {
          if (block.dir) {
            return <p key={i + 'd' + scene.id} className='stagedir'>{block.dir}</p>
          } else {
            return (
              <ErrorBoundary key={i + 'b' + scene.id}>
                <Block
                  block={block}
                  blockIndex={i}
                  lineIndex={blockLines[i]}
                  currentLine={currentLine}
                  currentBlock={currentBlock === i}
                  track={track}
                  scrollpointRef={scrollpointRef}
                  noteIDPrefix={`${scene.id}-${i}`}
                  noteMode={noteMode}
                  noteSettings={noteSettings}
                  userNotes={userNoteBlocks[i]}
                  dbUserNotesRef={dbUserNotesRef}
                  setBusy={setBusy}
                  play={play}
                />
              </ErrorBoundary>
            )
          }
        })}
      </Col>
    </>
  )
}
