import React, { useEffect, useState } from 'react'

export default function Input ({ area, type, className = '', value, onChange, debounce, dbRef, nullable, onBlur, ...props }) {
  const [buffer, setBuffer] = useState(value)
  useEffect(() => {
    setBuffer(value)
  }, [value])

  const useBuffer = debounce || dbRef

  let handleChange = e => onChange(e.target.value)
  let commitChange = onBlur || (() => {})

  if (useBuffer) {
    handleChange = e => setBuffer(e.target.value)

    commitChange = () => {
      if (onChange) onChange(buffer)
      if (onBlur) onBlur()
      if (dbRef) dbRef.set(buffer || (nullable ? null : ''))
    }
  }

  const allProps = {
    ...props,
    type: type || 'text',
    className: 'form-control ' + className,
    value: useBuffer ? buffer : value,
    onChange: handleChange,
    onBlur: commitChange
  }

  return (
    area
      ? <textarea {...allProps} />
      : <input {...allProps} />
  )
}
