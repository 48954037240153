import React from 'react'
import { Link } from 'react-router-dom'
import urls from '../data/urls'
import analytics from '../utils/analytics'

export default function PageShowOverview ({ localShow, preview }) {
  return (
    <>
      <div className='row justify-content-center'>
        <div className='col-8 col-md-4 order-md-2 mb-3 d-flex flex-column justify-content-center'>
          {localShow.posterImg && <img src={'/assets/' + localShow.posterImg} className='img-fluid' alt={localShow.title + ' poster'} />}
        </div>
        <div className='col-12 col-md-8 order-md-1 d-flex flex-column justify-content-center align-items-start' style={{ fontSize: '1.2em' }}>
          <span className='text-muted font-weight-bold small' style={{ letterSpacing: '.2em' }}>SYT PRESENTS</span>
          <h1 className='font-serif'>{localShow.title}</h1>
          <p>{localShow.summary}</p>
          <Link to={`/${preview ? 'preview/' : ''}${localShow.id}/play`} className='btn btn-info btn-lg'>
            {localShow.startButton || 'Start the show'}
          </Link>
        </div>
      </div>
      <div className='row justify-content-center mt-3 mt-xl-5'>
        <div className='col-12 col-md-10 col-xl-8 p-3 p-xl-4' style={{ background: 'white', borderRadius: '1em' }}>
          <div className='pl-3' style={{ background: 'sepia', borderLeft: '4px solid var(--yellow)', borderRadius: '2px', fontSize: '.95em' }}>
            <h5 className='font-weight-bold'>Gift your ticket!</h5>
            <p className='mb-2'>We are asking everyone who is able to make a donation of any size to support this work today.</p>
            <p><i>{localShow.title}</i> is shared free for all to enjoy. Support this project and future SYT programming by donating the average cost of a ticket, and share with your friends and family!</p>
            <a
              href={urls.donateCTA}
              target='_blank'
              rel='noopener noreferrer'
              className='btn btn-warning font-weight-bold mb-3'
              onClick={() => analytics.event.donate(localShow.id, 'showGiftButton')}
            >Make my gift
            </a>
            <p className='mb-0'>Art happens when communities come together to make it happen. From donations to standing ovations, you make Shakespearean Youth Theatre possible. Thank you for being part of the community!</p>
          </div>
        </div>
      </div>
    </>
  )
}
