import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import Navbar from '../components/Navbar'
import { logging } from '../utils/analytics'
import auth from '../utils/auth'

const colors = {
  error: 'danger',
  warn: 'warning',
  info: 'primary',
  use: 'info'
}

const hiddenKeys = ['an', 'userAgent']

export default function Log () {
  const [log, setLog] = useState([])
  useEffect(() => {
    return logging.subscribe(setLog)
  }, [setLog])

  const [filters, setFilters] = useState([])

  const [deleteMode, setDeleteMode] = useState(false)
  const hideFunc = deleteMode ? logging.remove : logging.hide

  const isAdmin = auth.hasRights('admin')

  // console.log(log)

  return (
    <>
      <Navbar back='/backstage/analytics' title='Event Log'>
        {isAdmin &&
          <Button className='ml-auto' size='sm' color='danger' outline={!deleteMode} onClick={() => setDeleteMode(!deleteMode)}>Delete Mode</Button>}
      </Navbar>
      <div className='container'>
        <div className='btn-group btn-group-sm mb-3'>
          {Object.keys(logging.events).map(key => (
            <Button
              key={key}
              color={colors[logging.events[key].level]}
              outline={filters.includes(key)}
              onClick={() => {
                if (filters.includes(key)) {
                  setFilters(filters.filter(f => f !== key))
                } else {
                  setFilters([...filters, key])
                }
              }}
            >{key}
            </Button>
          ))}
        </div>
        {log.filter(entry => !filters.includes(entry.event)).map(entry => {
          // const title = <span className={'d-block font-weight-bold text-' + colors[entry.level]}>{entry.title} ({entry.data.an})</span>
          return (
            <div key={entry.id} className='border-bottom py-1'>
              <span className='d-block float-right small' role='button' style={{ color: '#ccc' }} onClick={() => hideFunc(entry.id)}>&times;</span>
              <span className={'d-block font-weight-bold text-' + colors[entry.level]}>
                {entry.title} {isAdmin && <Link to={`/backstage/analytics/users/${entry.data.an}`} className='text-reset'>({entry.data.an})</Link>}
                <span className='small text-muted ml-1'>{new Date(entry.date).toString()}</span>
              </span>

              {Object.keys(entry.data).filter(key => !hiddenKeys.includes(key)).map(key => key + ': ' + entry.data[key]).join(', ')}
            </div>
          )
        })}
      </div>
    </>
  )
}
