import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import LinkOut from './LinkOut'
import Wordmark from './Wordmark'
import menuIcon from '../assets/icon-menu.svg'
import userIcon from '../assets/icon-user.svg'
import userDropIcon from '../assets/icon-user-drop.svg'
import '../styles/NavbarPublic.css'
import LoginForm from './LoginForm'
import auth, { useUser } from '../utils/auth'
import urls from '../data/urls'

const links = [
  { to: '/', title: 'Home', hideActive: true },
  { to: '/shows', title: 'Shows' }
]

export default function NavbarPublic ({ overlay, background, ...props }) {
  const location = useLocation()
  const user = useUser()

  const [showMenu, setShowMenu] = useState(false)

  const [showLogin, setShowLogin] = useState(false)

  const loginDismiss = () => {
    setShowLogin(false)
  }

  let style = {}
  if (background) {
    style = {
      background: `url(${urls.asset(background)}) center / cover no-repeat, linear-gradient(to right, #7F0F7F, #49139D)`
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className={'navbarPublic py-3' + (overlay ? ' navbarPublic-overlay' : '') + (showMenu ? ' expanded' : '')} style={style}>
      <div className='container navContent d-none d-md-flex'>
        <Wordmark small={!overlay} className='wordmark' />
        {links.map(link => (
          <Link
            key={link.to}
            to={link.to}
            className={(!link.hideActive && location.pathname.startsWith(link.to)) ? 'active' : ''}
          >
            {link.title}
          </Link>
        ))}
        {auth.hasRights('editor') && <Link to='/backstage'>Backstage</Link>}
        <LinkOut preset='donateHome' className='text-warning'>Donate</LinkOut>
        {user
          ? (
            <Link to='/account' title='My Account'>
              <img src={userIcon} alt='My Account' role='button' width='30' height='30' style={{ marginRight: '15px' }} />
            </Link>
          )
          : <img src={userDropIcon} alt='Sign In' role='button' width='45' height='30' onClick={() => setShowLogin(!showLogin)} />}
      </div>

      <div className='container navContent d-md-none'>
        <Wordmark small className='wordmark' />
        <img src={menuIcon} alt='Menu' role='button' width='40' height='40' onClick={() => setShowMenu(!showMenu)} />
      </div>

      <div className='container navContentMobile d-md-none'>
        {links.map(link => (
          <Link
            key={link.to}
            to={link.to}
            className={(!link.hideActive && location.pathname.startsWith(link.to)) ? ' active' : ''}
          >
            {link.title}
          </Link>
        ))}
        {auth.hasRights('editor') && <Link to='/backstage'>Backstage</Link>}
        <LinkOut preset='donateHome' className='text-warning'>Donate</LinkOut>
        {user
          ? <Link to='/account'><img src={userIcon} alt='' width='24' height='24' className='ml-n1 mb-1 mr-2' />My Account</Link>
          : <Link to='/account'><img src={userIcon} alt='' width='24' height='24' className='ml-n1 mb-1 mr-2' />Sign In</Link>}
      </div>

      {showLogin &&
        <div className={'navbar-login p-3' + (overlay ? ' navbar-login-overlay' : '')}>
          <LoginForm
            displayCreatePromo
            onSignIn={loginDismiss}
            onCreateAccount={loginDismiss}
          />
        </div>}

      {overlay && <div className='mobileBackground d-md-none' />}
    </div>
  )
}
