import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/Button'
import auth from '../utils/auth'
import backIcon from '../assets/icon-back.svg'

export function BackArrow ({ link, mr, className = '' }) {
  return (
    <Link to={link} className={className + (mr ? ' mr-auto' : ' mr-2')}>
      <img src={backIcon} alt='back' />
    </Link>
  )
}

export default function Navbar ({ back, title, mr, logout, className = '', children }) {
  return (
    <nav className={'mb-3 p-3 bg-dark text-light sticky-top d-flex align-items-center ' + className}>
      {back && <BackArrow link={back} mr={mr} />}
      {title && <h1 className={'h5 m-0 ' + (mr ? ' mr-auto' : ' mr-2')}>{title}</h1>}
      {children}
      {logout &&
        <>
          <span className='d-block small text-muted mr-2 ml-auto'>{auth.currentRights()}</span>
          <Button size='sm' color='secondary' outline onClick={() => auth.logout()}>Log Out</Button>
        </>}
    </nav>
  )
}
