import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Button from './Button'
import { Row, Col } from './layout'
import urls from '../data/urls'
import analytics from '../utils/analytics'
import auth from '../utils/auth'
import grantLogoCWLLA from '../assets/grant-cwlla.png'
import grantLogoMRAC from '../assets/grant-mrac.svg'
import socialInstagram from '../assets/icon-social-instagram.svg'
import socialFacebook from '../assets/icon-social-facebook.svg'
import socialTwitter from '../assets/icon-social-twitter.svg'
import '../styles/PageFooter.css'

export default function PageFooter () {
  const location = useLocation()

  const preview = location.pathname.slice(0, 8) === '/preview'

  return (
    <footer>
      <div className='container'>
        {auth.hasRights('preview') &&
          <Row className='mb-3 mb-xl-5 mx-0 py-3 previewBanner'>
            <Col className='d-flex flex-column align-items-start flex-md-row align-items-md-center'>
              <div className='btn-group btn-group-sm mb-2 mb-md-0 mr-md-3'>
                <Button color='secondary' outline={preview} link={preview && (location.pathname.slice(8) || '/')}>Live</Button>
                <Button color='info' outline={!preview} link={!preview && '/preview' + location.pathname}>Preview</Button>
              </div>
              <span className='mr-auto'>You’re signed into the Dream Project with <b>{auth.currentRights()}</b> access. {analytics.devMode && 'Analytics are limited by dev mode.'} <Link to='/backstage' className='text-info'>Open Backstage</Link></span>
            </Col>
          </Row>}
        <Row>
          <Col md={6}>
            <h6>A production of Shakespearean Youth Theatre.</h6>
            <p>Learn more about our organization and mission at <a href='https://sytmn.org' target='_blank' rel='noopener noreferrer' className='text-info'>sytmn.org</a>.</p>
            <div className='d-flex mb-1'>
              <a className='socialLink' href={urls.twitter} target='_blank' rel='noopener noreferrer'>
                <img src={socialTwitter} alt='Twitter' />
              </a>
              <a className='socialLink' href={urls.facebook} target='_blank' rel='noopener noreferrer'>
                <img src={socialFacebook} alt='Facebook' />
              </a>
              <a className='socialLink' href={urls.instagram} target='_blank' rel='noopener noreferrer'>
                <img src={socialInstagram} alt='Instagram' />
              </a>
            </div>
            <p>
              550 Vandalia Street, Saint Paul MN<br />
              651-330-5037 — info@sytmn.org
            </p>
          </Col>
          <Col md={6}>
            <p>This activity is made possible by the voters of Minnesota through a grant from the Metropolitan Regional Arts Council, thanks to a legislative appropriation from the arts and cultural heritage fund.</p>
            <div className='d-flex align-items-end'>
              <img src={grantLogoCWLLA} alt='Clean Water, Land and Legacy Amendment' height='100' className='mb-3 mr-3' />
              <img src={grantLogoMRAC} alt='Metro Regional Arts Council' height='75' className='mb-3 mr-3' />
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}
