import React, { useMemo } from 'react'
import '../styles/Graph.css'

export default function Graph ({ data = [], height = '50vh', ...props }) {
  const max = useMemo(() => {
    return data.reduce((acc, cur) => Math.max(acc, cur.val), 2)
  }, [data])

  return (
    <div className='graph-container' {...props}>
      <div className='graph-canvas' style={{ height }}>
        {data.map((point, i) => (
          <div key={i} className='graph-bar' style={{ height: point.val / max * 100 + '%' }} title={`${point.label || '#' + i}: ${point.val}`}>
            <span className='graph-value'>{point.val || ''}</span>
          </div>
        ))}
      </div>
      <div className='graph-axisline-x' />
      <div className='graph-labels'>
        {data[0] && <div>{data[0].label}</div>}
        {data[1] && <div>{data[data.length - 1].label}</div>}
      </div>
    </div>
  )
}
