import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import AccountClassrooms from '../components/AccountClassrooms'
import AccountProfile from '../components/AccountProfile'
import AccountSettings from '../components/AccountSettings'
import Button from '../components/Button'
import Catalog from '../components/Catalog'
import { Col, Row } from '../components/layout'
import NavbarPublic from '../components/NavbarPublic'
import PageFooter from '../components/PageFooter'
import auth, { useUser } from '../utils/auth'
import '../styles/Account.css'

const views = [
  { id: 'profile', title: 'My Profile', component: AccountProfile },
  { id: 'classrooms', title: 'Classrooms', component: AccountClassrooms },
  { id: 'settings', title: 'Settings', component: AccountSettings }
  // { divider: true },
  // { id: 'test', title: 'Test Area', component: AccountTest },
  // { id: 'login', title: 'Login', component: () => <Login className='border rounded-lg p-3 mt-3 bg-white' displayCreatePromo /> }
]

function AccountPlaceholder () {
  return (
    <div className='mt-5 d-flex flex-column align-items-start fadeInDelay'>
      <h3 className='font-serif'>Not signed in.</h3>
      <p className='text-muted' style={{ maxWidth: '35em' }}>Create your free account to add your own notes and highlights as you listen, track your progress through each show, and share notes with others for school or study.</p>
      <Link to='/login' className='btn btn-lg btn-info mt-3 mt-xl-5'>Sign In</Link>
    </div>
  )
}

export default function Account ({ match, ...props }) {
  const view = match.params.view
  const user = useUser()
  const history = useHistory()

  const ViewComponent = user ? views.find(v => v.id === view).component : AccountPlaceholder

  // const [redirectTimeout, setRedirectTimeout] = useState(null)
  // const [redirect, setRedirect] = useState(null)
  // if (!user && !redirectTimeout) {
  //   setRedirectTimeout(setTimeout(() => {
  //     if (!user) {
  //       setRedirect('/login')
  //     }
  //   }), 1000)
  // }
  // if (redirect) {
  //   return <Redirect to={redirect} />
  // }

  return (
    <>
      <NavbarPublic />
      <div className='container my-3 my-lg-5' style={{ minHeight: '50vh' }}>
        <Row>
          <Col md={3}>
            {user &&
              <>
                <Catalog links={views.map(v => ({ to: '/account/' + v.id, title: v.title, divider: v.divider }))} />

                <Button
                  color='secondary'
                  outline
                  size='sm'
                  className='ml-2 mb-5'
                  onClick={() => {
                    auth.fb.signOut().then(() => {
                      history.push('/')
                    }).catch(err => {
                      window.alert('There was a problem signing you out: ' + err.message)
                    })
                  }}
                >Sign Out
                </Button>
              </>}
          </Col>

          <Col md={9}>
            <ViewComponent user={user || { fake: true }} />
          </Col>

        </Row>

        {/* <ButtonGroup>
          <Button color='danger' outline onClick={() => auth.fb.signOut()}>Sign Out</Button>
        </ButtonGroup> */}
      </div>
      <PageFooter />
    </>
  )
}
