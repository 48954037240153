import React, { useMemo, useState } from 'react'
import Button from './Button'
import auth from '../utils/auth'
import { preflightScene, levelColors } from '../utils/preflight'
import '../styles/EditorPreflight.css'

const MsgCard = ({ msg, dbRef, isAdmin }) => {
  const [editState, setEditState] = useState(null) // null, edit, fix
  const [edit, setEdit] = useState(msg.origData || msg.quote)

  return (
    <div className='card'>
      <div className='card-body msg-card-body d-flex flex-column align-items-start rounded-sm position-relative' style={{ borderColor: `var(--${levelColors[msg.level]})` }}>
        <span
          className='small text-muted'
          style={{ position: 'absolute', top: '.5em', right: '.5em' }}
          title={!msg.blockData ? null : (msg.blockData.dir || `${msg.blockData.voice}: ${msg.blockData.lines[0]}`)}
        >
          {msg.block === undefined ? 'SCENE' : '#' + msg.block}
          <span
            role='button'
            style={{ fontSize: '1.5em', marginLeft: '.25em', lineHeight: '.5em' }}
            onClick={() => dbRef.child('/preflightIgnores').push(JSON.stringify(msg))}
          >×
          </span>
        </span>
        <h6 className='card-title mt-0' title={msg.type}>{msg.title}</h6>
        {editState !== 'edit' &&
          <div className={'msg-quote-' + msg.quoteType + (editState === 'fix' && msg.fixedData ? ' msg-quote-fix' : '')}>
            {(editState === 'fix' && msg.fixedData) ? msg.fixedData : msg.quote}
          </div>}
        {editState === 'edit' &&
          <>
            <textarea className='form-control mb-3' rows='2' value={edit} onChange={e => setEdit(e.target.value)} />
            {msg.fixedData && <small>{msg.fixedData}</small>}
          </>}
        {msg.details && <small>{msg.details}</small>}
        <div className='btn-group btn-group-sm ml-auto mb-n1 mr-n1'>
          {editState &&
            <Button color='secondary' outline onClick={() => setEditState(null)}>×</Button>}
          {msg.edit && (!editState || editState === 'edit') &&
            <Button
              color='info' outline={editState !== 'edit'}
              onClick={() => {
                if (editState === 'edit') {
                  msg.edit(edit)
                } else {
                  setEditState('edit')
                }
              }}
            >{editState === 'edit' ? 'Save' : 'Edit'}
            </Button>}
          {msg.fix && (!editState || editState === 'fix') && isAdmin &&
            <Button
              color='success' outline={editState !== 'fix'}
              onClick={() => {
                if (editState === 'fix') {
                  msg.fix()
                } else {
                  setEditState('fix')
                }
              }}
            >Fix
            </Button>}
        </div>
      </div>
    </div>
  )
}

const isAdmin = auth.hasRights('admin')
const defaultFilter = 'typoQuote typoFancy typoFancySus voiceAudience artisanDir voiceMatch'

export default function EditorPreflight ({ show, scene, dbRef }) {
  const [filter, setFilter] = useState(window.localStorage.getItem('preflightFilter') || defaultFilter)
  const filterArr = filter.split(' ')
  const [filterMode, setFilterMode] = useState('exclude')

  const msgs = useMemo(() => {
    return preflightScene(scene, show.chars, dbRef)
  }, [scene, show.chars, dbRef])

  const summary = {}
  if (filterMode === 'summarize') {
    msgs.forEach(msg => {
      if (filterArr.includes(msg.type)) {
        if (!summary[msg.type]) summary[msg.type] = []
        summary[msg.type].push(msg)
      }
    })
  }

  return (
    <>
      <div className='alert alert-warning'>
        This editor is a work in progress. Changes made here can't be undone from this view.
      </div>
      <div className='d-flex'>
        <div className='btn-group mr-3'>
          <Button color='secondary' outline={filterMode !== 'include'} onClick={() => setFilterMode('include')}>Include</Button>
          <Button color='secondary' outline={filterMode !== 'summarize'} onClick={() => setFilterMode('summarize')}>Summarize</Button>
          <Button color='secondary' outline={filterMode !== 'exclude'} onClick={() => setFilterMode('exclude')}>Exclude</Button>
        </div>
        <input
          className='form-control'
          type='text'
          placeholder='Filter...'
          value={filter}
          onChange={e => setFilter(e.target.value)}
          onBlur={() => window.localStorage.setItem('preflightFilter', filter)}
        />
      </div>
      <div className='card-columns mt-3 production'>
        {scene.blocks.length > 0 && msgs.length === 0 &&
          <div className='card'>
            <div className='card-body msg-card-body d-flex flex-column rounded-sm position-relative' style={{ borderColor: 'var(--success)' }}>
              <h6 className='card-title mt-0'>No issues found</h6>
              <small>{scene.blocks.length} blocks checked.</small>
            </div>
          </div>}
        {scene.blocks.length > 0 && msgs.length > 0 && msgs.every(msg => filterArr.includes(msg.type) !== (filterMode === 'include')) &&
          <div className='card'>
            <div className='card-body msg-card-body d-flex flex-column rounded-sm position-relative' style={{ borderColor: 'var(--gray)' }}>
              <h6 className='card-title mt-0'>No issues visible</h6>
              <small>{msgs.length} issues are filtered out.</small>
            </div>
          </div>}

        {!scene.fake && Object.keys(summary).sort().map(type => (
          <div key={type} className='card'>
            <div className='card-body msg-card-body d-flex flex-column rounded-sm position-relative' style={{ borderColor: `var(--${levelColors[summary[type][0].level]})` }}>
              <h6 className='card-title mt-0'>{summary[type][0].title}</h6>
              <div style={{ fontSize: '.7em' }}>
                {summary[type].map(msg => {
                  return (
                    <div key={msg.id} title={msg.quote}>
                    #{msg.block}: {msg.quote}
                    </div>
                  )
                })}
              </div>
              {isAdmin && summary[type].every(msg => msg.fix) &&
                <Button
                  size='sm'
                  color='success'
                  className='mt-1 ml-auto'
                  onClick={() => {
                    summary[type].forEach(msg => msg.fix())
                  }}
                >Fix all
                </Button>}
            </div>
          </div>
        ))}
        {!scene.fake && msgs.filter(msg => filterArr.includes(msg.type) === (filterMode === 'include')).map(msg => (
          <MsgCard key={msg.id} {...{ msg, dbRef, isAdmin }} />
        ))}

        {scene.preflightIgnores &&
          <div className='card'>
            <div className='card-body msg-card-body d-flex flex-column rounded-sm position-relative' style={{ borderColor: 'var(--purple)' }}>
              <h6 className='card-title mt-0'>{Object.keys(scene.preflightIgnores).length} ignored issues</h6>
              {Object.keys(scene.preflightIgnores).map(key => {
                const msg = JSON.parse(scene.preflightIgnores[key])
                return (
                  <span key={key} title={msg.quote} role='button' onClick={() => dbRef.child(`/preflightIgnores/${key}`).remove()}>
                    #{msg.block}: {msg.title}
                  </span>
                )
              })}
              {isAdmin &&
                <Button
                  size='sm'
                  color='warning'
                  outline
                  className='mt-1 mr-auto'
                  onClick={() => dbRef.child('/preflightIgnores').remove()}
                >Unignore all
                </Button>}
            </div>
          </div>}
        {scene.blocks.length === 0 &&
          <div className='card'>
            <div className='card-body msg-card-body d-flex flex-column rounded-sm position-relative' style={{ borderColor: 'var(--secondary)' }}>
              <h6 className='card-title mt-0'>Loading...</h6>
            </div>
          </div>}
      </div>
    </>
  )
}
