import React from 'react'
import { Link } from 'react-router-dom'
import { Col } from './layout'
import urls from '../data/urls'
import '../styles/ViewTrack.css'

export default function ViewTrack ({ show, userHistory = {} }) {
  const style = {
    background: `url(${urls.asset(`navbar-${show.id}.jpg`)}) center / cover no-repeat, var(--purple-gradient)`
  }
  return (
    <>
      <Col width={12} lg={3}>
        <Link to={`/${show.id}`}>
          <img src={urls.asset(show.promoImg)} alt={show.title} className='img-fluid rounded-lg' />
        </Link>
      </Col>
      <Col width={12} lg={9} className='viewTrackRow'>
        <h5 className='font-serif mb-0'>{show.title}</h5>
        <div className='viewTrackLine my-2' style={style}>
          {show.scenes.map(sceneID => (
            <div
              key={sceneID}
              className={'viewTrackCell' + (userHistory[sceneID] ? ' played' : '')}
            />
          ))}
        </div>
        <span>{show.scenes.filter(sceneID => userHistory[sceneID]).length}/{show.scenes.length} scenes played</span>
      </Col>
    </>
  )
}
