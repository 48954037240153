import React from 'react'
import { Link } from 'react-router-dom'
import PageContent from '../components/PageContent'
import PageFooter from '../components/PageFooter'
import { Row, Col } from '../components/layout'
import shows from '../data/shows'
import urls from '../data/urls'
import iconBookplay from '../assets/icon-bookplay.svg'
import iconBulb from '../assets/icon-bulb.svg'
import iconHelppoint from '../assets/icon-helppoint.svg'
import iconGo from '../assets/icon-go.svg'
import '../styles/Home.css'
import NavbarPublic from '../components/NavbarPublic'

const Divider = ({ children }) => (
  <div className='d-flex align-items-center mt-3 mt-xl-5 mb-3 mb-xl-4 px-2 px-lg-5'>
    <hr className='flex-grow-1' style={{ borderColor: '#ccc' }} />
    {children && <h4 className='font-italic text-muted my-0 mx-2 mx-lg-3 font-serif'>{children}</h4>}
    {children && <hr className='flex-grow-1' style={{ borderColor: '#ccc' }} />}
  </div>
)

const ShowRow = ({ show, isPreview, flip, link }) => (
  <PageContent
    img={urls.asset(show.promoImg || `show-promo-${show.id}.jpg`)}
    alt={show.title}
    className='position-relative'
    flip={flip}
  >
    {!show.live && link &&
      <span className='small text-warning font-weight-bold' style={{ letterSpacing: '0.1em' }}>EDITOR PREVIEW</span>}
    {show.callout &&
      <span className='small text-info font-weight-bold' style={{ letterSpacing: '0.1em' }}>{show.callout.toUpperCase()}</span>}
    <h2 className='font-serif'>{show.title}</h2>
    <p style={show.summaryStyle}>{show.summary}</p>
    {link &&
      <div className='golink'>
        <Link
          to={(isPreview ? '/preview/' : '/') + show.id}
          className='stretched-link'
          style={{ fontSize: '1.2em' }}
        >
          Explore
        </Link>
        <img src={iconGo} alt='' />
      </div>}
  </PageContent>
)

export default function Home ({ match, location }) {
  const isPreview = location.pathname.slice(0, 8) === '/preview'

  return (
    <>
      <div className='home-header'>
        <NavbarPublic overlay />
        <img src='/assets/backdrop-home.jpg' alt='Dream Project poster' width='1941' height='647' style={{ width: '100%', height: 'auto' }} />
        {/* <div className='home-header-content'>
          <div className='container h-100 d-flex flex-column text-light'>
            <Wordmark location={location} />
          </div>
        </div> */}
      </div>

      <div className='container my-3 my-lg-5'>
        <Row className='mb-3 mb-xl-5'>
          <Col lg={8} className='mx-auto text-lg-center'>
            <h1 className='font-serif'>Shakespeare for a new generation.</h1>
            <p>Immerse yourself in interactive Podcast Plays, go backstage and gain insights from SYT’s young artists, and discover Shakespeare’s works, reimagined for a new millennium.</p>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className='d-flex'>
              <img src={iconBookplay} alt='' width='30' height='30' className='flex-shrink-0' />
              <div className='ml-2 mt-1 flex-shrink-1'>
                <h5 className='font-serif'>Immersive reading.</h5>
                <p>Listen and read simultaneously. Each engaging audio drama provides a complete script with real-time highlighting.</p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className='d-flex'>
              <img src={iconHelppoint} alt='' width='30' height='30' className='flex-shrink-0' />
              <div className='ml-2 mt-1 flex-shrink-1'>
                <h5 className='font-serif'>Interactive scripts.</h5>
                <p>Click anywhere in the script to define unfamiliar words, begin listening from a specific line, or find scene summaries and character breakdowns.</p>
              </div>
            </div>
          </Col>

          <Col md={4}>
            <div className='d-flex'>
              <img src={iconBulb} alt='' width='30' height='30' className='flex-shrink-0' />
              <div className='ml-2 mt-1 flex-shrink-1'>
                <h5 className='font-serif'>Behind-the-scenes features.</h5>
                <p>Cast members share close readings of key monologues and scenes, explore character relationships and themes in the play, and bring Shakespeare into the 21st century.</p>
              </div>
            </div>
          </Col>
        </Row>

        <Divider>Explore our shows</Divider>

        <ShowRow link show={shows.muchado} />

        <ShowRow link show={shows.winterstale} flip />

        <ShowRow link show={shows.randj} />

        <Row>
          {[shows.tandc, shows.hamlet, shows.midsummer].map(show => (
            <Col key={show.id} width={6} lg={3} className='mb-3'>
              <Link to={(isPreview ? '/preview/' : '/') + show.id}>
                <img
                  src={urls.asset(show.promoImg || `show-promo-${show.id}.jpg`)}
                  alt={show.title}
                  className='img-fluid rounded-lg'
                />
              </Link>
            </Col>
          ))}
          <Col width={6} lg={3} className='mb-3'>
            <Link to='/shows' className='text-decoration-none'>
              <div className='allShows font-serif rounded-lg'>
                <div>
                  See all shows
                </div>
              </div>
            </Link>
          </Col>
        </Row>

        <Divider />

        <PageContent
          img={urls.asset('photo-recording.jpg')}
          alt='Recording a Dream Project show'
          className='position-relative'
        >
          <h2 className='font-serif'>All the world’s a stage.</h2>
          <p>Join Shakespearean Youth Theatre and the Dream Project for unprecedented access to the works of William Shakespeare. Each audiobook is performed by young artists ages 13-19, professionally produced and directed by working artists. SYT’s core of teaching artists provides unparalleled insight into the themes, characters, and language of each audiobook through interactive notes, annotations, and curriculum. Shakespeare, reimagined.</p>
          {/* <div className='golink'>
            <a
              href='https://www.sytmn.org/classes'
              target='_blank'
              rel='noopener noreferrer'
              className='stretched-link'
              style={{ fontSize: '1.2em' }}
            >
                Explore
            </a>
            <img src={iconGo} alt='' />
          </div> */}
        </PageContent>
      </div>

      <PageFooter location={location} />
    </>
  )
}
