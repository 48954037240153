import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col } from './layout'
import iconBack from '../assets/icon-back.png'
import iconMenu from '../assets/icon-menu.svg'
import '../styles/Stage.css'

export default function Stage ({ col, preview, sceneList, showID, sceneID, setSceneID, genericMode, recording, ...props }) {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    if (!sceneList || sceneList.length === 0) return
    setShowMenu(sceneID === sceneList[0].id || sceneID === sceneList[sceneList.length - 1].id)
  }, [sceneID, sceneList])

  const navRef = useRef(null)

  let style = null
  if (genericMode) style = { position: 'fixed', height: '100vh' }

  return (
    <>
      <Col {...col} style={style} className='d-none d-md-block stage-col'>
        <nav className={'d-flex flex-column sticky-top stage-menu' + (showMenu ? ' menu-expand' : '') + (recording ? ' recording' : '')} ref={navRef}>
          <div
            className='menu-cell' role='button' onClick={() => {
              setShowMenu(!showMenu)
              if (showMenu && navRef.current) navRef.current.scrollTop = 0
            }}
          >
            <img src={iconMenu} alt='menu' />
          </div>
          <Link to={`${preview ? '/preview' : ''}/${showID}`} className='scene-cell exit-cell d-flex' role='button' onClick={() => {}}>
            <img src={iconBack} alt='' height='24' />
            Exit
          </Link>
          {sceneList.map(scene => (
            <div
              key={scene.id}
              className={'scene-cell' + (scene.id === sceneID ? ' active' : '')}
              role='button'
              onClick={() => {
                setSceneID(scene.id)
                if (navRef.current) navRef.current.scrollTop = 0
              }}
            >
              {scene.title}
            </div>
          ))}
        </nav>
      </Col>
    </>
  )
}
