import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import Button from '../components/Button'
import Card from '../components/Card'
import Graph from '../components/Graph'
import { Col, Row } from '../components/layout'
import Navbar from '../components/Navbar'
import shows from '../data/shows'
import db from '../utils/db'
const dayjs = require('dayjs')

const viewKeys = [
  { id: 'scenes', title: 'Scene', desc: 'See the number of scenes viewed by people each day.' },
  { id: 'show', title: 'Show', desc: 'See the number of people viewing this show each day.' },
  { id: 'first', title: 'First-Time', desc: 'See the number of people viewing this show for the first time.' }
]

export default function AnalyticsShow ({ match }) {
  const showID = match.params.show
  const history = useHistory()

  const [viewKey, setViewKey] = useState(viewKeys[0])
  const [grouping, setGrouping] = useState('day')

  const [viewData, setViewData] = useState({})
  useEffect(() => {
    return db.subscribe('AnalyticsShow_viewData', `/analytics/calc/views/${showID}`, setViewData)
  }, [showID])

  const manualGraphData = useMemo(() => {
    const daily = []

    const now = dayjs()
    let dateCounter = dayjs(Object.keys(viewData)[0])

    while (dateCounter.isBefore(now, 'days')) {
      const counts = viewData[dateCounter.format('YYYY-MM-DD')]
      daily.push({
        val: counts ? counts[viewKey.id] : 0,
        label: dateCounter.format('ddd, D MMM YYYY')
      })
      dateCounter = dateCounter.add(1, 'days')
    }

    if (grouping === 'day') {
      return daily
    }

    const weekly = []
    for (let i = 0; i < daily.length; i += 7) {
      weekly.push({
        val: daily.slice(i, i + 7).reduce((acc, cur) => acc + cur.val, 0),
        label: daily[i].label
      })
    }

    return weekly
  }, [viewData, viewKey, grouping])

  return (
    <>
      <Navbar back={`/backstage/${showID}`} title={shows[showID].titleShort + ' Analytics'}>
        <select
          className='custom-select custom-select-sm bg-dark text-light w-auto ml-3'
          value={showID}
          onChange={e => {
            history.push(`/backstage/${e.target.value}/analytics`)
          }}
        >
          {Object.keys(shows).filter(id => shows[id].live).map(id => (
            <option key={id} value={id}>{shows[id].titleShort}</option>
          ))}
        </select>
      </Navbar>
      <div className='container'>
        <Row className='row-cols-3'>

          <Card title={shows[showID].titleShort + ' Analytics'}>
            <div className='btn-group mt-3'>
              {viewKeys.map(key => (
                <Button
                  key={key.id}
                  color='secondary'
                  outline={key !== viewKey}
                  onClick={() => setViewKey(key)}
                >{key.title}
                </Button>
              ))}
            </div>
            <p className='small mt-2 mr-5 mb-0'>{viewKey.desc}</p>
            <div className='btn-group mt-3'>
              <Button color='secondary' outline={grouping !== 'day'} onClick={() => setGrouping('day')}>Daily</Button>
              <Button color='secondary' outline={grouping !== 'week'} onClick={() => setGrouping('week')}>Weekly</Button>
            </div>
            {grouping === 'week' && viewKey.id === 'show' &&
              <p className='small mt-2 mr-5 mb-0'>
                Weekly stats are calculated by summing daily stats; numbers may be slightly high.
              </p>}
          </Card>

          <Card title={manualGraphData.reduce((acc, cur) => acc + cur.val, 0) + ' Total Views'} />

        </Row>
        <Row>
          <Col>
            <Card title='Lifetime Views'>
              <Graph data={manualGraphData} height='45vh' className='mt-3' />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}
