import React, { useMemo, useState } from 'react'
import Button from './Button'
import Input from './Input'
import auth from '../utils/auth'
import iconAssignment from '../assets/icon-assignment.svg'
import iconCompletion from '../assets/icon-completion.svg'
import iconDone from '../assets/icon-done.svg'
import iconStickynote from '../assets/icon-stickynote.svg'
import '../styles/Login.css'
import db from '../utils/db'

const errors = {
  emailInvalid: { email: 'Enter your email.' },
  passwordBlank: { password: 'Enter your password.' },
  passwordShort: { password: 'Your password must be at least 6 characters.' },
  passwordCommon: { password: 'Your password must be at least 6 characters (and harder to guess than that). Try adding numbers or symbols.' },
  nameBlank: { name: 'Enter a name your teacher will recognize.' },
  'auth/email-already-in-use': { email: 'There’s already an account with this email.' },
  'auth/internal-error': { general: 'Our server is having trouble signing you in. Try again in a minute.' },
  'auth/phone-number-already-exists': { email: 'This phone number is already connected to an account here.' },
  'auth/user-not-found': { email: 'There’s no account here with this email.' },
  'auth/wrong-password': { password: 'This password is incorrect.' }
}
errors['auth/invalid-email'] = errors.emailInvalid
errors['auth/weak-password'] = errors.passwordShort

const validateEmail = str => str && str.includes('@')
const validatePassword = str => str && str.length >= 6
const scanPassword = (str, more = []) => str && (commonPasswords.includes(str) || more.includes(str))

const commonPasswords = [
  '123456', 'password', '12345678', 'qwerty', '123456789', '111111', '1234567', 'dragon', '123123', 'baseball',
  'abc123', 'football', 'monkey', 'letmein', '696969', 'shadow', 'master', '666666', 'qwertyuiop', '123321',
  'mustang', '1234567890', 'michael', '654321', 'superman', '1qaz2wsx', '7777777', 'fuckyou', '121212',
  '000000', 'qazwsx', '123qwe', 'killer', 'trustno1', 'jordan', 'jennifer', 'zxcvbnm', 'asdfgh', 'hunter',
  'buster', 'soccer', 'harley', 'batman', 'andrew', 'tigger', 'sunshine', 'iloveyou', 'fuckme', 'charlie',
  'robert', 'thomas', 'hockey', 'ranger', 'daniel', 'starwars', 'klaster', '112233', 'george', 'asshole',
  'computer', 'michelle', 'jessica', 'pepper', 'zxcvbn', '555555', '11111111', '131313', 'freedom', '777777',
  'maggie', '159753', 'aaaaaa', 'ginger', 'princess', 'joshua', 'cheese', 'amanda', 'summer', 'ashley',
  'nicole', 'chelsea', 'biteme', 'matthew', 'access', 'yankees', '987654321', 'dallas', 'austin', 'thunder',
  'taylor', 'matrix', 'minecraft', 'hunter2', 'correcthorsebatterystaple',
  'theater', 'theatre', 'shakespeare', 'shakes', 'dreamproject', 'interfere16', 'interfere', 'prosper', 'school', 'college',
  'winter', 'winterstale', 'troilus', 'cressida', 'hamlet', 'macbeth', 'tempest', 'kinglear', 'midsummer', 'dreams',
  'juliet', 'muchado', 'nothing', 'asyoulikeit'
]

export default function ({ allowCreate = true, onSignIn = () => {}, onCreateAccount = () => {}, compact, className, ...props }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordFocused, setPasswordFocused] = useState(false)
  const [passAgain, setPassAgain] = useState('')
  const [passAgainFocused, setPassAgainFocused] = useState(false)
  const [name, setName] = useState('')
  const [showClassroomCode, setShowClassroomCode] = useState(false)
  const [classroomCode, setClassroomCode] = useState('')
  const [createMode, setCreateMode] = useState(false)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)
  const [msgs, setMsgs] = useState({})

  const moreCommonPasswords = useMemo(() => {
    return [email, email.split('@')[0], ...name.split(' ').map(s => s.toLowerCase())].filter(s => s)
  }, [email, name])

  const signIn = () => {
    if (!validateEmail(email)) {
      setMsgs(errors.emailInvalid)
      return
    }
    if (!password || password === '') {
      setMsgs(errors.passwordBlank)
      return
    }
    setMsgs({})
    setLoading(true)
    auth.fb.signInWithEmailAndPassword(email, password).then(user => {
      setLoading(false)
      setDone(true)
      setTimeout(() => onSignIn(user), 1000)
    }).catch(err => {
      setLoading(false)
      console.error('Error signing in:', err)
      if (errors[err.code]) {
        setMsgs(errors[err.code])
      } else {
        setMsgs({ generic: `There was an issue signing you in. (${err.message})` })
      }
    })
  }
  const createAccount = () => {
    if (!validateEmail(email)) {
      setMsgs(errors.emailInvalid)
      return
    }
    if (!password || password === '') {
      setMsgs(errors.passwordBlank)
      return
    }
    if (!validatePassword(password)) {
      setMsgs(errors.passwordShort)
      return
    }
    if (scanPassword(password, moreCommonPasswords)) {
      setMsgs(errors.passwordCommon)
      return
    }
    if (password !== passAgain) {
      return
    }
    if ((!name || name === '') && classroomCode) {
      setMsgs(errors.nameBlank)
      return
    }
    setMsgs({})
    setLoading(true)
    auth.fb.createUserWithEmailAndPassword(email, password).then(user => {
      db.ref(`/users/${user.uid}`.set({
        id: user.uid,
        email: user.email,
        name: user.displayName,
        created: new Date()
      })).then(() => {
        setLoading(false)
        setDone(true)
        setTimeout(() => onCreateAccount(user), 1000)
      })
    }).catch(err => {
      setLoading(false)
      console.error('Error creating account:', err)
      if (errors[err.code]) {
        setMsgs(errors[err.code])
      } else {
        setMsgs({ generic: `There was an issue creating your account. (${err.message})` })
      }
    })
  }

  return (
    <form
      className={'loginForm ' + className + (done ? ' done' : '')}
      onSubmit={event => {
        event.preventDefault()
        if (createMode) {
          createAccount()
        } else {
          signIn()
        }
      }}
      {...props}
    >

      <Input
        value={email}
        onChange={setEmail}
        onBlur={() => {
          if (msgs === errors.emailInvalid && email.includes('@')) {
            setMsgs({})
          }
        }}
        className={msgs.email ? 'fieldError' : ''}
        placeholder='Email'
        type='email'
        autoFocus
      />
      {msgs.email &&
        <p className='ml-2 errorMsg'>{msgs.email}</p>}

      <Input
        value={password}
        onChange={val => {
          setPassword(val)
          if (createMode && val && val.length >= 6 && scanPassword(val, moreCommonPasswords)) {
            setMsgs(errors.passwordCommon)
          } else if ((msgs === errors.passwordShort && val.length >= 6) || msgs === errors.passwordCommon) {
            setMsgs({})
          }
        }}
        onFocus={() => setPasswordFocused(true)}
        onBlur={() => setPasswordFocused(false)}
        className={'mt-2' + (msgs.password ? ' fieldError' : createMode && passwordFocused && validatePassword(password) ? ' fieldSuccess' : '')}
        placeholder='Password'
        type='password'
      />
      {msgs.password
        ? <p className='ml-2 errorMsg'>{msgs.password}</p>
        : createMode && passwordFocused &&
          <p className='ml-2 errorMsg benign'>{errors.passwordShort.password}</p>}

      {createMode &&
        <>
          <Input
            value={passAgain}
            onChange={setPassAgain}
            onFocus={() => setPassAgainFocused(true)}
            onBlur={() => setPassAgainFocused(false)}
            className={
              'mt-2' +
              (!passAgainFocused && passAgain.length > 0 && password !== passAgain ? ' fieldError' : '') +
              (passAgainFocused && passAgain.length > 0 && password === passAgain ? ' fieldSuccess' : '')
            }
            placeholder='Confirm Password'
            type='password'
            autoFocus={password.length > 0}
          />
          {!passAgainFocused && passAgain.length > 0 && password !== passAgain && !msgs.password &&
            <p className='ml-2 errorMsg'>These passwords don’t match.</p>}

          <Input
            value={name}
            onChange={setName}
            className={'mt-3' + (msgs.name ? ' fieldError' : '')}
            placeholder='Display Name'
            type='text'
          />
          {showClassroomCode &&
            <p className={'ml-2 errorMsg' + (!msgs.name ? ' benign' : '')}>Use a name your teacher will recognize.</p>}

          {showClassroomCode
            ? (
              <>
                <Input
                  value={classroomCode}
                  onChange={val => setClassroomCode(val.toUpperCase())}
                  className='mt-3 form-control-sm text-monospace'
                  placeholder='Classroom Code'
                  type='text'
                  autoFocus={password.length > 0}
                />
                <p className='ml-2 errorMsg benign'>You can add or change this later.</p>
              </>)
            : name === 'Student' && (
              <span role='button' className='my-2 createAccount' onClick={() => setShowClassroomCode(true)}>
                I have a classroom code
              </span>)}

        </>}

      <div className='w-100' onClick={createMode ? createAccount : signIn}>
        <Button className='signInBtn' color='info'>
          {done
            ? <img src={iconDone} alt='Done' width='20' height='20' />
            : loading
              ? <div class='spinner-border spinner-border-sm text-light' role='status'><span class='sr-only'>Loading...</span></div>
              : createMode ? 'Sign Up' : 'Sign In'}
        </Button>
      </div>

      <span
        role='button'
        className='createAccount'
        onClick={() => {
          setShowClassroomCode(classroomCode)
          setCreateMode(!createMode)
          setMsgs({})
        }}
      >
        {createMode ? 'Cancel' : 'Create Account'}
      </span>

      {msgs.generic
        ? <p className='mt-2 errorMsg'>{msgs.generic}</p>
        : !compact && !createMode && (
          <div className='accountPromo mx-2'>
            <div className='promoItem'>
              <img src={iconStickynote} alt='' width='30' height='30' className='flex-shrink-0' />
              <p>Add your own notes and highlights</p>
            </div>
            <div className='promoItem'>
              <img src={iconCompletion} alt='' width='30' height='30' className='flex-shrink-0' />
              <p>Track your progress as you read and listen</p>
            </div>
            <div className='promoItem'>
              <img src={iconAssignment} alt='' width='30' height='30' className='flex-shrink-0' />
              <p>Work on school assignments</p>
            </div>
          </div>)}
    </form>
  )
}
