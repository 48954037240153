import React from 'react'
import closeIcon from '../assets/icon-close.svg'
import '../styles/Lightbox.css'

export default function Lightbox ({ show, onDismiss, children, ...props }) {
  if (!show) return <></>
  return (
    <div className='lightbox-backdrop' onClick={onDismiss}>
      <div className='lightbox' {...props} onClick={e => e.stopPropagation()}>
        <div className='close' role='button' onClick={onDismiss}>
          <img src={closeIcon} alt='close' />
        </div>
        {children}
      </div>
    </div>
  )
}
