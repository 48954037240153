// eslint-disable-next-line
/* eslint-disable jsx-a11y/accessible-emoji */

import React, { useState, useRef } from 'react'
import Button from './Button'
import { Col } from './layout'
import Note from './Note'
import '../styles/EditorNote.css'

function FormRow ({ widths, group, className = '', children }) {
  return (
    <div className={'form-row align-items-center ' + (group ? 'form-group ' : '') + className}>
      {children.map((child, i) => (
        <Col key={i} width={widths[i] || 12}>
          {child}
        </Col>
      ))}
    </div>
  )
}

function InlineLabel ({ className = '', children }) {
  return <label className={'col-form-label w-100 text-right ' + className}>{children}</label>
}

function cap (num, min, max) {
  return Math.max(min, Math.min(max, parseInt(num || 0)))
}

export default function EditorNoteObject ({ note, block, dbRef, ...props }) {
  const views = [
    { id: 'manage', icon: '🔧' },
    { id: 'location', icon: '📌' },
    { id: 'content', icon: '✏️' },
    { id: 'attachment', icon: '📎', className: (note.vimeo || note.img) ? 'tab-comments-content' : null },
    { id: 'comments', icon: '💬', className: note.comments ? 'tab-comments-content' : null }
    // { id: 'preview', icon: '👀' }
  ]
  const [view, setView] = useState('content')
  // if (note.type !== 'production' && !(note.attachment || note.vimeo)) {
  //   views.splice(3, 1) // remove attachment view
  // }

  const marked = typeof note.loc === 'number'

  const [deleting, setDeleting] = useState(false)

  function update (key, func = x => x) {
    return event => {
      dbRef.update({ [key]: func(event.target.value) })
    }
  }

  const [explainImportance, setExplainImportance] = useState(null)
  const importances = {
    high: { icon: '🔶', desc: 'Essential and unique insights. A few per scene.', next: 'mid' },
    mid: { icon: '🔵', desc: 'Helpful and non-obvious notes. A few per minute.', next: 'low' },
    low: { icon: '◼️', desc: "Deep dives and intuitive notes that aren't required.", next: 'high' }
  }
  const curImp = () => importances[note.importance || 'mid']
  const importanceTimeout = useRef(null)
  function adjustImportance () {
    const imp = curImp().next
    dbRef.update({ importance: imp })
    setExplainImportance(imp)
    clearTimeout(importanceTimeout.current)
    importanceTimeout.current = setTimeout(() => {
      setExplainImportance(null)
    }, 7000)
  }

  const search = note.title.toLowerCase()
  let autoLoc = -1
  let autoLen = note.title.length || 6
  let autoOverflow = null
  let autoLine = block.lines.findIndex(line => {
    if (line.dir) return false
    const lineText = line.toLowerCase()
    if (lineText.includes(search)) {
      autoLoc = lineText.indexOf(search)
      return true
    }
    return false
  })
  if (autoLine === -1) {
    autoLine = block.lines.findIndex(line => {
      if (line.dir) return false
      const lineText = line.toLowerCase()
      let searchTrim = search
      while (searchTrim.length > 4) {
        searchTrim = searchTrim.slice(0, -1)
        if (lineText.endsWith(searchTrim)) {
          autoLoc = lineText.indexOf(searchTrim)
          autoLen = searchTrim.length
          autoOverflow = search.length - searchTrim.length - 1
          return true
        }
      }
      return false
    })
  }

  return (
    <div className={'editornote ' + note.type}>
      <div className='btn-group-vertical btn-group-sm'>
        {views.map(v => (
          <Button
            className={v.id === view ? null : v.className}
            color='secondary'
            outline={v.id !== view}
            key={v.id}
            onClick={() => {
              setView(v.id)
              setDeleting(false)
            }}
          >{v.icon}
          </Button>
        ))}
      </div>
      <div className='editornote-pane'>
        {view === 'manage' &&
          <>
            <Button
              size='sm' outline={!deleting} color='danger' className='align-self-start mb-1'
              onClick={() => {
                if (!deleting) return setDeleting(true)
                dbRef.remove()
              }}
            >{deleting ? 'Are you sure?' : 'Delete Note'}
            </Button>
            {deleting && <span className='small text-danger'>After deleting, click Sort Notes in the top bar.</span>}
            <pre className='small'>
              {Object.keys(note).map(key => `${key}: ${JSON.stringify(note[key])}`).join('\n')}
            </pre>
          </>}

        {view === 'location' &&
          <>
            <FormRow group widths={[3, 3, 6]}>
              <InlineLabel>Pin to line</InlineLabel>
              <input type='number' className='form-control' value={note.line} onChange={update('line', val => cap(val, 0, 255))} />
              <div className='ml-3 d-flex align-items-center' role='button' onClick={() => dbRef.update(marked ? { loc: null, len: null, overflow: null } : { loc: 0, len: note.title.length || 6 })}>
                <Button size='sm' color='info' outline={!marked} className='mr-2'>📍</Button>
                Mark in script
              </div>
            </FormRow>
            {typeof note.loc === 'number' &&
              <FormRow group widths={[3, 2, 1, 2, 2, 2]}>
                <InlineLabel>Start</InlineLabel>
                <input type='number' className='form-control border-info' value={note.loc} onChange={update('loc', val => cap(val, 0, 255))} />
                <InlineLabel>Len</InlineLabel>
                <input type='number' className='form-control border-info' value={note.len} onChange={update('len', val => cap(val, 1, 255))} />
                <InlineLabel>Overflow</InlineLabel>
                <input type='number' className='form-control border-info' value={note.overflow} onChange={update('overflow', val => cap(val, 0, 255) || null)} />
              </FormRow>}
            <FormRow group widths={[3, 3, 6]}>
              <InlineLabel>Vertical offset</InlineLabel>
              <input type='number' className='form-control' value={note.offset} onChange={update('offset', val => cap(val, -120, 120) || null)} />
              <label className='col-form-label'>em</label>
            </FormRow>
          </>}

        {view === 'content' &&
          <>
            {explainImportance
              ? (
                <FormRow widths={[3, 8, 1]}>
                  <input className='form-control' placeholder='Title' value={note.title} onChange={update('title')} />
                  <p className='small text-muted m-0 mt-1 ml-2'>{curImp().desc}</p>
                  <Button outline color='secondary' className='px-1' onClick={adjustImportance}>{curImp().icon}</Button>
                </FormRow>)
              : (
                <FormRow widths={[7, 4, 1]}>
                  <input className='form-control' placeholder='Title' value={note.title} onChange={update('title')} />
                  <select className='custom-select' value={note.type} onChange={update('type')}>
                    {['define', 'dramaturg', 'production', 'content'].map(opt => <option key={opt}>{opt}</option>)}
                  </select>
                  <Button outline color='secondary' className='px-1' onClick={adjustImportance}>{curImp().icon}</Button>
                </FormRow>)}
            <textarea className='form-control' rows={3} placeholder='Body' value={note.body} onChange={update('body')} />
            {typeof note.loc !== 'number' && note.title.length > 0 &&
              <div
                className='d-flex align-items-center'
                role='button'
                onClick={() => {
                  dbRef.update({
                    line: Math.max(0, autoLine),
                    loc: Math.max(0, autoLoc),
                    len: autoLen,
                    overflow: autoOverflow
                  })

                  if (autoLine === -1) {
                    setView('location')
                  }
                }}
              >
                <Button size='sm' color='info' outline={autoLine === -1} className='mr-2'>
                    📍 Auto-mark
                </Button>
              </div>}
          </>}

        {view === 'attachment' &&
          <>
            <FormRow widths={[3, 6, 3]}>
              <InlineLabel>Image</InlineLabel>
              <input className='form-control' placeholder='filename' value={note.img} onChange={update('img', val => val || null)} />
              {note.img && !note.img.includes('.') && <span>.jpg</span>}
            </FormRow>
            {note.img &&
              <FormRow widths={[3, 9]}>
                <InlineLabel>Caption</InlineLabel>
                <input className='form-control' value={note.imgCaption} onChange={update('imgCaption', val => val || null)} />
              </FormRow>}
            <FormRow widths={[3, 6]} className='mt-2'>
              <InlineLabel>Vimeo ID</InlineLabel>
              <input className='form-control' placeholder='000000000' value={note.vimeo} onChange={update('vimeo', val => val || null)} />
            </FormRow>
            {note.vimeo &&
              <FormRow widths={[3, 6, 3]}>
                <InlineLabel>Thumbnail</InlineLabel>
                <input className='form-control' placeholder='TandC_Thumbnail_NAME' value={note.vimeoThumb} onChange={update('vimeoThumb', val => val || null)} />
                {note.vimeoThumb && !note.vimeoThumb.includes('.') && <span>.jpg</span>}
              </FormRow>}
          </>}

        {view === 'comments' &&
          <>
            <span className='small text-muted ml-2'>Comments below are only visible to editors.</span>
            <textarea className='form-control' value={note.comments} onChange={update('comments', val => val || null)} />
          </>}

        {view === 'preview' &&
          <Note {...note} type={note.type + ' w-75 mt-3'} />}
      </div>
    </div>
  )
}
