import React from 'react'
import analytics from '../utils/analytics'
import auth from '../utils/auth'

export default class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    this.state = { error: false }
  }

  static getDerivedStateFromError (error) {
    return { error: error }
  }

  componentDidCatch (error, errorInfo) {
    console.error('Error boundary caught:', error, errorInfo)
    if (!analytics.devMode) {
      analytics.error(error.message, {
        location: this.props.location,
        type: error.name,
        info: JSON.stringify(errorInfo)
      })
    }
  }

  render () {
    if (this.state.error) {
      if (auth.hasRights('admin')) {
        return (
          <div className='text-danger text-monospace' style={{ background: '#ffdddd' }}>
            {String(this.state.error)}
          </div>
        )
      }
      return null
    }

    return this.props.children
  }
}
