import React from 'react'
import { Row, Col } from './layout'
import Script from './Script'
import rulerImg from '../assets/stage-ruler.svg'

const background = `url(${rulerImg}) no-repeat center/cover, #818489`

export default function EditorStage ({ scene, dbRef }) {
  return (
    <Row className='align-items-stretch' style={{ minHeight: '100vh' }}>
      <Col md={2} xl={3} className='d-none d-md-block p-0' style={{ background }} />
      {scene && <Script col={{ md: 10, xl: 9 }} scene={scene} noteMode='hide' />}
    </Row>
  )
}
