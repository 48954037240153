import React from 'react'
import '../styles/Shatter.css'

export default function Shatter ({ slide }) {
  const style = {
    background: slide ? `url(/assets/shatter-${slide}.jpg) no-repeat center/cover` : 'none',
    mixBlendMode: slide < 6 ? 'difference' : 'normal'
  }

  return (
    <>
      <div className='shatter' style={style} />
      {new Array(8).fill({}).map((_, i) => (
        <img key={i} src={`/assets/shatter-${i}.jpg`} alt='' className='shatter-preload' />
      ))}
    </>
  )
}
