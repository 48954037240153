import React from 'react'
import EditorNoteBlock from './EditorNoteBlock'
import Input from './Input'
import { Row, Col } from './layout'
import sceneTitle from '../utils/sceneTitle'
import summaryIcon from '../assets/icon-summary.svg'

export default function EditorNotes ({ scene, dbRef }) {
  const noteTotals = {
    total: 0,
    high: 0,
    mid: 0,
    low: 0
  }
  scene.blocks.forEach(block => {
    if (block.notes) {
      block.notes.forEach(note => {
        noteTotals.total++
        noteTotals[note.importance || 'mid']++
      })
    }
  })
  return (
    <>
      <Row className='mb-n4'>
        <Col md={6}>
          <h3>{sceneTitle(scene.id)}</h3>
          <h5 className='mb-0'>{noteTotals.total} notes</h5>
          {noteTotals.high} high, {noteTotals.mid} mid, {noteTotals.low} low
        </Col>
        <Col md={6}>
          <div className='alert alert-light border d-flex align-items-center'>
            <img src={summaryIcon} alt='' width='30' className='mr-3 ml-n2' style={{ opacity: '0.5' }} />
            <Input
              area
              rows='3'
              placeholder='Scene synopsis'
              value={scene.summary}
              dbRef={dbRef.child('/summary')}
            />
          </div>
        </Col>
      </Row>

      {scene.blocks.map((block, i) => <EditorNoteBlock key={i} i={i} block={block} dbRef={dbRef.child(`/blocks/${i}/notes`)} />)}
    </>
  )
}
