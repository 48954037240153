import React, { useState } from 'react'
import Input from './Input'
import Button from './Button'
import auth from '../utils/auth'
import db from '../utils/db'
import { useHistory } from 'react-router-dom'

const errors = {
  'auth/wrong-password': 'Your current password is incorrect.',
  'auth/invalid-email': 'This email is invalid.',
  'auth/email-already-in-use': 'This email is already in use by another account.',
  'auth/weak-password': 'This new password isn’t strong enough - use at least 6 characters.'
}

export default function AccountSettings ({ user }) {
  const [emailNew, setEmailNew] = useState('')
  const [emailPassword, setEmailPassword] = useState('')
  const [emailStatus, setEmailStatus] = useState({})
  const [passCurrent, setPassCurrent] = useState('')
  const [passNew, setPassNew] = useState('')
  const [passAgain, setPassAgain] = useState('')
  const [deletePassword, setDeletePassword] = useState('')

  const [confirm, setConfirm] = useState(null)

  const history = useHistory()

  return (
    <div className='container-fluid'>
      <h3 className='font-serif'>Account Settings</h3>
      <hr className='mx-3 mb-3 mb-xl-5' />

      <h5 className='mt-5'>Change email address</h5>
      <form className='my-3 p-3 bg-white border rounded-lg d-flex flex-column align-items-start' style={{ maxWidth: '24em' }}>
        <Input type='email' className='mb-2' placeholder='New email address' value={emailNew} onChange={setEmailNew} />
        <Input type='password' className='mb-2' placeholder='Password' value={emailPassword} onChange={setEmailPassword} />
        <Button
          outline={!(emailNew && emailPassword)}
          color='info'
          onClick={() => {
            user.reauthenticateWithCredential(
              auth.fb.EmailAuthProvider.credential(user.email, emailPassword)
            ).then(() => {
              user.updateEmail(emailNew).then(() => {
                setEmailNew('')
                setEmailPassword('')
                setEmailStatus({ success: true })
              })
            }).catch(err => {
              setEmailStatus({ error: errors[err.code] || err.message || 'There was a problem changing your email.' })
            })
          }}
        >
          {emailStatus.loading
            ? <div class='spinner-border spinner-border-sm text-light' role='status'><span class='sr-only'>Loading...</span></div>
            : emailStatus.success
              ? 'Changed!'
              : 'Change Email'}
        </Button>
        {emailStatus.error && <span className='text-danger small mt-2'>{emailStatus.error}</span>}
      </form>

      <h5 className='mt-5'>Change password</h5>
      <form className='my-3 p-3 bg-white border rounded-lg d-flex flex-column align-items-start' style={{ maxWidth: '24em' }}>
        <Input type='password' className='mb-3' placeholder='Current password' value={passCurrent} onChange={setPassCurrent} />
        <Input type='password' className='mb-2' placeholder='New password' value={passNew} onChange={setPassNew} />
        <Input type='password' className='mb-3' placeholder='Verify new password' value={passAgain} onChange={setPassAgain} />
        <Button outline={!(passCurrent && passNew && passAgain)} color='info'>Change Password</Button>
      </form>

      <h5 className='mt-5'>Manage my data</h5>
      <div className='d-flex flex-column align-items-start'>
        {!confirm
          ? (
            <>
              <Button outline color='secondary' className='mb-3' onClick={() => setConfirm('clearClassrooms')}>Clear Classroom Data</Button>
              <Button outline color='danger' className='mb-5' onClick={() => setConfirm('clearHistory')}>Clear Listening History</Button>
              <Button outline color='danger' onClick={() => setConfirm('deleteAccount')}>Delete Account</Button>
            </>)
          : confirm === 'clearClassrooms'
            ? (
              <>
                <p className='mt-3 mb-2'>You're not in any classrooms.</p>
                <div>
                  <Button color='secondary' className='mr-2' onClick={() => setConfirm(null)}>Cancel</Button>
                  <Button color='danger' outline disabled>Clear</Button>
                </div>
              </>
            )
            : confirm === 'clearHistory'
              ? (
                <>
                  <p className='mt-3 mb-2'>Are you sure you want to clear your listening history? This can't be undone.</p>
                  <div>
                    <Button color='secondary' className='mr-2' onClick={() => setConfirm(null)}>Cancel</Button>
                    <Button
                      color='danger'
                      onClick={() => {
                        setConfirm(null)
                        db.ref(`/users/${user.uid}/history`).remove()
                      }}
                    >Clear
                    </Button>
                  </div>
                </>
              )
              : confirm === 'deleteAccount'
                ? (
                  <>
                    <p className='mt-3 mb-2'>Are you sure you want to delete your entire account? This can't be undone.</p>
                    <Input
                      type='password'
                      placeholder='Password'
                      value={deletePassword}
                      onChange={setDeletePassword}
                      style={{ maxWidth: '16em' }}
                      className='mb-2'
                    />
                    <div>
                      <Button color='secondary' className='mr-2' onClick={() => setConfirm(null)}>Cancel</Button>
                      <Button
                        color='danger'
                        onClick={() => {
                          db.ref(`/users/${user.uid}/deleted`).set(new Date())
                          user.reauthenticateWithCredential(
                            auth.fb.EmailAuthProvider.credential(user.email, emailPassword)
                          ).then(() => {
                            user.delete().then(() => {
                              history.push('/')
                            })
                          }).catch(err => {
                            setConfirm(null)
                            window.alert('There was a problem deleting your account: ' + err.message)
                          })
                        }}
                      >Delete My Account
                      </Button>
                    </div>
                  </>
                )
                : null}
      </div>
    </div>
  )
}
