import { useEffect, useState } from 'react'
import 'firebase/auth'
import analytics from './analytics'
import firebase from './firebase'

const roles = ['admin', 'editor', 'preview']
const passwords = {
  admin: 'prosper',
  editor: 'interfere16',
  preview: 'interfere'
}

const auth = {
  hasRights: function (role) {
    if (!role || !passwords[role]) return false
    if (window.localStorage.getItem('backstage') === passwords[role]) {
      return true
    }
    if (role === 'admin') {
      return false
    }
    return this.hasRights(roles[roles.indexOf(role) - 1])
  },
  currentRights: function () {
    return roles.find(role => this.hasRights(role)) || 'none'
  },

  login: function (pass) {
    const success = Object.values(passwords).includes(pass)
    if (success) {
      window.localStorage.setItem('backstage', pass)
      analytics.event.login(this.currentRights())
    } else {
      analytics.event.loginFailed(pass)
    }
    return success
  },
  logout: () => {
    window.localStorage.removeItem('backstage')
    window.location.pathname = '/'
  },

  fb: firebase.default.auth(),

  user: null,
  userChangeHandlers: [],
  onUserChange: function (back) {
    this.userChangeHandlers.push(back)
    return () => {
      this.userChangeHandlers.splice(this.userChangeHandlers.indexOf(back), 1)
    }
  }
}

auth.fb.onAuthStateChanged(user => {
  console.log('user updated!', user)
  auth.user = user || null
  auth.userChangeHandlers.forEach(back => back(user))
})

export default auth

export function useUser () {
  const [user, setUser] = useState(auth.user)

  useEffect(() => {
    return auth.onUserChange(setUser)
  }, [])

  return user
}
