import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from './layout'
import Button from './Button'
import Input from './Input'
import LinkOut from './LinkOut'

export default function AccountClassrooms ({ user }) {
  const [classroomCode, setClassroomCode] = useState('')
  const [joinFailed, setJoinFailed] = useState(false)

  if (!user.classrooms) {
    return (
      <div className='mt-5 d-flex flex-column align-items-center text-center'>
        <h3 className='font-serif'>No classrooms joined.</h3>
        <p className='text-muted mx-auto' style={{ maxWidth: '35em' }}>Classrooms let you bring students, classmates, or even your book club together to share notes on a Dream Project show.</p>

        <h5 className='mt-5'>Join a Classroom</h5>
        <form
          className='d-flex'
          style={{ maxWidth: '30em' }}
          onSubmit={e => {
            e.preventDefault()
            setJoinFailed(true)
          }}
        >
          <Input
            value={classroomCode}
            onChange={val => {
              setClassroomCode(val.toUpperCase())
              setJoinFailed(false)
            }}
            className='text-monospace'
            placeholder='Classroom Code'
          />
          <Button color='success' className='ml-2' onClick={() => setJoinFailed(true)}>Join</Button>
        </form>
        {joinFailed && classroomCode && <span className='small text-danger mt-1'>Couldn’t find a classroom with that code.</span>}

        <h5 className='mt-5'>Create a Classroom</h5>
        <p className='text-muted' style={{ maxWidth: '24em' }}>Classrooms is available in early access now. <LinkOut preset='mailto' sameTab className='text-info'>Contact us</LinkOut> for pricing and more details.</p>
      </div>
    )
  }

  return (
    <div className='container-fluid'>
      <Row>
        <Col>
          <h3>Created Classrooms</h3>
        </Col>
      </Row>

      <Row className='bg-white rounded-lg border py-3 mb-3'>
        <Col md={8} className='d-flex flex-column justify-content-center'>
          <h5 className='font-weight-bold mb-1'>Playwriting Through Time</h5>
          <span>13 people have written 136 notes on <i>Hamlet</i>.</span>
          {/* <Button color='light' className='text-success'>View Classroom Home</Button> */}
          <Link to='/classrooms/DKL6OYD' className='text-success'>View Classroom dashboard</Link>
        </Col>
        <Col md={4} className='d-flex flex-column align-items-md-end'>
          <div className='text-monospace text-success mx-auto mr-md-0 px-2 rounded' style={{ background: '#ebfaee' }}>SRQ24NB</div>
          <Button color='success' className='mt-2'>Manage</Button>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col>
          <h3>Joined Classrooms</h3>
        </Col>
      </Row>

      <Row className='bg-white rounded-lg border py-3 mb-3'>
        <Col md={8} className='d-flex flex-column justify-content-center'>
          <h5 className='font-weight-bold'>From Style to Substance</h5>
          <span>The 26 notes you've written within <i>Romeo & Juliet</i> are shared with Anton Jones.</span>
        </Col>
        <Col md={4} className='d-flex flex-column align-items-md-end'>
          <div className='text-monospace text-success mx-auto mr-md-0 px-2 rounded' style={{ background: '#ebfaee' }}>E621AME</div>
          <Button color='success' className='mt-2'>Manage</Button>
          <Button color='danger' outline className='mt-2'>Leave Classroom</Button>
        </Col>
      </Row>

      <Row className='bg-white rounded-lg border py-3 mb-3'>
        <Col md={8} className='d-flex flex-column justify-content-center'>
          <h5 className='font-weight-bold'>Shakespeare Summer Camp</h5>
          <span>Notes you write within <i>A Midsummer Night’s Dream</i> are shared with Jane Bassuk and 13 other classmates.</span>
        </Col>
        <Col md={4} className='d-flex flex-column align-items-md-end'>
          <div className='text-monospace text-success mx-auto mr-md-0 px-2 rounded' style={{ background: '#ebfaee' }}>SRQ24NB</div>
          <Button color='success' className='mt-2'>Manage</Button>
          <Button color='danger' outline className='mt-2'>Leave Classroom</Button>
        </Col>
      </Row>

      <div className='alert alert-success'>
        Create your own classroom to bring students, classmates, or even your book club together to share notes. <u>Contact us</u> for more details about this early access feature.
      </div>

    </div>
  )
}
