import React from 'react'
import Button from '../components/Button'
import Card from '../components/Card'
import { Row } from '../components/layout'
import Navbar from '../components/Navbar'
import auth from '../utils/auth'

export default function Analytics (props) {
  const enableDevMode = () => {
    window.localStorage.setItem('dev', true)
    window.location.reload()
  }

  const disableDevMode = () => {
    window.localStorage.removeItem('dev')
    window.location.reload(false)
  }

  return (
    <>
      <Navbar back='/backstage' title='Analytics' />
      <div className='container'>
        <Row className='row-cols-3'>

          <Card title='Analytics' subtitle='Coming soon.' />

          {auth.hasRights('admin') &&
            <Card title='Event Log' subtitle='Track usage and security events.' link={{ to: '/backstage/analytics/log', text: 'View event log' }} />}


          {auth.hasRights('admin') &&
            <Card title='Dev Mode' subtitle='Silence some analytics events.'>
              {window.localStorage.getItem('dev')
                ? <Button color='warning' onClick={disableDevMode}>Disable Dev Mode</Button>
                : <Button color='warning' outline onClick={enableDevMode}>Enable Dev Mode</Button>}
            </Card>}

          {auth.hasRights('admin') &&
            <Card title='User Data' link={{ to: '/backstage/analytics/users', text: 'View user data' }} />}

          {auth.hasRights('admin') &&
            <Card title='Processes' link={{ to: '/backstage/analytics/process', text: 'Run analytics processes' }} />}

        </Row>
      </div>
    </>
  )
}
